import React from 'react';

import { PrescriptionUpsert } from '@lupa/utils/validators/prescriptionValidators';
import { globalSingleton } from '@lupa/work/singletons/globalSingleton';
import { formatPrescriptionDosage } from '@lupa/work/validators/prescriptionValidators';

import { IconPawFilled, IconUserFilled } from '@tabler/icons-react';

import { format } from 'date-fns';

interface DosageLabelPdfProps {
  prescription: Pick<
    PrescriptionUpsert,
    | 'id'
    | 'product_name'
    | 'quantity'
    | 'unit'
    | 'dosage_specification'
    | 'status'
  >;
  petName: string;
  clientName: string;
  clientAddress?: string;
  prescriberName: string;
  size?: {
    width: number;
    height: number;
    margins: {
      top: number;
      right: number;
      bottom: number;
      left: number;
    };
  };
}

export const dosageLabelSize = () =>
  globalSingleton.currentStore.printing_settings?.dosageLabels ?? {
    width: 105,
    height: 29,
    margins: {
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
    },
  };

export const getDosageLabelPdfOptions = () => ({
  width: `${dosageLabelSize().width}mm`,
  height: `${dosageLabelSize().height}mm`,
});

export default function DosageLabelPdf({
  prescription,
  petName,
  prescriberName,
  clientName,
  clientAddress,
  size: sizeMM = dosageLabelSize(),
}: DosageLabelPdfProps) {
  if (prescription.status === 'cancelled') {
    console.error(
      'Attempted to render a dosage label for a cancelled prescription. Id:',
      prescription.id,
    );
    return null;
  }

  const currentStore = globalSingleton.currentStore;

  const haveMargins =
    sizeMM.margins.top > 0 ||
    sizeMM.margins.right > 0 ||
    sizeMM.margins.bottom > 0 ||
    sizeMM.margins.left > 0;

  return (
    <div
      style={{
        width: `${sizeMM.width}mm`,
        height: `${sizeMM.height}mm`,
        paddingTop: `${sizeMM.margins.top}mm`,
        paddingRight: `${sizeMM.margins.right}mm`,
        paddingBottom: `${sizeMM.margins.bottom}mm`,
        paddingLeft: `${sizeMM.margins.left}mm`,
      }}
    >
      <div
        className={`font-poppins h-full w-full text-[7pt] ${haveMargins ? 'rounded-[8pt] border border-black' : ''}`}
      >
        <div className='flex h-full'>
          <div className='flex w-[16pt] items-center justify-center border-r-[0.75pt] border-black'>
            <p className='rotate-[180deg] text-center text-[5pt] uppercase leading-[1.25] tracking-[-0.1px] [writing-mode:vertical-rl]'>
              For animal treatment only
              <br />
              Keep out of reach of children
            </p>
          </div>

          <div
            className={`flex flex-1 flex-col pl-[2pt] ${haveMargins ? 'pb-[1pt] pr-[1pt] pt-[1pt]' : ''}`}
          >
            <div>
              <p className='text-[6pt] font-bold leading-[1.2]'>
                {currentStore.name}
              </p>
              <p className='text-[5pt]'>
                {currentStore.address?.formatted_address}
              </p>
              <div className='flex justify-between text-[6pt]'>
                <p>{currentStore.phone}</p>
                <p>{prescriberName}</p>
                <p>{format(new Date(), 'dd MMM yyyy')}</p>
              </div>
            </div>

            <div className='flex flex-col border border-b-0 border-black'>
              <div className='flex flex-shrink-0 border-b border-black'>
                <div className='flex-1 p-[1pt]'>
                  <p className='font-bold'>{prescription.product_name}</p>
                </div>
                <div className='border-l border-black p-[1pt]'>
                  <p>
                    QTY: {prescription.quantity} {prescription.unit}
                  </p>
                </div>
              </div>
              <div className='flex flex-shrink-0 border-b border-black'>
                <div className='flex-1 p-[1pt]'>
                  <p>{formatPrescriptionDosage({ prescription })}</p>
                </div>
              </div>
            </div>

            <div className='mt-auto flex gap-2 text-[8pt] font-bold'>
              <div className='flex flex-shrink-0 flex-grow-0 items-center'>
                <IconPawFilled height='7pt' width='7pt' />
                {petName}
              </div>
              <div className='flex flex-1 items-center'>
                <IconUserFilled height='7pt' width='7pt' />
                {clientName}
                {clientAddress && `, ${clientAddress}`}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
