import { trpc } from '@lupa/work/lib/trpc';
import { globalSingleton } from '@lupa/work/singletons/globalSingleton';

import { useMutation } from '@tanstack/react-query';
import { getQueryKey } from '@trpc/react-query';

import { produce } from 'immer';
import { useNavigate } from 'react-router-dom';

import { API_KEYS } from './apiKeys';
import { getOrCreateConversation } from './chatApi';

const getAllQueriesStartingWith = (key: unknown) => {
  return globalSingleton.queryClient
    .getQueryCache()
    .findAll({ queryKey: [key] });
};

export const getTrpcQueryKey = ({
  fn,
  input,
  type = 'query',
}: {
  fn: any;
  input?: any;
  type?: 'query' | 'mutation';
}) => {
  return [getQueryKey(fn)[0], { input, type }];
};

export const invalidateAllQueriesStartingWith = (key: unknown) => {
  const allQueries = getAllQueriesStartingWith(key);

  allQueries.forEach((query: any) => {
    globalSingleton.queryClient.invalidateQueries(query.queryKey);
  });
};

export const invalidateAllAppointmentsRelatedQueries = () => {
  invalidateAllQueriesStartingWith(
    getQueryKey(trpc.appointments.getAppointmentSummary)[0],
  );
  invalidateAllQueriesStartingWith(
    getQueryKey(trpc.calendar.getCalendarAppointments)[0],
  );
  invalidateAllQueriesStartingWith(
    getQueryKey(trpc.appointments.getAppointmentBillingItems)[0],
  );
  invalidateAllQueriesStartingWith(
    getQueryKey(trpc.calendar.getCalendarFlowChartAppointments)[0],
  );
  invalidateAllQueriesStartingWith(
    getQueryKey(trpc.calendar.getCalendarHospitalizationAppointments)[0],
  );
};

const updateDraftProperties = (draftObject: any, propertiesToUpdate: any) => {
  Object.keys(propertiesToUpdate).forEach((key) => {
    if (propertiesToUpdate[key] !== undefined) {
      draftObject[key] = propertiesToUpdate[key];
    }
  });
};

export const updateAppointmentProperties = (
  appointmentId: string,
  propertiesToUpdate: any,
) => {
  const allAppointmentsQueries = getAllQueriesStartingWith(
    getQueryKey(trpc.appointments.getAppointments)[0],
  );
  allAppointmentsQueries.forEach((query: any) => {
    const { queryKey } = query;
    globalSingleton.queryClient.setQueryData(queryKey, (oldData: any) => {
      return produce(oldData, (draft: any) => {
        const appointmentIndex = draft?.appointments?.findIndex(
          (a: any) => a.id === appointmentId,
        );
        if (appointmentIndex != null && appointmentIndex !== -1) {
          updateDraftProperties(
            draft.appointments[appointmentIndex],
            propertiesToUpdate,
          );
        }
      });
    });
  });

  globalSingleton.queryClient.setQueryData(
    getTrpcQueryKey({
      fn: trpc.appointments.getAppointment,
      input: {
        appointmentId,
      },
    }),
    (oldData: any) => {
      return produce(oldData, (draft: any) => {
        if (draft) {
          updateDraftProperties(draft, propertiesToUpdate);
        }
      });
    },
  );

  globalSingleton.queryClient.setQueryData(
    getTrpcQueryKey({
      fn: trpc.appointments.getAppointmentSummary,
      input: {
        appointmentId,
      },
    }),
    (oldData: any) => {
      return produce(oldData, (draft: any) => {
        if (draft) {
          updateDraftProperties(draft, propertiesToUpdate);
        }
      });
    },
  );

  // TODO: Refactor this to avoid refetching everything!
  invalidateAllAppointmentsRelatedQueries();
};

export const invalidateAppointmentQueries = (
  appointmentId: string,
  isNotification = false,
) => {
  globalSingleton.queryClient.invalidateQueries({
    queryKey: getTrpcQueryKey({
      fn: trpc.appointments.getAppointment,
      input: {
        appointmentId,
      },
    }),
  });

  invalidateAllAppointmentsRelatedQueries();

  if (isNotification) {
    invalidateAllQueriesStartingWith(
      getQueryKey(trpc.store.getStoreNotifications)[0],
    );
  }
};

export const invalidateClaim = (claimId: string) => {
  globalSingleton.queryClient.invalidateQueries({
    queryKey: getTrpcQueryKey({
      fn: trpc.claims.getClaimData,
      input: {
        claimId,
      },
    }),
  });
};

export const invalidateInvoicesForClientQuery = (clientId: string) => {
  globalSingleton.queryClient.invalidateQueries({
    queryKey: getTrpcQueryKey({
      fn: trpc.invoices.getInvoicesForClient,
      input: {
        clientId,
      },
    }),
  });
};

export const invalidateInvoice = ({
  invoiceId,
  clientId,
}: {
  invoiceId: string;
  clientId: string;
}) => {
  globalSingleton.queryClient.invalidateQueries({
    queryKey: getTrpcQueryKey({
      fn: trpc.invoices.getInvoice,
      input: {
        storeInvoiceId: invoiceId,
      },
    }),
  });
  invalidateClientQueries(clientId);
};

export const invalidateInvoiceForAppointment = (appointmentId: string) => {
  globalSingleton.queryClient.invalidateQueries({
    queryKey: getTrpcQueryKey({
      fn: trpc.invoices.getInvoiceFromAppointment,
      input: {
        appointmentId,
      },
    }),
  });
};

export const invalidateClientQueries = (clientId: string) => {
  globalSingleton.queryClient.invalidateQueries({
    queryKey: [
      getQueryKey(trpc.clients.getClient)[0],
      {
        input: {
          clientId,
        },
        type: 'query',
      },
    ],
  });

  invalidateInvoicesForClientQuery(clientId);
  invalidateAllQueriesStartingWith(getQueryKey(trpc.clients.getClients)[0]);
};

export const invalidatePetQueries = (petId: string) => {
  globalSingleton.queryClient.invalidateQueries({
    queryKey: [
      getQueryKey(trpc.pets.getPet)[0],
      {
        input: {
          petId,
        },
        type: 'query',
      },
    ],
  });

  invalidateAllQueriesStartingWith(getQueryKey(trpc.pets.getPets)[0]);
  invalidateAllAppointmentsRelatedQueries();
};

export const useGetOrCreateConversation = () => {
  const navigate = useNavigate();

  return useMutation({
    mutationKey: [API_KEYS.GET_OR_CREATE_CONVERSATION],
    mutationFn: getOrCreateConversation,
    onSuccess: ({ conversationId }: { conversationId: string }) => {
      globalSingleton.queryClient.invalidateQueries({
        queryKey: [API_KEYS.CONVERSATIONS],
      });
      navigate(`/chat?threadKey=${conversationId}`);
    },
  });
};

export const invalidateSearchQueries = (searchQuery: string) => {
  const allSearchQueries = globalSingleton.queryClient
    .getQueryCache()
    .findAll({ queryKey: [searchQuery] });

  allSearchQueries.forEach((query: any) => {
    globalSingleton.queryClient.invalidateQueries({ queryKey: query.queryKey });
  });
};

export const updatePetProperties = (petId: string, propertiesToUpdate: any) => {
  const allPetsQueries = globalSingleton.queryClient
    .getQueryCache()
    .findAll({ queryKey: [getQueryKey(trpc.pets.getPets)[0]] });

  allPetsQueries.forEach((query: any) => {
    const { queryKey } = query;
    globalSingleton.queryClient.setQueryData(queryKey, (oldData: any) => {
      return produce(oldData, (draft: any) => {
        const petIndex = draft?.data?.findIndex((a: any) => a.id === petId);
        if (petIndex != null && petIndex !== -1) {
          updateDraftProperties(draft.data[petIndex], propertiesToUpdate);
        }
      });
    });
  });

  const allPetQueries = globalSingleton.queryClient.getQueryCache().findAll({
    queryKey: [
      getQueryKey(trpc.pets.getPet)[0],
      {
        input: {
          petId,
        },
        type: 'query',
      },
    ],
  });

  allPetQueries.forEach((query: any) => {
    const { queryKey } = query;
    globalSingleton.queryClient.setQueryData(queryKey, (oldData: any) => {
      return produce(oldData, (draft: any) => {
        if (draft) {
          updateDraftProperties(draft, propertiesToUpdate);
        }
      });
    });
  });

  const allAppointmentQueries = getAllQueriesStartingWith(
    getQueryKey(trpc.appointments.getAppointment)[0],
  );

  allAppointmentQueries.forEach((query: any) => {
    const { queryKey } = query;
    globalSingleton.queryClient.setQueryData(queryKey, (oldData: any) => {
      return produce(oldData, (draft: any) => {
        if (draft && draft.pet) {
          updateDraftProperties(draft.pet, propertiesToUpdate);
        }
      });
    });
  });
};

export const invalidateAllQueries = () => {
  // TODO: Refactor this to avoid refetching everything!
  globalSingleton.queryClient.invalidateQueries();
};

export const invalidateTrpcQuery = (fn: any, input: any) => {
  globalSingleton.queryClient.invalidateQueries({
    queryKey: [
      getQueryKey(fn)[0],
      {
        input,
        type: 'query',
      },
    ],
  });
};
