import { getSupabaseAssumingAlreadyInitialized } from '../lib/supabase';

export const getImagePublicUrl = (url: Nullish<string>): string => {
  const supabase = getSupabaseAssumingAlreadyInitialized();
  if (url === '' || url == null) {
    return '';
  }

  const firstPath = url.split('/')[0];

  if (
    firstPath === 'clients' ||
    firstPath === 'pets' ||
    firstPath === 'employees'
  ) {
    // TODO: Remove this hack once we migrate avatars to avatars/
    return supabase.storage.from('avatars').getPublicUrl(url).data.publicUrl;
  }

  return supabase.storage.from('companies').getPublicUrl(url).data.publicUrl;
};
