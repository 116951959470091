import * as React from 'react';
import { Dispatch, useCallback, useEffect, useState } from 'react';

import { usePopover } from '@lupa/ui/hooks/use-popover';
import { STORE_TEMPLATE_TYPE } from '@lupa/utils/enums';

import { $isLinkNode, TOGGLE_LINK_COMMAND } from '@lexical/link';
import {
  $isListNode,
  INSERT_CHECK_LIST_COMMAND,
  INSERT_ORDERED_LIST_COMMAND,
  INSERT_UNORDERED_LIST_COMMAND,
  ListNode,
} from '@lexical/list';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $isDecoratorBlockNode } from '@lexical/react/LexicalDecoratorBlockNode';
import {
  $createHeadingNode,
  $createQuoteNode,
  $isHeadingNode,
  $isQuoteNode,
  HeadingTagType,
} from '@lexical/rich-text';
import {
  $getSelectionStyleValueForProperty,
  $isParentElementRTL,
  $patchStyleText,
  $setBlocksType,
} from '@lexical/selection';
import { $isTableNode, $isTableSelection } from '@lexical/table';
import {
  $findMatchingParent,
  $getNearestBlockElementAncestorOrThrow,
  $getNearestNodeOfType,
  $isEditorIsNestedEditor,
  mergeRegister,
} from '@lexical/utils';
import {
  ButtonBase,
  Divider,
  FormControl,
  IconButton,
  InputAdornment,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Popover,
  Select,
  Stack,
  Tooltip,
} from '@mui/material';
import {
  IconAlignCenter,
  IconAlignJustified,
  IconAlignLeft,
  IconAlignRight,
  IconArrowBackUp,
  IconArrowForwardUp,
  IconBackground,
  IconBaseline,
  IconBold,
  IconChevronDown,
  IconClearFormatting,
  IconH1,
  IconH2,
  IconH3,
  IconIndentDecrease,
  IconIndentIncrease,
  IconItalic,
  IconLetterA,
  IconList,
  IconListCheck,
  IconListNumbers,
  IconMaximize,
  IconMinimize,
  IconQuote,
  IconStrikethrough,
  IconSubscript,
  IconSuperscript,
  IconTypography,
  IconUnderline,
} from '@tabler/icons-react';

import {
  $createParagraphNode,
  $getSelection,
  $isElementNode,
  $isRangeSelection,
  $isRootOrShadowRoot,
  $isTextNode,
  CAN_REDO_COMMAND,
  CAN_UNDO_COMMAND,
  COMMAND_PRIORITY_CRITICAL,
  COMMAND_PRIORITY_NORMAL,
  ElementFormatType,
  FORMAT_ELEMENT_COMMAND,
  FORMAT_TEXT_COMMAND,
  INDENT_CONTENT_COMMAND,
  KEY_MODIFIER_COMMAND,
  LexicalEditor,
  OUTDENT_CONTENT_COMMAND,
  REDO_COMMAND,
  SELECTION_CHANGE_COMMAND,
  UNDO_COMMAND,
} from 'lexical';

import ColorPicker from '../../ui/ColorPicker';
import { getSelectedNode } from '../../utils/getSelectedNode';
import { sanitizeUrl } from '../../utils/url';
import EditorToolbarInsertDropdown from './EditorToolbarInsertDropdown';
import EditorToolbarInsertTemplate from './EditorToolbarInsertTemplate';
import FontSize from './FontSize';

const blockTypeToBlockName = {
  bullet: 'Bulleted List',
  check: 'Check List',
  h1: 'Heading 1',
  h2: 'Heading 2',
  h3: 'Heading 3',
  number: 'Numbered List',
  paragraph: 'Normal',
  quote: 'Quote',
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const rootTypeToRootName = {
  root: 'Root',
  table: 'Table',
};

const FONT_FAMILY_OPTIONS: [string, string][] = [
  ['Arial', 'Arial'],
  ['Courier New', 'Courier New'],
  ['Georgia', 'Georgia'],
  ['Times New Roman', 'Times New Roman'],
  ['Trebuchet MS', 'Trebuchet MS'],
  ['Verdana', 'Verdana'],
];

const FONT_SIZE_OPTIONS: [string, string][] = [
  ['10px', '10px'],
  ['11px', '11px'],
  ['12px', '12px'],
  ['13px', '13px'],
  ['14px', '14px'],
  ['15px', '15px'],
  ['16px', '16px'],
  ['17px', '17px'],
  ['18px', '18px'],
  ['19px', '19px'],
  ['20px', '20px'],
];

const ELEMENT_FORMAT_OPTIONS: {
  [key in Exclude<ElementFormatType, ''>]: {
    icon: React.ReactNode;
    iconRTL: React.ReactNode;
    name: string;
  };
} = {
  left: {
    icon: <IconAlignLeft fontSize={18} />,
    iconRTL: <IconAlignLeft fontSize={18} />,
    name: 'Left Align',
  },
  center: {
    icon: <IconAlignCenter fontSize={18} />,
    iconRTL: <IconAlignCenter fontSize={18} />,
    name: 'Center Align',
  },
  right: {
    icon: <IconAlignRight fontSize={18} />,
    iconRTL: <IconAlignRight fontSize={18} />,
    name: 'Right Align',
  },
  justify: {
    icon: <IconAlignJustified fontSize={18} />,
    iconRTL: <IconAlignJustified fontSize={18} />,
    name: 'Justify Align',
  },
  start: {
    icon: <IconAlignLeft fontSize={18} />,
    iconRTL: <IconAlignRight fontSize={18} />,
    name: 'Start Align',
  },
  end: {
    icon: <IconAlignRight fontSize={18} />,
    iconRTL: <IconAlignLeft fontSize={18} />,
    name: 'End Align',
  },
};

function BlockFormatDropDown({
  editor,
  blockType,
  disabled = false,
}: {
  blockType: keyof typeof blockTypeToBlockName;
  editor: LexicalEditor;
  disabled?: boolean;
}): JSX.Element {
  const [anchorEl, setAnchorEl] = useState(null);

  const formatParagraph = () => {
    editor.update(() => {
      const selection = $getSelection();
      if ($isRangeSelection(selection)) {
        $setBlocksType(selection, () => $createParagraphNode());
      }
    });
  };

  const formatHeading = (headingSize: HeadingTagType) => {
    if (blockType !== headingSize) {
      editor.update(() => {
        const selection = $getSelection();
        $setBlocksType(selection, () => $createHeadingNode(headingSize));
      });
    }
  };

  const formatBulletList = () => {
    if (blockType !== 'bullet') {
      editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND, undefined);
    } else {
      formatParagraph();
    }
  };

  const formatCheckList = () => {
    if (blockType !== 'check') {
      editor.dispatchCommand(INSERT_CHECK_LIST_COMMAND, undefined);
    } else {
      formatParagraph();
    }
  };

  const formatNumberedList = () => {
    if (blockType !== 'number') {
      editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND, undefined);
    } else {
      formatParagraph();
    }
  };

  const formatQuote = () => {
    if (blockType !== 'quote') {
      editor.update(() => {
        const selection = $getSelection();
        $setBlocksType(selection, () => $createQuoteNode());
      });
    }
  };

  const handleChange = (newBlockType: keyof typeof blockTypeToBlockName) => {
    switch (newBlockType) {
      case 'paragraph':
        formatParagraph();
        break;
      case 'h1':
      case 'h2':
      case 'h3':
        formatHeading(newBlockType);
        break;
      case 'bullet':
        formatBulletList();
        break;
      case 'number':
        formatNumberedList();
        break;
      case 'check':
        formatCheckList();
        break;
      case 'quote':
        formatQuote();
        break;
      default:
        break;
    }

    setAnchorEl(null);
  };

  const getIcon = (type: keyof typeof blockTypeToBlockName) => {
    switch (type) {
      case 'paragraph':
        return <IconAlignJustified size={20} />;
      case 'h1':
        return <IconH1 size={20} />;
      case 'h2':
        return <IconH2 size={20} />;
      case 'h3':
        return <IconH3 size={20} />;
      case 'bullet':
        return <IconList size={20} />;
      case 'number':
        return <IconListNumbers size={20} />;
      case 'check':
        return <IconListCheck size={20} />;
      case 'quote':
        return <IconQuote size={20} />;
      default:
        return null;
    }
  };

  return (
    <>
      <Tooltip title='Block style' placement='top'>
        <span>
          <ButtonBase
            onClick={(event: any) => {
              setAnchorEl(event.currentTarget);
            }}
            disabled={disabled}
            sx={{
              borderRadius: 1,
              padding: 0.5,
              color: '#6C737F',
              '&:hover': {
                backgroundColor: 'action.hover',
              },
            }}
          >
            <Stack direction='row' alignItems='center'>
              {getIcon(blockType as keyof typeof blockTypeToBlockName)}

              <IconChevronDown size={20} />
            </Stack>
          </ButtonBase>
        </span>
      </Tooltip>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => {
          setAnchorEl(null);
        }}
      >
        {Object.entries(blockTypeToBlockName).map(([key, name]) => (
          <MenuItem
            key={key}
            value={key}
            selected={key === blockType}
            onClick={() => {
              handleChange(key as keyof typeof blockTypeToBlockName);
            }}
          >
            <ListItemIcon>
              {getIcon(key as keyof typeof blockTypeToBlockName)}
            </ListItemIcon>
            <ListItemText>{name}</ListItemText>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}

function FontDropDown({
  editor,
  value,
  style,
  disabled = false,
}: {
  editor: LexicalEditor;
  value: string;
  style: string;
  disabled?: boolean;
}): JSX.Element {
  const handleChange = (event: any) => {
    editor.update(() => {
      const selection = $getSelection();
      if (selection !== null) {
        $patchStyleText(selection, {
          [style]: event.target.value,
        });
      }
    });
  };

  return (
    <FormControl sx={{ minWidth: 'fit-content' }}>
      <Select
        value={value}
        onChange={handleChange}
        size='small'
        disabled={disabled}
        startAdornment={
          <InputAdornment position='start'>
            <IconTypography size={18} />
          </InputAdornment>
        }
      >
        {(style === 'font-family'
          ? FONT_FAMILY_OPTIONS
          : FONT_SIZE_OPTIONS
        ).map(([option, text]) => (
          <MenuItem key={option} value={option}>
            {text}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

function FontColorDropDown({
  disabled = false,
  color,
  onChange,
}: {
  disabled?: boolean;
  color: string;
  onChange?: (color: string, skipHistoryStack: boolean) => void;
}) {
  const popover = usePopover();

  return (
    <>
      <Tooltip title='Font color' placement='top'>
        <span>
          <ButtonBase
            ref={popover.anchorRef}
            onClick={popover.handleOpen}
            disabled={disabled}
            sx={{
              borderRadius: 1,
              padding: 0.5,
              color: '#6C737F',
              '&:hover': {
                backgroundColor: 'action.hover',
              },
            }}
          >
            <Stack direction='row' alignItems='center'>
              <IconLetterA size={20} />

              <IconChevronDown size={20} />
            </Stack>
          </ButtonBase>
        </span>
      </Tooltip>

      <Popover
        anchorEl={popover.anchorRef.current}
        open={popover.open}
        onClose={popover.handleClose}
        elevation={20}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <ColorPicker color={color} onChange={onChange} />
      </Popover>
    </>
  );
}

function BackgroundColorDropDown({
  disabled = false,
  color,
  onChange,
}: {
  disabled?: boolean;
  color: string;
  onChange?: (color: string, skipHistoryStack: boolean) => void;
}) {
  const popover = usePopover();

  return (
    <>
      <Tooltip title='Background color' placement='top'>
        <span>
          <ButtonBase
            ref={popover.anchorRef}
            onClick={popover.handleOpen}
            disabled={disabled}
            sx={{
              borderRadius: 1,
              padding: 0.5,
              color: '#6C737F',
              '&:hover': {
                backgroundColor: 'action.hover',
              },
            }}
          >
            <Stack direction='row' alignItems='center'>
              <IconBackground size={20} />

              <IconChevronDown size={20} />
            </Stack>
          </ButtonBase>
        </span>
      </Tooltip>

      <Popover
        anchorEl={popover.anchorRef.current}
        open={popover.open}
        onClose={popover.handleClose}
        elevation={20}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <ColorPicker color={color} onChange={onChange} />
      </Popover>
    </>
  );
}

function ElementFormatDropdown({
  editor,
  value,
  isRTL,
  disabled = false,
}: {
  editor: LexicalEditor;
  value: ElementFormatType;
  isRTL: boolean;
  disabled: boolean;
}): JSX.Element {
  const [anchorEl, setAnchorEl] = useState(null);
  const handleChange = (newValue: ElementFormatType) => {
    editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, newValue);
    setAnchorEl(null);
  };

  const getAlignmentIcon = (value: ElementFormatType, isRTL: boolean) => {
    switch (value) {
      case 'left':
        return <IconAlignLeft size={20} />;
      case 'center':
        return <IconAlignCenter size={20} />;
      case 'right':
        return <IconAlignRight size={20} />;
      case 'justify':
        return <IconAlignJustified size={20} />;
      case 'start':
        return isRTL ? (
          <IconAlignRight size={20} />
        ) : (
          <IconAlignLeft size={20} />
        );
      case 'end':
        return isRTL ? (
          <IconAlignLeft size={20} />
        ) : (
          <IconAlignRight size={20} />
        );
      default:
        return <IconAlignLeft size={20} />;
    }
  };

  return (
    <>
      <Tooltip title='Element format' placement='top'>
        <span>
          <ButtonBase
            onClick={(event: any) => {
              setAnchorEl(event.currentTarget);
            }}
            disabled={disabled}
            sx={{
              borderRadius: 1,
              padding: 0.5,
              color: '#6C737F',
              '&:hover': {
                backgroundColor: 'action.hover',
              },
            }}
          >
            <Stack direction='row' alignItems='center'>
              {getAlignmentIcon(value || 'left', isRTL)}

              <IconChevronDown size={20} />
            </Stack>
          </ButtonBase>
        </span>
      </Tooltip>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => {
          setAnchorEl(null);
        }}
      >
        {Object.entries(ELEMENT_FORMAT_OPTIONS).map(([key, option]) => (
          <MenuItem
            key={key}
            value={key}
            onClick={() => {
              handleChange(key as ElementFormatType);
            }}
          >
            <ListItemIcon>
              {getAlignmentIcon(key as ElementFormatType, isRTL)}
            </ListItemIcon>
            <ListItemText primary={option.name} />
          </MenuItem>
        ))}

        <Divider />

        <MenuItem
          onClick={() =>
            editor.dispatchCommand(OUTDENT_CONTENT_COMMAND, undefined)
          }
        >
          <ListItemIcon>
            {isRTL ? (
              <IconIndentIncrease size={16} />
            ) : (
              <IconIndentDecrease size={16} />
            )}
          </ListItemIcon>
          <ListItemText primary='Outdent' />
        </MenuItem>
        <MenuItem
          onClick={() =>
            editor.dispatchCommand(INDENT_CONTENT_COMMAND, undefined)
          }
        >
          <ListItemIcon>
            {isRTL ? (
              <IconIndentDecrease size={16} />
            ) : (
              <IconIndentIncrease size={16} />
            )}
          </ListItemIcon>
          <ListItemText primary='Indent' />
        </MenuItem>
      </Menu>
    </>
  );
}

function TextFormatDropdown({
  activeEditor,
  isEditable,
  isStrikethrough,
  isSubscript,
  isSuperscript,
  clearFormatting,
}: {
  activeEditor: LexicalEditor;
  isEditable: boolean;
  isStrikethrough: boolean;
  isSubscript: boolean;
  isSuperscript: boolean;
  clearFormatting: () => void;
}) {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (command: any) => {
    if (command === 'clear') {
      clearFormatting();
    } else {
      activeEditor.dispatchCommand(FORMAT_TEXT_COMMAND, command);
    }
    handleClose();
  };

  return (
    <>
      <Tooltip title='More text formatting options'>
        <span>
          <IconButton
            onClick={handleClick}
            disabled={!isEditable}
            size='small'
            aria-label='Formatting options for additional text styles'
          >
            <IconBaseline fontSize={18} />
          </IconButton>
        </span>
      </Tooltip>

      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem
          onClick={() => handleMenuItemClick('strikethrough')}
          selected={isStrikethrough}
        >
          <ListItemIcon>
            <IconStrikethrough fontSize={18} />
          </ListItemIcon>
          <ListItemText>Strikethrough</ListItemText>
        </MenuItem>
        <MenuItem
          onClick={() => handleMenuItemClick('subscript')}
          selected={isSubscript}
        >
          <ListItemIcon>
            <IconSubscript fontSize={18} />
          </ListItemIcon>
          <ListItemText>Subscript</ListItemText>
        </MenuItem>
        <MenuItem
          onClick={() => handleMenuItemClick('superscript')}
          selected={isSuperscript}
        >
          <ListItemIcon>
            <IconSuperscript fontSize={18} />
          </ListItemIcon>
          <ListItemText>Superscript</ListItemText>
        </MenuItem>
        <MenuItem onClick={() => handleMenuItemClick('clear')}>
          <ListItemIcon>
            <IconClearFormatting fontSize={18} />
          </ListItemIcon>
          <ListItemText>Clear Formatting</ListItemText>
        </MenuItem>
      </Menu>
    </>
  );
}

export default function EditorToolbarPlugin({
  setIsLinkEditMode,
  templateType,
  isComplexToolbar,
  showFontSelector,
  showMoreFormattingOptions,
  isFullScreen,
  setIsFullScreen,
}: {
  setIsLinkEditMode: Dispatch<boolean>;
  templateType?: STORE_TEMPLATE_TYPE;
  isComplexToolbar?: boolean;
  showFontSelector?: boolean;
  showMoreFormattingOptions?: boolean;
  isFullScreen: boolean;
  setIsFullScreen: (value: boolean) => void;
}): JSX.Element {
  const [editor] = useLexicalComposerContext();
  const [activeEditor, setActiveEditor] = useState(editor);
  const [blockType, setBlockType] =
    useState<keyof typeof blockTypeToBlockName>('paragraph');
  const [rootType, setRootType] =
    useState<keyof typeof rootTypeToRootName>('root');

  const [fontSize, setFontSize] = useState<string>('15px');
  const [fontColor, setFontColor] = useState<string>('#000');
  const [bgColor, setBgColor] = useState<string>('#fff');
  const [fontFamily, setFontFamily] = useState<string>('Arial');
  const [elementFormat, setElementFormat] = useState<ElementFormatType>('left');
  const [isLink, setIsLink] = useState(false);
  const [isBold, setIsBold] = useState(false);
  const [isItalic, setIsItalic] = useState(false);
  const [isUnderline, setIsUnderline] = useState(false);
  const [isStrikethrough, setIsStrikethrough] = useState(false);
  const [isSubscript, setIsSubscript] = useState(false);
  const [isSuperscript, setIsSuperscript] = useState(false);
  const [canUndo, setCanUndo] = useState(false);
  const [canRedo, setCanRedo] = useState(false);
  const [isRTL, setIsRTL] = useState(false);
  const [isEditable, setIsEditable] = useState(() => editor.isEditable());
  const [isImageCaption, setIsImageCaption] = useState(false);

  const $updateToolbar = useCallback(() => {
    const selection = $getSelection();
    if ($isRangeSelection(selection)) {
      if (activeEditor !== editor && $isEditorIsNestedEditor(activeEditor)) {
        const rootElement = activeEditor.getRootElement();
        setIsImageCaption(
          !!rootElement?.parentElement?.classList.contains(
            'image-caption-container',
          ),
        );
      } else {
        setIsImageCaption(false);
      }

      const anchorNode = selection.anchor.getNode();
      let element =
        anchorNode.getKey() === 'root'
          ? anchorNode
          : $findMatchingParent(anchorNode, (e) => {
              const parent = e.getParent();
              return parent !== null && $isRootOrShadowRoot(parent);
            });

      if (element === null) {
        element = anchorNode.getTopLevelElementOrThrow();
      }

      const elementKey = element.getKey();
      const elementDOM = activeEditor.getElementByKey(elementKey);

      // Update text format
      setIsBold(selection.hasFormat('bold'));
      setIsItalic(selection.hasFormat('italic'));
      setIsUnderline(selection.hasFormat('underline'));
      setIsStrikethrough(selection.hasFormat('strikethrough'));
      setIsSubscript(selection.hasFormat('subscript'));
      setIsSuperscript(selection.hasFormat('superscript'));
      setIsRTL($isParentElementRTL(selection));

      // Update links
      const node = getSelectedNode(selection);
      const parent = node.getParent();
      if ($isLinkNode(parent) || $isLinkNode(node)) {
        setIsLink(true);
      } else {
        setIsLink(false);
      }

      const tableNode = $findMatchingParent(node, $isTableNode);
      if ($isTableNode(tableNode)) {
        setRootType('table');
      } else {
        setRootType('root');
      }

      if (elementDOM !== null) {
        if ($isListNode(element)) {
          const parentList = $getNearestNodeOfType<ListNode>(
            anchorNode,
            ListNode,
          );
          const type = parentList
            ? parentList.getListType()
            : element.getListType();
          setBlockType(type);
        } else {
          const type = $isHeadingNode(element)
            ? element.getTag()
            : element.getType();
          if (type in blockTypeToBlockName) {
            setBlockType(type as keyof typeof blockTypeToBlockName);
          }
        }
      }
      // Handle buttons
      setFontColor(
        $getSelectionStyleValueForProperty(selection, 'color', '#000'),
      );
      setBgColor(
        $getSelectionStyleValueForProperty(
          selection,
          'background-color',
          '#fff',
        ),
      );
      setFontFamily(
        $getSelectionStyleValueForProperty(selection, 'font-family', 'Arial'),
      );
      let matchingParent;
      if ($isLinkNode(parent)) {
        // If node is a link, we need to fetch the parent paragraph node to set format
        matchingParent = $findMatchingParent(
          node,
          (parentNode) => $isElementNode(parentNode) && !parentNode.isInline(),
        );
      }

      // If matchingParent is a valid node, pass it's format type
      setElementFormat(
        $isElementNode(matchingParent)
          ? matchingParent.getFormatType()
          : $isElementNode(node)
            ? node.getFormatType()
            : parent?.getFormatType() || 'left',
      );
    }
    if ($isRangeSelection(selection) || $isTableSelection(selection)) {
      setFontSize(
        $getSelectionStyleValueForProperty(selection, 'font-size', '15px'),
      );
    }
  }, [activeEditor, editor]);

  useEffect(() => {
    return editor.registerCommand(
      SELECTION_CHANGE_COMMAND,
      (_payload, newEditor) => {
        setActiveEditor(newEditor);
        $updateToolbar();
        return false;
      },
      COMMAND_PRIORITY_CRITICAL,
    );
  }, [editor, $updateToolbar]);

  useEffect(() => {
    activeEditor.getEditorState().read(() => {
      $updateToolbar();
    });
  }, [activeEditor, $updateToolbar]);

  useEffect(() => {
    return mergeRegister(
      editor.registerEditableListener((editable) => {
        setIsEditable(editable);
      }),
      activeEditor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          $updateToolbar();
        });
      }),
      activeEditor.registerCommand<boolean>(
        CAN_UNDO_COMMAND,
        (payload) => {
          setCanUndo(payload);
          return false;
        },
        COMMAND_PRIORITY_CRITICAL,
      ),
      activeEditor.registerCommand<boolean>(
        CAN_REDO_COMMAND,
        (payload) => {
          setCanRedo(payload);
          return false;
        },
        COMMAND_PRIORITY_CRITICAL,
      ),
    );
  }, [$updateToolbar, activeEditor, editor]);

  useEffect(() => {
    return activeEditor.registerCommand(
      KEY_MODIFIER_COMMAND,
      (payload) => {
        const event: KeyboardEvent = payload;
        const { code, ctrlKey, metaKey } = event;

        if (code === 'KeyK' && (ctrlKey || metaKey)) {
          event.preventDefault();
          let url: string | null;
          if (!isLink) {
            setIsLinkEditMode(true);
            url = sanitizeUrl('https://');
          } else {
            setIsLinkEditMode(false);
            url = null;
          }
          return activeEditor.dispatchCommand(TOGGLE_LINK_COMMAND, url);
        }
        return false;
      },
      COMMAND_PRIORITY_NORMAL,
    );
  }, [activeEditor, isLink, setIsLinkEditMode]);

  const applyStyleText = useCallback(
    (styles: Record<string, string>, skipHistoryStack?: boolean) => {
      activeEditor.update(
        () => {
          const selection = $getSelection();
          if (selection !== null) {
            $patchStyleText(selection, styles);
          }
        },
        skipHistoryStack ? { tag: 'historic' } : {},
      );
    },
    [activeEditor],
  );

  const clearFormatting = useCallback(() => {
    activeEditor.update(() => {
      const selection = $getSelection();
      if ($isRangeSelection(selection) || $isTableSelection(selection)) {
        const anchor = selection.anchor;
        const focus = selection.focus;
        const nodes = selection.getNodes();
        const extractedNodes = selection.extract();

        if (anchor.key === focus.key && anchor.offset === focus.offset) {
          return;
        }

        nodes.forEach((node, idx) => {
          // We split the first and last node by the selection
          // So that we don't format unselected text inside those nodes
          if ($isTextNode(node)) {
            // Use a separate variable to ensure TS does not lose the refinement
            let textNode = node;
            if (idx === 0 && anchor.offset !== 0) {
              textNode = textNode.splitText(anchor.offset)[1] || textNode;
            }
            if (idx === nodes.length - 1) {
              textNode = textNode.splitText(focus.offset)[0] || textNode;
            }
            /**
             * If the selected text has one format applied
             * selecting a portion of the text, could
             * clear the format to the wrong portion of the text.
             *
             * The cleared text is based on the length of the selected text.
             */
            // We need this in case the selected text only has one format
            const extractedTextNode = extractedNodes[0];
            if (nodes.length === 1 && $isTextNode(extractedTextNode)) {
              textNode = extractedTextNode;
            }

            if (textNode.__style !== '') {
              textNode.setStyle('');
            }
            if (textNode.__format !== 0) {
              textNode.setFormat(0);
              $getNearestBlockElementAncestorOrThrow(textNode).setFormat('');
            }
            node = textNode;
          } else if ($isHeadingNode(node) || $isQuoteNode(node)) {
            node.replace($createParagraphNode(), true);
          } else if ($isDecoratorBlockNode(node)) {
            node.setFormat('');
          }
        });
      }
    });
  }, [activeEditor]);

  const onFontColorSelect = useCallback(
    (value: string, skipHistoryStack: boolean) => {
      applyStyleText({ color: value }, skipHistoryStack);
    },
    [applyStyleText],
  );

  const onBgColorSelect = useCallback(
    (value: string, skipHistoryStack: boolean) => {
      applyStyleText({ 'background-color': value }, skipHistoryStack);
    },
    [applyStyleText],
  );

  const toggleFullScreen = () => {
    setIsFullScreen(!isFullScreen);
  };

  const canViewerSeeInsertDropdown = !isImageCaption;

  return (
    <Stack
      direction='row'
      alignItems='center'
      gap={0.5}
      flexWrap='wrap'
      position='sticky'
      top={0}
      sx={{
        backgroundColor: 'white',
        zIndex: 2,
        pb: 0.5,
      }}
    >
      <Tooltip title='Undo' placement='top'>
        <span>
          <IconButton
            size='small'
            disabled={!canUndo || !isEditable}
            onClick={() => {
              activeEditor.dispatchCommand(UNDO_COMMAND, undefined);
            }}
          >
            <IconArrowBackUp fontSize={18} />
          </IconButton>
        </span>
      </Tooltip>

      <Tooltip title='Redo' placement='top'>
        <span>
          <IconButton
            size='small'
            disabled={!canRedo || !isEditable}
            onClick={() => {
              activeEditor.dispatchCommand(REDO_COMMAND, undefined);
            }}
          >
            <IconArrowForwardUp fontSize={18} />
          </IconButton>
        </span>
      </Tooltip>

      <Divider orientation='vertical' flexItem />

      {blockType in blockTypeToBlockName && activeEditor === editor && (
        <>
          <BlockFormatDropDown
            disabled={!isEditable}
            blockType={blockType} // @ts-ignore
            rootType={rootType}
            editor={activeEditor}
          />
          <ElementFormatDropdown
            disabled={!isEditable}
            value={elementFormat}
            editor={activeEditor}
            isRTL={isRTL}
          />
          <Divider orientation='vertical' flexItem />
        </>
      )}

      {isComplexToolbar && showFontSelector && (
        <>
          <FontDropDown
            disabled={!isEditable}
            // eslint-disable-next-line react/style-prop-object
            style='font-family'
            value={fontFamily}
            editor={activeEditor}
          />
          <Divider orientation='vertical' flexItem />
        </>
      )}

      {isComplexToolbar && (
        <>
          <FontSize
            selectionFontSize={fontSize.slice(0, -2)}
            editor={activeEditor}
            disabled={!isEditable}
          />

          <Divider orientation='vertical' flexItem />
        </>
      )}

      {isComplexToolbar && (
        <Tooltip title='Bold' placement='top'>
          <span>
            <IconButton
              size='small'
              disabled={!isEditable}
              onClick={() => {
                activeEditor.dispatchCommand(FORMAT_TEXT_COMMAND, 'bold');
              }}
              sx={{
                color: isBold ? 'primary.main' : undefined,
              }}
            >
              <IconBold fontSize={18} />
            </IconButton>
          </span>
        </Tooltip>
      )}

      {isComplexToolbar && (
        <Tooltip title='Italic' placement='top'>
          <span>
            <IconButton
              size='small'
              disabled={!isEditable}
              onClick={() => {
                activeEditor.dispatchCommand(FORMAT_TEXT_COMMAND, 'italic');
              }}
              sx={{
                color: isItalic ? 'primary.main' : undefined,
              }}
            >
              <IconItalic fontSize={18} />
            </IconButton>
          </span>
        </Tooltip>
      )}

      {isComplexToolbar && (
        <Tooltip title='Underline' placement='top'>
          <IconButton
            size='small'
            disabled={!isEditable}
            onClick={() => {
              activeEditor.dispatchCommand(FORMAT_TEXT_COMMAND, 'underline');
            }}
            sx={{
              color: isUnderline ? 'primary.main' : undefined,
            }}
          >
            <IconUnderline fontSize={18} />
          </IconButton>
        </Tooltip>
      )}

      <FontColorDropDown
        disabled={!isEditable}
        color={fontColor}
        onChange={onFontColorSelect}
      />

      <BackgroundColorDropDown
        disabled={!isEditable}
        color={bgColor}
        onChange={onBgColorSelect}
      />

      {isComplexToolbar && showMoreFormattingOptions && (
        <TextFormatDropdown
          activeEditor={activeEditor}
          isEditable={isEditable}
          isStrikethrough={isStrikethrough}
          isSubscript={isSubscript}
          isSuperscript={isSuperscript}
          clearFormatting={clearFormatting}
        />
      )}

      {isComplexToolbar && canViewerSeeInsertDropdown && (
        <>
          <Divider orientation='vertical' flexItem />

          <EditorToolbarInsertDropdown
            isEditable={isEditable}
            activeEditor={activeEditor}
          />

          {templateType && (
            <>
              <Divider orientation='vertical' flexItem />

              <EditorToolbarInsertTemplate
                isEditable={isEditable}
                activeEditor={activeEditor}
                templateType={templateType}
              />
            </>
          )}

          <Divider orientation='vertical' flexItem />

          <Tooltip
            title={isFullScreen ? 'Exit Full Screen' : 'Full Screen'}
            placement='top'
          >
            <IconButton size='small' onClick={toggleFullScreen}>
              {isFullScreen ? (
                <IconMinimize fontSize={18} />
              ) : (
                <IconMaximize fontSize={18} />
              )}
            </IconButton>
          </Tooltip>
        </>
      )}
    </Stack>
  );
}
