import React, { useMemo } from 'react';

import { Scrollbar } from '@lupa/ui/components/Scrollbar';

import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';

import { MobileNavSection } from './MobileNavSection';
import { TenantSwitch } from './TenantSwitch';

const MOBILE_NAV_WIDTH = 280;

const useCssVars = (color: string) => {
  const theme: any = useTheme();

  return useMemo(() => {
    switch (color) {
      // Blend-in and discrete have no difference on mobile because
      // there's a backdrop and differences are not visible
      case 'blend-in':
      case 'discrete':
        if (theme.palette.mode === 'dark') {
          return {
            '--nav-bg': theme.palette.background.default,
            '--nav-color': theme.palette.neutral[100],
            '--nav-logo-border': theme.palette.neutral[700],
            '--nav-section-title-color': theme.palette.neutral[400],
            '--nav-item-color': theme.palette.neutral[400],
            '--nav-item-hover-bg': 'rgba(255, 255, 255, 0.04)',
            '--nav-item-active-bg': 'rgba(255, 255, 255, 0.04)',
            '--nav-item-active-color': theme.palette.text.primary,
            '--nav-item-disabled-color': theme.palette.neutral[600],
            '--nav-item-icon-color': theme.palette.neutral[500],
            '--nav-item-icon-active-color': theme.palette.primary.main,
            '--nav-item-icon-disabled-color': theme.palette.neutral[700],
            '--nav-item-chevron-color': theme.palette.neutral[700],
            '--nav-scrollbar-color': theme.palette.neutral[400],
          };
        }
        return {
          '--nav-bg': theme.palette.background.default,
          '--nav-color': theme.palette.text.primary,
          '--nav-logo-border': theme.palette.neutral[100],
          '--nav-section-title-color': theme.palette.neutral[400],
          '--nav-item-color': theme.palette.text.secondary,
          '--nav-item-hover-bg': theme.palette.action.hover,
          '--nav-item-active-bg': theme.palette.action.selected,
          '--nav-item-active-color': theme.palette.text.primary,
          '--nav-item-disabled-color': theme.palette.neutral[400],
          '--nav-item-icon-color': theme.palette.neutral[400],
          '--nav-item-icon-active-color': theme.palette.primary.main,
          '--nav-item-icon-disabled-color': theme.palette.neutral[400],
          '--nav-item-chevron-color': theme.palette.neutral[400],
          '--nav-scrollbar-color': theme.palette.neutral[900],
        };
      case 'evident':
        if (theme.palette.mode === 'dark') {
          return {
            '--nav-bg': theme.palette.neutral[800],
            '--nav-color': theme.palette.common.white,
            '--nav-logo-border': theme.palette.neutral[700],
            '--nav-section-title-color': theme.palette.neutral[400],
            '--nav-item-color': theme.palette.neutral[400],
            '--nav-item-hover-bg': 'rgba(255, 255, 255, 0.04)',
            '--nav-item-active-bg': 'rgba(255, 255, 255, 0.04)',
            '--nav-item-active-color': theme.palette.common.white,
            '--nav-item-disabled-color': theme.palette.neutral[500],
            '--nav-item-icon-color': theme.palette.neutral[400],
            '--nav-item-icon-active-color': theme.palette.primary.main,
            '--nav-item-icon-disabled-color': theme.palette.neutral[500],
            '--nav-item-chevron-color': theme.palette.neutral[600],
            '--nav-scrollbar-color': theme.palette.neutral[400],
          };
        }
        return {
          '--nav-bg': theme.palette.neutral[800],
          '--nav-color': theme.palette.common.white,
          '--nav-logo-border': theme.palette.neutral[700],
          '--nav-section-title-color': theme.palette.neutral[400],
          '--nav-item-color': theme.palette.neutral[400],
          '--nav-item-hover-bg': 'rgba(255, 255, 255, 0.04)',
          '--nav-item-active-bg': 'rgba(255, 255, 255, 0.04)',
          '--nav-item-active-color': theme.palette.common.white,
          '--nav-item-disabled-color': theme.palette.neutral[500],
          '--nav-item-icon-color': theme.palette.neutral[400],
          '--nav-item-icon-active-color': theme.palette.primary.main,
          '--nav-item-icon-disabled-color': theme.palette.neutral[500],
          '--nav-item-chevron-color': theme.palette.neutral[600],
          '--nav-scrollbar-color': theme.palette.neutral[400],
        };

      default:
        return {};
    }
  }, [theme, color]);
};

type MobileNavProps = {
  open: boolean;
  onClose: () => void;
  sections: any[];
  color?: string;
};

export const MobileNav = ({
  color = 'evident',
  open,
  onClose,
  sections = [],
}: MobileNavProps) => {
  const cssVars = useCssVars(color);

  return (
    <Drawer
      anchor='left'
      onClose={onClose}
      open={open}
      PaperProps={{
        sx: {
          ...cssVars,
          backgroundColor: 'var(--nav-bg)',
          color: 'var(--nav-color)',
          width: MOBILE_NAV_WIDTH,
        },
      }}
      variant='temporary'
    >
      <Scrollbar
        sx={{
          height: '100%',
          '& .simplebar-content': {
            height: '100%',
          },
          '& .simplebar-scrollbar:before': {
            background: 'var(--nav-scrollbar-color)',
          },
        }}
      >
        <Stack sx={{ height: '100%' }}>
          <TenantSwitch />
          <Stack
            component='nav'
            spacing={2}
            sx={{
              flexGrow: 1,
              px: 2,
            }}
          >
            {sections.map((section) => (
              <MobileNavSection
                key={section.title}
                section={section}
                subheader={section.subheader}
              />
            ))}
          </Stack>

          <Box sx={{ p: 3 }}>
            <Typography color='neutral.400' variant='body2'>
              Powered by{' '}
              <span style={{ color: '#9e77ed', fontWeight: 700 }}>Lupa</span>
            </Typography>
          </Box>
        </Stack>
      </Scrollbar>
    </Drawer>
  );
};
