import React from 'react';

import ReactDOM from 'react-dom/client';

import { AuthProvider } from '@lupa/ui/contexts/auth/AuthProvider';
import { AuthTokenRefresher } from '@lupa/ui/contexts/auth/AuthTokenRefresher';
import '@lupa/ui/styles/globals.css';
import { initSentryClient } from '@lupa/ui/utils/instrumentClient';

import '@fontsource/poppins/100.css';
import '@fontsource/poppins/200.css';
import '@fontsource/poppins/300.css';
import '@fontsource/poppins/400.css';
import '@fontsource/poppins/500.css';
import '@fontsource/poppins/600.css';
import '@fontsource/poppins/700.css';
import '@fontsource/poppins/800.css';
import '@fontsource/poppins/900.css';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';

import { enGB } from 'date-fns/locale';
import { enableMapSet as immerEnableMapSet } from 'immer';
import ReactGA from 'react-ga4';

import App from './App';
import { getSupabase } from './lib/supabase';
import reportWebVitals from './reportWebVitals';
import { updateGlobalSingletonWithSession } from './singletons/globalSingleton';
import { API_ENDPOINT, IS_DEV } from './utils/networkUtils';

import './index.css';

if (!IS_DEV) {
  ReactGA.initialize('G-XM7LZKG9RE');

  initSentryClient({
    dsn: 'https://7505b62ca90ade09743d30c01e601747@o4507455239553024.ingest.de.sentry.io/4507455244337232',
    backendUrl: API_ENDPOINT,
    environment: IS_DEV ? 'development' : 'production',
  });
}

immerEnableMapSet();

const root = ReactDOM.createRoot(
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  document.getElementById('root') as HTMLElement,
);

const AppWithProviders = () => (
  <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enGB}>
    <AuthProvider
      supabaseProvider={getSupabase}
      sessionUpdateCallback={updateGlobalSingletonWithSession}
    >
      <AuthTokenRefresher />
      <App />
    </AuthProvider>
  </LocalizationProvider>
);

root.render(
  IS_DEV ? (
    <React.StrictMode>
      <AppWithProviders />
    </React.StrictMode>
  ) : (
    <AppWithProviders />
  ),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.debug))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
