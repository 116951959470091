import React, { useEffect } from 'react';

import StyledSplitPills from '@lupa/ui/components/StyledSplitPills';
import {
  APPOINTMENT_NOTIFICATION_REASON,
  APPOINTMENT_STATUS,
} from '@lupa/utils/enums';
import AppointmentNotification from '@lupa/work/components/appointments/AppointmentNotification';
import { AppointmentUpdateForm } from '@lupa/work/components/appointments/upsert/AppointmentUpdate';
import RichTextEditor from '@lupa/work/components/editors/RichTextEditor';
import { StatusPillDropdown } from '@lupa/work/components/ui/DropdownStatusPill';

import LoadingButton from '@mui/lab/LoadingButton/LoadingButton';
import { Avatar, Box, Button, Stack, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { IconCalendarMonth, IconClock, IconPaw } from '@tabler/icons-react';

import { format } from 'date-fns';
import { FormikProps } from 'formik';

import { getImagePublicUrl } from '../../../utils/get-image-public-url';
import { formatCurrency } from '../../../utils/i18n';
import { AppointmentCreateForm } from './AppointmentCreate';

type AppointmentUpsertConfirmSectionProps = {
  formik:
    | FormikProps<AppointmentUpdateForm>
    | FormikProps<AppointmentCreateForm>;
  setActiveStep: (step: number) => void;
  initialValues: AppointmentUpdateForm | AppointmentCreateForm;
  notifyUserChangedManually: boolean;
  setNotifyUserChangedManually: (value: boolean) => void;
};

export default function AppointmentUpsertConfirmSection({
  formik,
  setActiveStep,
  initialValues,
  notifyUserChangedManually,
  setNotifyUserChangedManually,
}: AppointmentUpsertConfirmSectionProps) {
  useEffect(() => {
    // For some status changes, we might need to notify the user
    if (
      (initialValues.appointment.status !== APPOINTMENT_STATUS.CONFIRMED &&
        formik.values.appointment.status === APPOINTMENT_STATUS.CONFIRMED) ||
      (initialValues.appointment.status !== APPOINTMENT_STATUS.CANCELLED &&
        formik.values.appointment.status === APPOINTMENT_STATUS.CANCELLED) ||
      (initialValues.appointment.status !== APPOINTMENT_STATUS.NO_SHOW &&
        formik.values.appointment.status === APPOINTMENT_STATUS.NO_SHOW)
    ) {
      formik.setFieldValue(
        'notificationReason',
        formik.values.appointment.status,
      );
      if (!notifyUserChangedManually) {
        formik.setFieldValue('notifyUser', true);
      }

      return;
    }
    if (
      // For time changes for confirmed appointments, we might need to notify the user
      initialValues.appointment.start.toString() !==
        formik.values.appointment.start.toString() &&
      formik.values.appointment.status === APPOINTMENT_STATUS.CONFIRMED
    ) {
      formik.setFieldValue(
        'notificationReason',
        APPOINTMENT_NOTIFICATION_REASON.TIME,
      );

      if (!notifyUserChangedManually) {
        formik.setFieldValue('notifyUser', true);
      }

      return;
    }

    formik.setFieldValue('notificationReason', null);
    formik.setFieldValue('notifyUser', false);
  }, [formik.values.appointment]);

  useEffect(() => {
    if (formik.values.appointment.status === APPOINTMENT_STATUS.REQUESTED) {
      // By default at this stage we assume that the appointment will be confirmed
      formik.setFieldValue('appointment.status', APPOINTMENT_STATUS.CONFIRMED);
    }
  }, []);

  return (
    <Grid container spacing={3}>
      <Stack direction='column' spacing={2} sx={{ flexGrow: 1 }}>
        <Stack
          direction='row'
          justifyContent='space-between'
          sx={{
            borderRadius: 2,
            border: '1px solid rgb(124, 58, 237, 0.8)',
            backgroundColor: 'rgb(124, 58, 237, 0.2)',
            p: 2,
          }}
        >
          <Typography variant='subtitle2' sx={{ color: 'white' }}>
            Appointment Price
          </Typography>
          <Typography variant='h6' fontWeight={600} sx={{ color: 'white' }}>
            {formatCurrency(formik.values.totalPrice)}
          </Typography>
        </Stack>

        <Stack
          direction='column'
          spacing={2}
          sx={{
            backgroundColor: 'rgb(255, 255, 255, 0.1)',
            p: 2,
            borderRadius: 3,
            width: '100%',
          }}
        >
          <Typography variant='h6' color='white' mb={2}>
            {formik.values.appointment.title}
          </Typography>

          <Stack direction='row' spacing={2}>
            <StyledSplitPills
              style={{ justifyContent: 'center', width: '50%' }}
            >
              <Stack direction='row' alignItems='center' gap={1}>
                <IconCalendarMonth />
                <Typography>
                  {format(
                    new Date(formik.values.appointment.start),
                    'EEE, d MMMM',
                  )}
                </Typography>
              </Stack>

              <Stack direction='row' alignItems='center' gap={1}>
                <IconClock />
                <Typography>
                  {`${format(
                    new Date(formik.values.appointment.start),
                    'HH:mm',
                  )} - ${format(
                    new Date(formik.values.appointment.end),
                    'HH:mm',
                  )}`}
                </Typography>
              </Stack>
            </StyledSplitPills>

            <StyledSplitPills
              style={{ justifyContent: 'center', width: '50%' }}
            >
              <Stack direction='row' alignItems='center' gap={1}>
                <IconPaw />
                <Typography>
                  {/* @ts-ignore */}
                  {formik.values.newPet?.name ?? formik.values.pet?.name}
                </Typography>
              </Stack>

              <Stack direction='row' alignItems='center' gap={1}>
                <Avatar
                  src={getImagePublicUrl(formik.values.employee?.avatar_url)}
                  sx={{ width: 24, height: 24 }}
                >
                  {formik.values.employee?.full_name?.[0]}
                </Avatar>
                <Typography>
                  {formik.values.employee?.full_name ?? 'No preference'}
                </Typography>
              </Stack>
            </StyledSplitPills>
          </Stack>
        </Stack>

        <Box sx={{ backgroundColor: 'white', p: 2, borderRadius: 2 }}>
          <RichTextEditor
            value={formik.values.appointment.booking_notes}
            onChange={(value) => {
              formik.setFieldValue('appointment.booking_notes', value);
            }}
            placeholder='Internal Booking Notes - e.g. "Pet is aggressive, please be careful"'
            sx={{ height: 200, overflow: 'auto' }}
          />
        </Box>

        {formik.values.notificationReason && (
          <AppointmentNotification
            reason={formik.values.notificationReason}
            notifyUser={formik.values.notifyUser}
            onNotifyUserChanged={(value) => {
              setNotifyUserChangedManually(true);
              formik.setFieldValue('notifyUser', value);
            }}
          />
        )}

        <Stack
          direction='row'
          alignItems='center'
          justifyContent='space-between'
          spacing={1}
          sx={{ backgroundColor: 'white', p: 2, borderRadius: 2 }}
        >
          <Typography>Appointment Status</Typography>

          <StatusPillDropdown
            status={formik.values.appointment.status}
            type='appointment'
            setStatus={(newStatus) => {
              formik.setFieldValue('appointment.status', newStatus);
            }}
          />
        </Stack>
      </Stack>

      <Stack
        direction='row'
        justifyContent='flex-end'
        mt={4}
        spacing={1}
        sx={{ width: '100%' }}
      >
        <Button
          sx={{ color: 'white' }}
          onClick={() => {
            setActiveStep(0);
          }}
        >
          Back
        </Button>

        <LoadingButton
          color='primary'
          loading={formik.isSubmitting}
          variant='contained'
          disabled={!formik.isValid}
          onClick={() => {
            formik.handleSubmit();
          }}
          data-testid='button-confirm-appointment'
          sx={{
            '&.Mui-disabled': {
              background: '#eaeaea',
              color: '#c0c0c0',
            },
          }}
        >
          Confirm
        </LoadingButton>
      </Stack>
    </Grid>
  );
}
