import React, { useState } from 'react';

import CardSection from '@lupa/ui/components/CardSection';
import { RouterLink } from '@lupa/ui/components/RouterLink';
import { TrpcRouterOutputs, trpc } from '@lupa/work/lib/trpc';
import {
  getHealthPlanBillingPeriodTypeLabel,
  getHealthPlanSubscriptionStatusLabel,
} from '@lupa/work/utils/get-labels';
import { getAllowanceNameLabel } from '@lupa/work/utils/healthplan-utils';
import { formatCurrency } from '@lupa/work/utils/i18n';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { IconArrowRight, IconChevronDown } from '@tabler/icons-react';

import { format } from 'date-fns';

import { paths } from '../../paths';

interface HealthPlanSubscriptionsHistoryProps {
  subscriptions:
    | TrpcRouterOutputs['healthPlans']['getClientSubscriptions']
    | TrpcRouterOutputs['healthPlans']['getPetSubscriptions'];
  isClient?: boolean;
}

export default function HealthPlanSubscriptionsHistory({
  subscriptions,
  isClient = false,
}: HealthPlanSubscriptionsHistoryProps) {
  const [expandedSubscription, setExpandedSubscription] = useState<
    string | false
  >(false);

  const handleAccordionChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpandedSubscription(isExpanded ? panel : false);
    };

  return (
    <CardSection title={isClient ? 'Health Plan History' : 'Plan History'}>
      {subscriptions.map((subscription) => (
        <Accordion
          key={subscription.id}
          expanded={expandedSubscription === subscription.id}
          onChange={handleAccordionChange(subscription.id)}
        >
          <AccordionSummary expandIcon={<IconChevronDown />}>
            <Typography>
              {subscription.health_plan.name}{' '}
              {`(${getHealthPlanSubscriptionStatusLabel(
                subscription.subscription_status,
              )})`}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <TableContainer>
              <Table size='small'>
                <TableHead>
                  <TableRow>
                    <TableCell>Status</TableCell>
                    <TableCell>Started On</TableCell>
                    <TableCell>Monthly Price</TableCell>
                    <TableCell>Billing Period</TableCell>
                    <TableCell>Ended On</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      {getHealthPlanSubscriptionStatusLabel(
                        subscription.subscription_status,
                      )}
                    </TableCell>
                    <TableCell>
                      {format(
                        new Date(subscription.started_on),
                        'MMMM d, yyyy',
                      )}
                    </TableCell>
                    <TableCell>
                      {formatCurrency(subscription.health_plan.price)}
                    </TableCell>
                    <TableCell>
                      {getHealthPlanBillingPeriodTypeLabel(
                        subscription.health_plan.billing_period,
                      )}
                    </TableCell>
                    <TableCell>
                      {subscription.ended_on != null
                        ? format(
                            new Date(subscription.ended_on),
                            'MMMM d, yyyy',
                          )
                        : 'Still active'}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            <AllowanceUsage subscriptionId={subscription.id} />
          </AccordionDetails>
        </Accordion>
      ))}
    </CardSection>
  );
}

interface AllowanceUsageProps {
  subscriptionId: string;
}

function AllowanceUsage({ subscriptionId }: AllowanceUsageProps) {
  const { data: allowanceUsages } =
    trpc.healthPlans.getHealthAllowanceUsages.useQuery({
      subscriptionIds: [subscriptionId],
    });

  const allowanceUsagesUpdated =
    allowanceUsages?.flat().map((allowanceUsage) => ({
      ...allowanceUsage,
      appointmentId:
        allowanceUsage?.billing_service?.appointment_id ??
        allowanceUsage?.billing_product?.appointment_id ??
        '',
    })) ?? [];

  return (
    <>
      <Typography variant='subtitle1' gutterBottom sx={{ mt: 2 }}>
        Allowance Usage
      </Typography>
      <TableContainer>
        <Table size='small'>
          <TableHead>
            <TableRow>
              <TableCell>Allowance Name</TableCell>
              <TableCell>Quantity</TableCell>
              <TableCell>Used On</TableCell>
            </TableRow>
          </TableHead>
          {allowanceUsagesUpdated != null && (
            <TableBody>
              {(allowanceUsagesUpdated.filter(Boolean) ?? []).map(
                (allowanceUsage) => (
                  <TableRow key={allowanceUsage.id}>
                    <TableCell>
                      {getAllowanceNameLabel({
                        allowance: allowanceUsage.allowance,
                      })}
                    </TableCell>
                    <TableCell>{allowanceUsage.quantity}</TableCell>
                    <TableCell>
                      {format(
                        new Date(allowanceUsage.usage_date),
                        'MMMM d, yyyy',
                      )}
                    </TableCell>
                    <TableCell align='right' sx={{ paddingRight: 0 }}>
                      <IconButton
                        LinkComponent={RouterLink}
                        href={paths.appointments.details(
                          allowanceUsage.appointmentId,
                        )}
                        data-testid={`allowance-${allowanceUsage.id}-usage-history-button-open-appointment`}
                      >
                        <IconArrowRight size={20} />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ),
              )}
            </TableBody>
          )}
        </Table>
      </TableContainer>
    </>
  );
}
