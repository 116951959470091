import React, { useMemo, useState } from 'react';

import { COLORS } from '@lupa/ui/utils/colors';
import {
  APPOINTMENT_NOTIFICATION_REASON,
  APPOINTMENT_TYPE,
  DISCOUNT_TYPE,
} from '@lupa/utils/enums';
import { TrpcRouterOutputs, trpc } from '@lupa/work/lib/trpc';
import {
  billingProductValidationSchema,
  billingServiceValidationSchema,
} from '@lupa/work/validators/appointmentValidators';

import { Box } from '@mui/material';
import Grid from '@mui/material/Grid2';
import Stack from '@mui/material/Stack';

import { addMinutes } from 'date-fns';
import { useFormik } from 'formik';
import toast from 'react-hot-toast';
import { z } from 'zod';
import { toFormikValidate, toFormikValidationSchema } from 'zod-formik-adapter';

import { invalidateAppointmentQueries } from '../../../api/mutations';
import { useRouter } from '../../../hooks/use-router';
import { paths } from '../../../paths';
import AppointmentUpsertCalendarView from './AppointmentUpsertCalendarView';
import AppointmentUpsertConfirmSection from './AppointmentUpsertConfirmSection';
import AppointmentUpsertDetailsSection from './AppointmentUpsertDetailsSection';
import AppointmentUpsertInvoicingView from './AppointmentUpsertInvoicingView';

type Appointment = TrpcRouterOutputs['appointments']['getAppointment'];

type InitialValuesParamsType = {
  appointment: Appointment;
};

export type AppointmentUpdateForm = z.infer<typeof validationSchema>;
export type AppointmentUpdateFormInitialValues = ReturnType<
  typeof useGetInitialValues
>;

const useGetInitialValues = ({ appointment }: InitialValuesParamsType) => {
  return useMemo(() => {
    const mainEmployee =
      appointment.employees.find((e) => e.is_main_employee)?.employee ?? null;
    const pet = appointment.pet;
    const client = appointment.client;

    return {
      client,
      employee: mainEmployee,
      appointmentId: appointment.id,
      pet,
      appointment: {
        id: appointment.id,
        visit_type: appointment.visit_type_id
          ? {
              id: appointment.visit_type_id,
              name: appointment.visit_type_name,
              category: appointment.visit_type_category,
            }
          : null,
        title: appointment.title,
        location: appointment.location,
        client_notes: appointment.client_notes,
        booking_notes: appointment.booking_notes,
        notes: appointment.notes,
        start: appointment.start
          ? appointment.start
          : new Date(new Date().setMinutes(0, 0, 0)).toISOString(),
        end: appointment.end
          ? appointment.end
          : addMinutes(
              new Date(new Date().setMinutes(0, 0, 0)),
              60,
            ).toISOString(),
        status: appointment.status,
      },

      totalPrice: appointment.invoice.amount_due,
      apply_discount: appointment.apply_discount,
      discount: appointment.discount,
      discount_type: appointment.discount_type,

      notifyUser: true,
      notificationReason: null,
    } as AppointmentUpdateForm;
  }, [appointment]);
};

const validationSchema = z.object({
  client: z
    .object({
      id: z.string().min(1, 'Client is required'),
    })
    .nullish(),
  pet: z
    .object({
      id: z.string().min(1, 'Pet is required'),
      name: z.string(),
      owner_full_name: z.string(),
    })
    .nullish(),
  employee: z.object(
    {
      id: z.string().min(1, 'Employee is required'),
      full_name: z.string(),
      avatar_url: z.string().nullish(),
    },
    {
      message: 'Employee is required',
    },
  ),
  appointment: z.object({
    id: z.string().min(1, 'Appointment is required'),
    visit_type: z.object(
      {
        id: z.string().min(1, 'Appointment type is required'),
        name: z.string().min(1, 'Appointment type is required'),
        category: z.nativeEnum(APPOINTMENT_TYPE),
      },
      {
        message: 'Appointment type is required',
      },
    ),
    status: z.string(),
    title: z.string().min(1, 'Title is required'),
    location: z
      .string()
      .max(255, 'Location must be 255 characters or shorter')
      .nullish(),
    client_notes: z.string().nullish(),
    booking_notes: z.string().nullish(),
    start: z.string().datetime({
      offset: true,
      message: 'Start date is required',
    }),
    end: z.string().datetime({
      offset: true,
      message: 'End date is required',
    }),
  }),

  totalPrice: z.number(),
  apply_discount: z.boolean().nullable(),
  discount: z.number(),
  discount_type: z.nativeEnum(DISCOUNT_TYPE),
  services: z.array(billingServiceValidationSchema).optional(),
  products: z.array(billingProductValidationSchema).optional(),
  prescribed_products: z.array(billingProductValidationSchema).optional(),

  notifyUser: z.boolean(),
  notificationReason: z.nativeEnum(APPOINTMENT_NOTIFICATION_REASON).nullish(),
});

type AppointmentUpdateProps = {
  appointment: Appointment;
  handleClose?: () => void;
  isNotification?: boolean;
};

export default function AppointmentUpdate({
  appointment,
  isNotification = false,
  handleClose,
}: AppointmentUpdateProps) {
  const [activeStep, setActiveStep] = useState(0);
  const [showCreateClient, setShowCreateClient] = useState(false);
  const [notifyUserChangedManually, setNotifyUserChangedManually] =
    useState(false);

  const router = useRouter();

  const upsertAppointmentMutation =
    trpc.appointments.updateAppointment.useMutation({
      onSuccess: ({ updatedAppointmentId }) => {
        handleClose?.();

        invalidateAppointmentQueries(updatedAppointmentId, isNotification);

        toast.success('Appointment updated');
        formik.resetForm();

        router.replace(paths.calendar.index);
      },
      onError: () => {
        toast.error('Something went wrong!');
        handleClose?.();
      },
    });

  const initialValues = useGetInitialValues({
    appointment,
  });

  const formik = useFormik<AppointmentUpdateForm>({
    initialValues,
    validationSchema: toFormikValidationSchema(validationSchema),
    validate: toFormikValidate(validationSchema),
    onSubmit: async () => {
      await upsertAppointmentMutation.mutateAsync({
        appointmentId: appointment.id,
        data: formik.values,
      });
    },
  });

  return (
    <Grid container spacing={2} flexGrow={1}>
      <Grid flexGrow={1} size={4}>
        <Stack
          direction='column'
          spacing={2}
          sx={{
            backgroundColor: '#1C2536',
            px: 2,
            py: 2,
            borderRadius: 4,
            flexGrow: 1,
            height: '100%',
            overflow: 'auto',
          }}
        >
          <Stack direction='row' gap={2} justifyContent='space-between'>
            <Box
              sx={{
                backgroundColor: COLORS.main,
                height: 8,
                flexGrow: 1,
                borderRadius: 4,
              }}
            />
            <Box
              sx={{
                backgroundColor:
                  activeStep === 1 ? COLORS.main : 'rgba(255, 255, 255, 0.2)',
                height: 8,
                flexGrow: 1,
                borderRadius: 4,
              }}
            />
          </Stack>

          {activeStep === 0 && (
            <AppointmentUpsertDetailsSection
              formik={formik}
              action='update'
              setActiveStep={setActiveStep}
              showCreateClient={showCreateClient}
              setShowCreateClient={setShowCreateClient}
              handleClose={handleClose}
            />
          )}

          {activeStep === 1 && (
            <AppointmentUpsertConfirmSection
              formik={formik}
              setActiveStep={setActiveStep}
              initialValues={initialValues}
              notifyUserChangedManually={notifyUserChangedManually}
              setNotifyUserChangedManually={setNotifyUserChangedManually}
            />
          )}
        </Stack>
      </Grid>
      <Grid size={8}>
        {activeStep === 0 && (
          <AppointmentUpsertCalendarView isThreeDaysView formik={formik} />
        )}

        {activeStep === 1 && <AppointmentUpsertInvoicingView formik={formik} />}
      </Grid>
    </Grid>
  );
}
