import React, { useMemo } from 'react';

import { alpha, styled, useTheme } from '@mui/material/styles';

import ApexChart, { Props as ApexProps } from 'react-apexcharts';

import deepMerge from '../utils/deep-merge';

const useChartOptions = (): ApexCharts.ApexOptions => {
  const theme = useTheme();

  return {
    chart: {
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: true,
      background: {
        borderRadius: 8,
        padding: 8,
        opacity: 0.9,
        borderWidth: 0,
      },
    },
    grid: {
      borderColor: theme.palette.divider,
      strokeDashArray: 2,
      xaxis: {
        lines: {
          show: false,
        },
      },
      yaxis: {
        lines: {
          show: false,
        },
      },
    },
    legend: {
      horizontalAlign: 'right',
      labels: {
        colors: theme.palette.text.secondary,
      },
      position: 'top',
      show: false,
    },
    markers: {
      hover: {
        size: undefined,
        sizeOffset: 2,
      },
      shape: 'circle',
      size: 4,
      strokeWidth: 0,
    },
    stroke: {
      curve: 'smooth',
      dashArray: [0, 3],
      lineCap: 'butt',
      width: 3,
    },
    theme: {
      mode: theme.palette.mode,
    },
    xaxis: {
      axisBorder: {
        show: true,
      },
      axisTicks: {
        show: true,
      },
      labels: {
        style: {
          colors: theme.palette.text.secondary,
        },
      },
    },
    yaxis: [
      {
        axisBorder: {
          show: true,
        },
        axisTicks: {
          show: true,
        },
        labels: {
          show: true,
        },
      },
    ],
  };
};

const StyledChart = styled(ApexChart)(({ theme }) => ({
  '& .apexcharts-xaxistooltip': {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[16],
    borderRadius: theme.shape.borderRadius,
    border: 0,
    '&::before, &::after': {
      display: 'none',
    },
  },
  '& .apexcharts-tooltip': {
    '&.apexcharts-theme-light, &.apexcharts-theme-dark': {
      backdropFilter: 'blur(6px)',
      background: 'transparent',
      border: 0,
      boxShadow: 'none',
      '& .apexcharts-tooltip-title': {
        // @ts-ignore
        background: alpha(theme.palette.neutral[900], 0.8),
        border: 0,
        color: theme.palette.common.white,
        margin: 0,
      },
      '& .apexcharts-tooltip-series-group': {
        // @ts-ignore
        background: alpha(theme.palette.neutral[900], 0.7),
        border: 0,
        color: theme.palette.common.white,
      },
    },
  },
}));

interface ChartProps extends ApexProps {
  options: ApexProps['options'];
  height?: number;
  merge?: boolean;
}

export function Chart({
  options,
  height = 350,
  merge = true,
  ...rest
}: ChartProps) {
  const chartOptions = useChartOptions();

  const mergedOptions = useMemo(() => {
    if (!merge && options) {
      return options;
    }

    return deepMerge(chartOptions, options);
  }, [chartOptions, options, merge]);

  if (!mergedOptions.chart) {
    return null;
  }

  return (
    <StyledChart
      options={mergedOptions}
      type={mergedOptions.chart.type}
      height={height}
      {...rest}
    />
  );
}
