import {
  COMPANY_ACCOUNT_TYPE,
  PERMISSION_CATEGORY,
  PERMISSION_LEVEL_VALUE,
  STORE_CATEGORY,
} from '@lupa/utils/enums';
import { globalSingleton } from '@lupa/work/singletons/globalSingleton';

export const getIsVetStore = () => {
  return globalSingleton.currentStore?.categories?.includes(STORE_CATEGORY.VET);
};

export const getIsFullVetStore = () => {
  return (
    globalSingleton.currentStore?.company.account_type ===
      COMPANY_ACCOUNT_TYPE.FULL && getIsVetStore()
  );
};

export const getIsFullStore = () => {
  return (
    globalSingleton.currentStore?.company.account_type ===
      COMPANY_ACCOUNT_TYPE.FULL ||
    globalSingleton.currentStore?.company.account_type ===
      COMPANY_ACCOUNT_TYPE.PROFESSIONAL
  );
};

export const hasEmployeePermissions = (category: PERMISSION_CATEGORY) => {
  const storePermissions = globalSingleton.currentStore?.permissions;

  if (storePermissions == null) {
    return true;
  }

  const employeePermission = globalSingleton.currentEmployee?.permission;

  if (employeePermission == null) {
    return true;
  }

  const storePermission = storePermissions[category];
  if (storePermission == null) {
    return true;
  }

  return storePermission[employeePermission] === PERMISSION_LEVEL_VALUE.ALLOWED;
};
