import React from 'react';

import { trpc } from '@lupa/work/lib/trpc';

import CalendarUpsertEventDialog from './create/CalendarUpsertEventDialog';

type CalendarWrapperUpsertEventDialogProps = {
  event: any;
  onClose: () => void;
  open: boolean;
  isNotification: boolean;
};

export default function CalendarWrapperUpsertEventDialog({
  event,
  onClose,
  open,
  isNotification,
}: CalendarWrapperUpsertEventDialogProps) {
  const { data: appointment, isPending } =
    trpc.appointments.getAppointment.useQuery({
      appointmentId: event?.appointmentId,
    });

  if (isPending) {
    return null;
  }

  return (
    <CalendarUpsertEventDialog
      action='update'
      appointment={appointment}
      onClose={onClose}
      open={open}
      isNotification={isNotification}
    />
  );
}
