import { Label } from '@lupa/ui/components/shadcn/label';

import { HintTooltip } from '../HintTooltip';

interface FormLabelProps {
  label: string;
  required?: boolean;
  hint?: string;
}

export default function FormLabel({ label, required, hint }: FormLabelProps) {
  return (
    <div className='flex items-center'>
      <Label htmlFor='select'>
        {label} {required && <span className='text-destructive'>*</span>}
      </Label>

      {hint && <HintTooltip hint={hint} />}
    </div>
  );
}
