import { trpc } from '@lupa/work/lib/trpc';
import { globalSingleton } from '@lupa/work/singletons/globalSingleton';

import { keepPreviousData } from '@tanstack/react-query';

export default function useGetNotifications() {
  return trpc.store.getStoreNotifications.useQuery(undefined, {
    placeholderData: keepPreviousData,
    enabled: !!globalSingleton.currentStore.id,
    refetchInterval: 30000, // 30 seconds
  });
}
