import React from 'react';

import { PERMISSION_CATEGORY } from '@lupa/utils/enums';
import { globalSingleton } from '@lupa/work/singletons/globalSingleton';

import { Theme } from '@mui/material';
import Badge from '@mui/material/Badge';
import Box, { BoxProps } from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import useMediaQuery from '@mui/material/useMediaQuery';
import {
  IconBell,
  IconHelp,
  IconLayoutSidebarLeftCollapse,
  IconMenu2,
} from '@tabler/icons-react';

import { useAtom } from 'jotai';

import { useGetConversations } from '../api/chatApi';
import { mainDrawerOpenAtom } from '../atoms/atoms';
import useGetNotifications from '../hooks/use-get-notifications';
import { AccountButton } from './AccountButton';
import { SearchButton } from './SearchButton';
import { useCssVars } from './SideNav';
import TopNavItem from './TopNavItem';

const TOP_NAV_HEIGHT = 64;
const SIDE_NAV_WIDTH = 280;

interface TopNavProps extends BoxProps {
  onMobileNavOpen: () => void;
  sections: {
    title: string;
    path: string;
    icon: React.JSX.Element;
    testId: string;
    permission_category: PERMISSION_CATEGORY | null;
  }[];
}

export const TopNav = ({
  onMobileNavOpen,
  sections,
  ...other
}: TopNavProps) => {
  const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));
  const cssVars: any = useCssVars('evident');
  const [mainDrawerOpen, setMainDrawerOpen] = useAtom(mainDrawerOpenAtom);

  const { data: notifications } = useGetNotifications();
  const { data: conversations } = useGetConversations();

  const unreadCount = conversations?.reduce(
    (acc: number, conversation: any) => {
      const lastMessage = conversation.last_message;
      const participant = conversation.participants.find(
        (p: any) => p.profile_id === globalSingleton.currentEmployee.profile_id,
      );

      if (
        participant &&
        lastMessage &&
        lastMessage.length &&
        lastMessage[0].message &&
        new Date(lastMessage[0].message.created_at) >
          new Date(participant.read_at)
      ) {
        return acc + 1;
      }
      return acc;
    },
    0,
  );

  return (
    <Box
      component='header'
      sx={{
        backdropFilter: 'blur(6px)',
        backgroundColor: (theme: any) => theme.palette.neutral[800],
        position: 'sticky',
        left: {
          lg: `${SIDE_NAV_WIDTH}px`,
        },
        top: 0,
        width: {
          lg: mainDrawerOpen ? `calc(100% - ${SIDE_NAV_WIDTH}px)` : '100%',
        },
        zIndex: (theme) => theme.zIndex.appBar,
      }}
      {...other}
    >
      <Stack
        alignItems='center'
        direction='row'
        justifyContent='space-between'
        spacing={2}
        sx={{
          minHeight: TOP_NAV_HEIGHT,
          px: 2,
        }}
      >
        <Stack alignItems='center' direction='row' spacing={2}>
          {!lgUp && (
            <IconButton onClick={onMobileNavOpen}>
              <IconMenu2 />
            </IconButton>
          )}

          {lgUp && (
            <Badge
              color='primary'
              overlap='circular'
              sx={{
                '& .MuiBadge-badge': { fontSize: 9, height: 15, minWidth: 15 },
              }}
              badgeContent={mainDrawerOpen ? 0 : notifications?.length}
            >
              <IconButton onClick={() => setMainDrawerOpen(!mainDrawerOpen)}>
                {mainDrawerOpen ? (
                  <IconLayoutSidebarLeftCollapse />
                ) : (
                  <IconBell />
                )}
              </IconButton>
            </Badge>
          )}
        </Stack>

        {lgUp && (
          <Stack
            sx={{
              ...cssVars,
              position: 'absolute',
              left: '50%',
              transform: 'translateX(-50%)',
            }}
            alignItems='center'
            direction='row'
            spacing={2}
          >
            {sections.map((item) => (
              <TopNavItem
                key={item.path}
                item={{
                  ...item,
                  badgeContent: item.title === 'Chat' ? unreadCount : undefined,
                }}
              />
            ))}
          </Stack>
        )}

        <Stack
          justifySelf='flex-end'
          alignItems='center'
          direction='row'
          spacing={2}
        >
          <Tooltip title='Help'>
            <IconButton id='intercom-help-button'>
              <IconHelp />
            </IconButton>
          </Tooltip>
          <SearchButton />
          <AccountButton />
        </Stack>
      </Stack>
    </Box>
  );
};
