import React, { ReactNode, useMemo } from 'react';

import { PERMISSION_CATEGORY } from '@lupa/utils/enums';
import {
  getIsFullStore,
  hasEmployeePermissions,
} from '@lupa/work/utils/store-utils';
import { notEmpty } from '@lupa/work/utils/type-utils';

import { Theme, styled } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import {
  IconApps,
  IconCalendar,
  IconGraph,
  IconListCheck,
  IconMessageCircle,
  IconPaw,
  IconSettings,
} from '@tabler/icons-react';

import { useAtom } from 'jotai';

import { mainDrawerOpenAtom } from '../atoms/atoms';
import { useMobileNav } from '../hooks/use-mobile-nav';
import { paths } from '../paths';
import { AdminTopNav } from './AdminTopNav';
import { MobileNav } from './MobileNav';
import { SideNav } from './SideNav';
import { TopNav } from './TopNav';

const TOP_NAV_HEIGHT = 64;
const SIDE_NAV_WIDTH = 280;

type VerticalLayoutRootProps = {
  theme?: any;
  mainDrawerOpen: boolean;
};

const VerticalLayoutRoot = styled('div')<VerticalLayoutRootProps>(
  ({ theme, mainDrawerOpen }) => ({
    display: 'flex',
    flex: '1 1 auto',
    maxWidth: '100%',
    backgroundColor: theme.palette.neutral[800],
    [theme.breakpoints.up('lg')]: {
      paddingLeft: mainDrawerOpen ? SIDE_NAV_WIDTH : 0,
    },
  }),
);

type VerticalLayoutContainerProps = {
  theme?: any;
  mainDrawerOpen: boolean;
  lgUp: boolean;
};

const VerticalLayoutContainer = styled('div')<VerticalLayoutContainerProps>(
  ({ theme, mainDrawerOpen, lgUp }) => ({
    display: 'flex',
    flex: '1 1 auto',
    flexDirection: 'column',
    width: '100%',
    borderTopLeftRadius: lgUp && mainDrawerOpen ? 32 : 0,
    backgroundColor: theme.palette.neutral[50],
    maxHeight: `calc(100vh - ${TOP_NAV_HEIGHT}px)`,
    overflowY: 'auto',
  }),
);

type VerticalLayoutProps = {
  children: ReactNode;
  isEnterpriseView?: boolean;
};

export default function VerticalLayout({
  children,
  isEnterpriseView,
}: VerticalLayoutProps) {
  const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));
  const [mainDrawerOpen] = useAtom(mainDrawerOpenAtom);
  const mobileNav = useMobileNav();
  const isFullStore = getIsFullStore();

  const enterpriseSections = useMemo(
    () =>
      [
        {
          title: 'Analytics',
          path: paths.admin.index,
          icon: <IconGraph />,
          testId: 'top-nav-analytics',
          permission_category: null,
        },
        // {
        //   title: 'Calendar',
        //   path: paths.admin.calendar,
        //   icon: <IconCalendar />,
        //   testId: 'top-nav-calendar',
        //   permission_category: null,
        // },
        {
          title: 'Settings',
          path: paths.admin.settings.index,
          testId: 'top-nav-settings',
          icon: <IconSettings />,
          permission_category: null,
        },
      ]
        .filter(notEmpty)
        .filter((item) => {
          if (item.permission_category != null) {
            return hasEmployeePermissions(item.permission_category);
          }

          return true;
        }),
    [],
  );

  const sections = useMemo(
    () =>
      [
        {
          title: 'Calendar',
          path: paths.index,
          icon: <IconCalendar />,
          testId: 'top-nav-calendar',
          permission_category: null,
        },
        {
          title: 'Appointments',
          path: paths.appointments.index,
          icon: <IconListCheck />,
          testId: 'top-nav-appointments',
          permission_category: null,
        },
        {
          title: 'Pets',
          path: paths.clients.pets,
          icon: <IconPaw />,
          testId: 'top-nav-clients',
          permission_category: null,
        },
        {
          title: 'Chat',
          path: paths.chat,
          icon: <IconMessageCircle />,
          testId: 'top-nav-chat',
          permission_category: PERMISSION_CATEGORY.CHAT,
        },
        isFullStore
          ? {
              title: 'Apps',
              path: paths.apps.index,
              icon: <IconApps />,
              testId: 'top-nav-apps',
              permission_category: null,
            }
          : null,
        {
          title: 'Settings',
          path: paths.settings.index,
          testId: 'top-nav-settings',
          icon: <IconSettings />,
          permission_category: null,
        },
      ]
        .filter(notEmpty)
        .filter((item) => {
          if (item.permission_category != null) {
            return hasEmployeePermissions(item.permission_category);
          }

          return true;
        }),
    [isFullStore],
  );

  return (
    <>
      {isEnterpriseView ? (
        <AdminTopNav sections={enterpriseSections} />
      ) : (
        <TopNav sections={sections} onMobileNavOpen={mobileNav.handleOpen} />
      )}

      {lgUp && !isEnterpriseView && <SideNav />}

      {!lgUp && !isEnterpriseView && (
        <MobileNav
          onClose={mobileNav.handleClose}
          open={mobileNav.open}
          sections={sections}
        />
      )}

      <VerticalLayoutRoot mainDrawerOpen={!isEnterpriseView && mainDrawerOpen}>
        <VerticalLayoutContainer
          mainDrawerOpen={!isEnterpriseView && mainDrawerOpen}
          lgUp={lgUp}
        >
          {children}
        </VerticalLayoutContainer>
      </VerticalLayoutRoot>
    </>
  );
}
