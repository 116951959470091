import { DISCOUNT_TYPE } from '@lupa/utils/enums';
import { TrpcRouterOutputs } from '@lupa/work/lib/trpc';
import { globalSingleton } from '@lupa/work/singletons/globalSingleton';
import {
  BillingProductDataType,
  BillingServiceDataType,
} from '@lupa/work/validators/appointmentValidators';

import { P, match } from 'ts-pattern';
import { v4 as uuidv4 } from 'uuid';

export type BillingItem = BillingServiceDataType | BillingProductDataType;

const roundNumber = (number: number, digits = 2): number =>
  Math.round(number * 10 ** digits) / 10 ** digits;

const getItemDiscount = (
  itemNetPrice: number,
  discount?: number,
  discountType?: DISCOUNT_TYPE,
): number => {
  if (!discount || !discountType) {
    return 0;
  }

  return discountType === DISCOUNT_TYPE.PERCENTAGE
    ? itemNetPrice * (discount / 100)
    : discount;
};

export const getItemsWeightedVat = (items: BillingItem[]): number => {
  let billingItemsVatAmount = 0;
  let billingItemsTotalPreTaxPrice = 0;

  items.forEach((item) => {
    const vatRate = item.vat_percentage / 100;
    const preTaxPrice = item.price / (1 + vatRate);
    const vatAmount = item.price - preTaxPrice;

    billingItemsTotalPreTaxPrice += preTaxPrice;
    billingItemsVatAmount += vatAmount;
  });

  return billingItemsVatAmount / billingItemsTotalPreTaxPrice;
};

export const getBillingInfoFromItem = (item: BillingItem) => {
  const itemUnitPricePostTax = item.unit_price;
  const itemPricePostTax = itemUnitPricePostTax * item.quantity;

  const itemFees = match(item)
    .with({ dispensing_fee: P.number }, (item) => item.dispensing_fee)
    .otherwise(() => 0);

  const pricePreDiscount = itemPricePostTax + itemFees;

  const itemDiscount = getItemDiscount(
    pricePreDiscount,
    item.discount,
    item.discount_type,
  );

  const itemPricePostDiscount = itemPricePostTax - itemDiscount;

  const itemTotalPrice = roundNumber(itemPricePostDiscount + itemFees);

  return {
    itemUnitPricePostTax,
    itemPricePostTax,
    itemDiscount,
    itemTotalPrice,
  };
};

export const getBillingInfoFromItems = ({
  items,
  hasGlobalDiscount = false,
  globalDiscount = 0,
  globalDiscountType = DISCOUNT_TYPE.TOTAL,
}: {
  items: BillingItem[];
  hasGlobalDiscount?: boolean | null;
  globalDiscount?: number;
  globalDiscountType?: DISCOUNT_TYPE;
}) => {
  let totalNetPreDiscount = 0;
  let totalDiscount = 0;
  let totalPrice = 0;

  items.forEach((item) => {
    const { itemDiscount, itemTotalPrice, itemPricePostTax } =
      getBillingInfoFromItem(item);
    totalDiscount += itemDiscount;
    totalPrice += itemTotalPrice;
    totalNetPreDiscount += itemPricePostTax;
  });

  if (hasGlobalDiscount && globalDiscount) {
    const globalDiscountAmount =
      globalDiscountType === DISCOUNT_TYPE.PERCENTAGE
        ? totalPrice * (globalDiscount / 100)
        : globalDiscount;

    totalDiscount += globalDiscountAmount;
    totalPrice -= globalDiscountAmount;
  }

  const weightedVatPreGlobalDiscount = getItemsWeightedVat(items);
  const totalGross = totalPrice / (1 + weightedVatPreGlobalDiscount);

  const totalVat = totalPrice - totalGross;

  return {
    totalNetPreDiscount,
    totalDiscount,
    totalGross,
    totalVat,
    totalPrice,
  };
};

export const getNewUnitPriceFromFinalPrice = (
  finalPrice: number,
  item: BillingItem,
) => {
  const itemFees = match(item)
    .with({ dispensing_fee: P.number }, (item) => item.dispensing_fee)
    .otherwise(() => 0);

  let discountAmount: number;

  if (!item.discount || !item.discount_type) {
    discountAmount = 0;
  } else if (item.discount_type === DISCOUNT_TYPE.TOTAL) {
    discountAmount = item.discount;
  } else if (item.discount_type === DISCOUNT_TYPE.PERCENTAGE) {
    discountAmount = finalPrice / ((100 - item.discount) / 100) - finalPrice;
  } else {
    throw new Error('Unsupported discount type.');
  }

  const finalPriceWithoutFees = finalPrice - itemFees;

  const finalPricesPreDiscount = finalPriceWithoutFees + discountAmount;

  return finalPricesPreDiscount / item.quantity;
};

export const getBillingInfoFromSearchedService = (
  service: Nullable<TrpcRouterOutputs['store']['searchServices'][0]>,
): BillingServiceDataType => {
  if (service == null) {
    return {
      id: uuidv4(),
      name: '',
      quantity: 1,
      unit_price: 0,
      price: 0,
      discount: 0,
      discount_type: DISCOUNT_TYPE.PERCENTAGE,
      vat_percentage: globalSingleton.currentStore.vat_percentage,
    };
  }

  return {
    id: uuidv4(),
    service_id: service.id,
    name: service.name,
    quantity: 1,
    unit_price: service.price,
    price: service.price,
    discount: 0,
    discount_type: DISCOUNT_TYPE.PERCENTAGE,
    service_detail: { category: service.category },
    vat_percentage: globalSingleton.currentStore.vat_percentage,
  };
};

export type ProductForBillingProduct = Pick<
  TrpcRouterOutputs['products']['searchProducts'][0],
  | 'id'
  | 'name'
  | 'price'
  | 'vat_percentage'
  | 'has_batches'
  | 'has_subunit'
  | 'measure_unit'
  | 'subunit'
  | 'subunit_multiplier'
  | 'unit'
  | 'category'
  | 'dispensing_fee'
>;

export const getBillingInfoFromSearchedProduct = (
  product: Nullable<ProductForBillingProduct>,
): BillingProductDataType => {
  if (product == null) {
    // Generate a new billing product with a random UUID
    return {
      id: uuidv4(),
      name: '',
      quantity: 1,
      unit_price: 0,
      price: 0,
      discount: 0,
      discount_type: DISCOUNT_TYPE.PERCENTAGE,
      vat_percentage: globalSingleton.currentStore.vat_percentage,
      dispensing_fee: 0,
    };
  }

  const billingProduct: BillingProductDataType = {
    id: uuidv4(),
    product_id: product.id,
    name: product.name,
    quantity: 1,
    price: product.price,
    unit_price: product.price,
    vat_percentage: product.vat_percentage,
    discount: 0,
    discount_type: DISCOUNT_TYPE.PERCENTAGE,
    batches: product.has_batches ? [] : undefined,
    has_subunit: false,
    subunit_multiplier: null,
    measure_unit: null,
    unit: null,
    product_detail: { category: product.category },
    subunit: null,
    dispensing_fee: product.dispensing_fee,
  };

  if (product.has_subunit) {
    const subunitMultiplier =
      product.measure_unit === product.subunit && product.subunit_multiplier
        ? 1 / product.subunit_multiplier
        : 1;

    billingProduct.unit_price *= subunitMultiplier;
    billingProduct.price *= subunitMultiplier;
    billingProduct.has_subunit = true;
    billingProduct.subunit_multiplier = product.subunit_multiplier;
    billingProduct.measure_unit = product.measure_unit;
    billingProduct.unit = product.unit;
    billingProduct.subunit = product.subunit;
  }

  return billingProduct;
};

export const getItemPriceDetails = ({
  item,
}: {
  item: { price: number; vat_percentage: number; discount_percentage: number };
}): {
  vatAmount: number;
  preTaxPrice: number;
} => {
  const priceAfterDiscount = item.price * (1 - item.discount_percentage / 100);
  const vatRate = item.vat_percentage / 100;
  const preTaxPrice = priceAfterDiscount / (1 + vatRate);
  const vatAmount = priceAfterDiscount - preTaxPrice;

  return { vatAmount, preTaxPrice };
};
