import { HelpCircle } from 'lucide-react';

import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from './shadcn/tooltip';

interface HintTooltipProps {
  hint: string;
  side?: 'top' | 'right' | 'bottom' | 'left';
  align?: 'start' | 'center' | 'end';
}

export function HintTooltip({
  hint,
  side = 'top',
  align = 'center',
}: HintTooltipProps) {
  return (
    <TooltipProvider>
      <Tooltip delayDuration={100}>
        <TooltipTrigger asChild>
          <HelpCircle className='text-muted-foreground -mt-1 ml-1 h-4 w-4' />
        </TooltipTrigger>

        <TooltipContent side={side} align={align}>
          <p className='max-w-xs text-xs'>{hint}</p>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
}
