// Required for TRPC
// eslint-disable-next-line @nx/enforce-module-boundaries
import type { TrpcWorkRouter } from '@lupa/server/routers/trpcWorkRouter';
import { globalSingleton } from '@lupa/work/singletons/globalSingleton';
import { API_ENDPOINT } from '@lupa/work/utils/networkUtils';

import {
  createTRPCProxyClient,
  createTRPCReact,
  httpBatchLink,
  splitLink,
  unstable_httpSubscriptionLink,
} from '@trpc/react-query';
import type { inferRouterInputs, inferRouterOutputs } from '@trpc/server';

// @ts-ignore
import { EventSourcePolyfill } from 'event-source-polyfill';

export type TrpcRouterInputs = inferRouterInputs<TrpcWorkRouter>;
export type TrpcRouterOutputs = inferRouterOutputs<TrpcWorkRouter>;

export const trpc = createTRPCReact<TrpcWorkRouter>();

const trpcLinks = [
  splitLink({
    condition: (op) => op.type === 'subscription',
    true: unstable_httpSubscriptionLink({
      url: `${API_ENDPOINT}/api/trpc/work`,
      EventSource: EventSourcePolyfill,
      eventSourceOptions() {
        return {
          headers: {
            authorization: globalSingleton.accessToken!,
            storeid: globalSingleton.currentStore?.id,
            companyid: globalSingleton.currentCompany?.id,
          },
          withCredentials: true,
        };
      },
    }),
    false: httpBatchLink({
      url: `${API_ENDPOINT}/api/trpc/work`,
      maxURLLength: 8000, // Cloudfront max is 8192, so we'll add some safety margin
      headers() {
        return {
          authorization: globalSingleton.accessToken!,
          storeid: globalSingleton.currentStore?.id,
          companyid: globalSingleton.currentCompany?.id,
        };
      },
    }),
  }),
];

export const trpcClient = trpc.createClient({
  links: trpcLinks,
});

export const trcpProxyClient = createTRPCProxyClient<TrpcWorkRouter>({
  links: trpcLinks,
});
