import React, { useCallback, useMemo } from 'react';

import LoadingButton from '@lupa/ui/components/LoadingButton';
import AutocompleteInput from '@lupa/ui/components/form/AutocompleteInput';
import CheckboxInput from '@lupa/ui/components/form/CheckboxInput';
import NumberInput from '@lupa/ui/components/form/NumberInput';
import TextInput from '@lupa/ui/components/form/TextInput';
import { Button } from '@lupa/ui/components/shadcn/button';
import { Form, FormField } from '@lupa/ui/components/shadcn/form';
import { APPOINTMENT_TYPE } from '@lupa/utils/enums';
import {
  MakeNonNullishFieldsNullable,
  assertEnumValue,
} from '@lupa/utils/types/utils';
import {
  UpsertStoreServiceValidatorDataType,
  upsertStoreServiceValidatorData,
} from '@lupa/utils/validators/storeValidators';
import { invalidateAllQueries } from '@lupa/work/api/mutations';
import { TrpcRouterOutputs, trpc } from '@lupa/work/lib/trpc';
import { globalSingleton } from '@lupa/work/singletons/globalSingleton';
import { getIsVetStore } from '@lupa/work/utils/store-utils';

import { zodResolver } from '@hookform/resolvers/zod';

import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';

import {
  getAppointmentCategoriesForStoreCategories,
  getAppointmentCategoryLabel,
} from '../../consts/storeCategories';
import CurrencyInput from '../CurrencyInput';
import SearchVenomCodes from '../search/SeachVenomCodes';

type Service = TrpcRouterOutputs['store']['getServices']['data'][0];

type UpsertStoreServiceForm =
  MakeNonNullishFieldsNullable<UpsertStoreServiceValidatorDataType> & {
    id: string;
    procurement_cost: number | null;
  };

const useGetInitialValues = (service?: Service) => {
  return useMemo<UpsertStoreServiceForm>(() => {
    return {
      id: service?.id ?? crypto.randomUUID(),
      category: service?.category ?? null,
      name: service?.name ?? null,
      price: service?.price ?? null,
      venom_code: service?.venom_code ?? null,
      is_external: service?.external_reference != null,
      margin: service?.margin ?? null,
      external_reference: service?.external_reference ?? null,
      procurement_cost: service?.margin
        ? service.price / (1 + service.margin / 100)
        : null,
    };
  }, [service]);
};

interface UpsertStoreServiceSettingsModalProps {
  service?: Service;
  onClose: () => void;
}

export default function UpsertStoreServiceSettingsModal({
  service,
  onClose,
}: UpsertStoreServiceSettingsModalProps) {
  const initialValues = useGetInitialValues(service);

  const upsertServiceMutation = trpc.store.upsertStoreService.useMutation({
    onSuccess: () => {
      invalidateAllQueries();
      toast.success('Service updated');
      onClose();
    },
    onError: () => {
      toast.error('Something went wrong!');
    },
  });

  const form = useForm<UpsertStoreServiceForm>({
    resolver: zodResolver(upsertStoreServiceValidatorData),
    defaultValues: initialValues,
    mode: 'onTouched',
  });

  const onSubmit = useCallback(
    (data: UpsertStoreServiceForm) => {
      const parsed = upsertStoreServiceValidatorData.safeParse(data);
      if (parsed.success) {
        upsertServiceMutation.mutate({ data: parsed.data });
      } else {
        console.error(parsed.error.errors);
      }
    },
    [upsertServiceMutation],
  );

  const isVetStore = getIsVetStore();
  const schema = upsertStoreServiceValidatorData.innerType();

  return (
    <Form {...form}>
      <div className='grid auto-rows-min gap-4 md:grid-cols-2'>
        <FormField
          control={form.control}
          name='category'
          render={({ field: { ref: _ref, ...fieldProps } }) => {
            return (
              <AutocompleteInput
                {...fieldProps}
                value={
                  fieldProps.value
                    ? {
                        id: fieldProps.value,
                        label: getAppointmentCategoryLabel(fieldProps.value),
                      }
                    : null
                }
                onChange={(v) => {
                  assertEnumValue(APPOINTMENT_TYPE, v.id);
                  form.setValue('category', v.id);
                }}
                label='Category'
                required={!schema.shape.category.isOptional()}
                options={getAppointmentCategoriesForStoreCategories(
                  globalSingleton.currentStore.categories,
                ).map((category) => ({
                  id: category,
                  label: getAppointmentCategoryLabel(category),
                }))}
                error={form.formState.errors.category?.message}
              />
            );
          }}
        />

        {isVetStore ? (
          <FormField
            control={form.control}
            name='name'
            render={({ field: { ref: _ref, ...fieldProps } }) => {
              return (
                <SearchVenomCodes
                  {...fieldProps}
                  value={
                    fieldProps.value
                      ? {
                          id: fieldProps.value,
                          label: fieldProps.value,
                        }
                      : null
                  }
                  onChange={(v) => {
                    form.setValue('name', v.label);
                  }}
                  venomType={4}
                  label='Name (Select or type)'
                  required={!schema.shape.name.isOptional()}
                  freeSolo
                  onOptionSelected={(v) => {
                    form.setValue('venom_code', v.venom_code);
                  }}
                  error={form.formState.errors.name?.message}
                />
              );
            }}
          />
        ) : (
          <FormField
            control={form.control}
            name='name'
            render={({ field: { ref: _ref, ...fieldProps } }) => (
              <TextInput
                {...fieldProps}
                label='Name'
                required={!schema.shape.name.isOptional()}
                error={form.formState.errors.name?.message}
              />
            )}
          />
        )}

        {isVetStore && (
          <FormField
            control={form.control}
            name='venom_code'
            render={({ field: { ref: _ref, ...fieldProps } }) => (
              <NumberInput
                {...fieldProps}
                label='Venom Code'
                isInteger
                minValue={0}
                showArrows={false}
                required={!schema.shape.venom_code.isOptional()}
                error={form.formState.errors.venom_code?.message}
                formatOptions={{ useGrouping: false }}
              />
            )}
          />
        )}

        <FormField
          control={form.control}
          name='price'
          render={({ field: { ref: _ref, ...fieldProps } }) => (
            <CurrencyInput
              {...fieldProps}
              label='Price (incl. VAT)'
              showArrows={false}
              disabled={form.watch('is_external') ?? false}
              required={!schema.shape.price.isOptional()}
              error={form.formState.errors.price?.message}
            />
          )}
        />
      </div>

      <div className='my-2 flex'>
        <FormField
          control={form.control}
          name='is_external'
          render={({ field: { ref: _ref, ...fieldProps } }) => {
            return (
              <CheckboxInput
                {...fieldProps}
                afterValueChange={(newValue) => {
                  // If the checkbox is unchecked, clear the margin
                  if (!newValue) {
                    form.setValue('margin', null);
                    form.setValue('procurement_cost', null);
                    form.setValue('external_reference', null);
                  } else {
                    form.setValue('procurement_cost', form.getValues('price'));
                  }
                }}
                label='Is External?'
                helperText='If the service is external, you can add a procurement cost, margin & reference ID.'
                className='w-full'
                required={false}
                error={form.formState.errors.is_external?.message}
                expandableField={
                  <div className='flex gap-2'>
                    <FormField
                      control={form.control}
                      name='external_reference'
                      render={({ field: { ref: _ref, ...fieldProps } }) => (
                        <TextInput
                          {...fieldProps}
                          value={fieldProps.value ?? null}
                          label='Reference ID'
                          required={true}
                          error={
                            form.formState.errors.external_reference?.message
                          }
                        />
                      )}
                    />

                    <FormField
                      control={form.control}
                      name='procurement_cost'
                      render={({ field: { ref: _ref, ...fieldProps } }) => {
                        return (
                          <CurrencyInput
                            {...fieldProps}
                            className='w-40'
                            label='Procurement cost'
                            showArrows={false}
                            required={false}
                            error={
                              form.formState.errors.procurement_cost?.message
                            }
                            afterValueChange={(newValue) => {
                              const currentMargin =
                                form.getValues('margin') ?? 0;

                              form.setValue(
                                'price',
                                (1 + currentMargin / 100) * (newValue ?? 0),
                              );
                            }}
                          />
                        );
                      }}
                    />

                    <FormField
                      control={form.control}
                      name='margin'
                      render={({ field: { ref: _ref, ...fieldProps } }) => {
                        return (
                          <NumberInput
                            {...fieldProps}
                            className='w-40'
                            label='Mark up'
                            showArrows={false}
                            required={false}
                            inlineEndAddOn='%'
                            minValue={0}
                            maxValue={100}
                            error={form.formState.errors.margin?.message}
                            afterValueChange={(newValue) => {
                              const currentProcurementCost =
                                form.getValues('procurement_cost') ?? 0;

                              form.setValue(
                                'price',
                                (1 + (newValue ?? 0) / 100) *
                                  currentProcurementCost,
                              );
                            }}
                          />
                        );
                      }}
                    />
                  </div>
                }
              />
            );
          }}
        />
      </div>

      <div className='flex justify-end gap-2'>
        <Button
          variant='outline'
          className='w-[100px]'
          onClick={() => {
            onClose();
          }}
        >
          Cancel
        </Button>

        <LoadingButton
          variant='default'
          className='w-[100px]'
          onClick={form.handleSubmit(onSubmit)}
          loading={upsertServiceMutation.isPending}
        >
          Save
        </LoadingButton>
      </div>
    </Form>
  );
}
