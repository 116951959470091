import React, { ComponentProps, useState } from 'react';

import AutocompleteInput from '@lupa/ui/components/form/AutocompleteInput';
import { TrpcRouterOutputs, trpc } from '@lupa/work/lib/trpc';

import { keepPreviousData } from '@tanstack/react-query';
import { useDebounce } from '@uidotdev/usehooks';

type SupplierProduct = NonNullable<
  TrpcRouterOutputs['products']['searchSupplierProducts']
>[number];

type SearchSupplierProductsProps<
  TFreeSolo extends boolean = false,
  TClearable extends boolean = false,
> = Omit<
  ComponentProps<
    typeof AutocompleteInput<SupplierProduct, TFreeSolo, TClearable>
  >,
  'options'
>;

export default function SearchSupplierProducts<
  TFreeSolo extends boolean = false,
  TClearable extends boolean = false,
>({
  onOptionSelected,
  ...props
}: SearchSupplierProductsProps<TFreeSolo, TClearable>) {
  const [open, setOpen] = useState(false);
  const [query, setQuery] = useState<string | undefined>(undefined);
  const queryDebounced = useDebounce(query, 300);

  const { data, isLoading } = trpc.products.searchSupplierProducts.useQuery(
    {
      query: queryDebounced,
      supplierId: null,
    },
    {
      enabled: open,
      placeholderData: keepPreviousData,
    },
  );

  return (
    <AutocompleteInput<SupplierProduct, TFreeSolo, TClearable>
      {...props}
      options={data ?? []}
      loading={isLoading}
      onOpenChange={(o) => {
        setOpen(o);
      }}
      onCommandValueChange={(v) => {
        setQuery(v);
      }}
      onOptionSelected={onOptionSelected}
    />
  );
}
