import React, { useEffect, useState } from 'react';

import { stringToColor } from '@lupa/utils/stringUtils';

import { Box, BoxProps, SxProps, Theme, Typography } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import { deepPurple } from '@mui/material/colors';
import { IconUser } from '@tabler/icons-react';

import { P, match } from 'ts-pattern';

function getStringAvatar(name: string, onlyOneInitial?: boolean) {
  const nameSplit = name.split(' ').filter(Boolean);
  const hasMultipleWords = nameSplit.length > 1;

  return {
    color: stringToColor(name),
    text:
      hasMultipleWords && !onlyOneInitial
        ? `${nameSplit[0][0]}${nameSplit[1][0]}`
        : name[0],
  };
}
function Fallback({
  text,
  fallbackIcon,
}: {
  text: Nullish<string>;
  fallbackIcon: React.ReactNode;
}) {
  if (text) {
    return <Typography>{text || ''}</Typography>;
  }

  if (fallbackIcon) {
    return <Box>{fallbackIcon}</Box>;
  }

  return <IconUser color={deepPurple.A400} />;
}

export interface FallbackAvatarProps extends BoxProps {
  src: string | null;
  size:
    | number
    | {
        width?: number;
        height?: number;
      };
  fallbackSrc?: string;
  fallbackIcon?: React.ReactNode;
  isCover?: boolean;
  name?: Nullable<string>;
  onlyOneInitial?: boolean;
  sx?: SxProps<Theme>;
}

export default function FallbackAvatar({
  src,
  size,
  fallbackSrc,
  fallbackIcon,
  isCover,
  name,
  onlyOneInitial = false,
  sx,
}: FallbackAvatarProps) {
  const [currentSrc, setCurrentSrc] = useState<string>(
    src ?? fallbackSrc ?? '',
  );

  useEffect(() => {
    if (src) {
      setCurrentSrc(src);
    }
  }, [src]);

  const handleError = () => {
    if (fallbackSrc && currentSrc !== fallbackSrc) {
      setCurrentSrc(fallbackSrc);
    }
  };

  const stringAvatar = name ? getStringAvatar(name, onlyOneInitial) : null;

  return (
    <Box
      sx={{
        ...sx,
        width: match(size)
          .with(
            { width: P.number.optional(), height: P.number.optional() },
            (s) => s.width,
          )
          .with(P.number, (s) => s)
          .exhaustive(),
        height: match(size)
          .with(
            { width: P.number.optional(), height: P.number.optional() },
            (s) => s.height,
          )
          .with(P.number, (s) => s)
          .exhaustive(),
        flexShrink: 0,
      }}
    >
      {isCover ? (
        <img
          alt='avatar'
          src={currentSrc}
          style={{ borderRadius: 16, width: '100%' }}
          onError={handleError}
        />
      ) : (
        <Avatar
          src={currentSrc}
          imgProps={{ onError: handleError }}
          style={{
            backgroundColor: stringAvatar ? stringAvatar.color : '#f8f3ff',
            width: '100%',
            height: '100%',
            flexShrink: 0,
          }}
        >
          <Fallback text={stringAvatar?.text} fallbackIcon={fallbackIcon} />
        </Avatar>
      )}
    </Box>
  );
}
