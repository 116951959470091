import React from 'react';

import { PERMISSION_CATEGORY } from '@lupa/utils/enums';

import Box, { BoxProps } from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import { IconHelp } from '@tabler/icons-react';

import { AccountButton } from './AccountButton';
import { useCssVars } from './SideNav';
import { TenantSwitch } from './TenantSwitch';
import TopNavItem from './TopNavItem';

const TOP_NAV_HEIGHT = 64;
const SIDE_NAV_WIDTH = 280;

interface AdminTopNavProps extends BoxProps {
  sections: {
    title: string;
    path: string;
    icon: React.JSX.Element;
    testId: string;
    permission_category: PERMISSION_CATEGORY | null;
  }[];
}

export const AdminTopNav = ({ sections, ...other }: AdminTopNavProps) => {
  const cssVars: any = useCssVars('evident');

  return (
    <Box
      component='header'
      sx={{
        backdropFilter: 'blur(6px)',
        backgroundColor: (theme: any) => theme.palette.neutral[800],
        position: 'sticky',
        top: 0,
        width: '100%',
        zIndex: (theme) => theme.zIndex.appBar,
      }}
      {...other}
    >
      <Stack
        alignItems='center'
        direction='row'
        justifyContent='space-between'
        spacing={2}
        sx={{
          minHeight: TOP_NAV_HEIGHT,
          px: 2,
        }}
      >
        <Box sx={{ width: SIDE_NAV_WIDTH, color: 'white' }}>
          <TenantSwitch />
        </Box>

        <Stack
          sx={{
            ...cssVars,
            position: 'absolute',
            left: '50%',
            transform: 'translateX(-50%)',
          }}
          alignItems='center'
          direction='row'
          spacing={2}
        >
          {sections.map((item) => (
            <TopNavItem key={item.path} item={item} />
          ))}
        </Stack>

        <Stack
          justifySelf='flex-end'
          alignItems='center'
          direction='row'
          spacing={2}
        >
          <Tooltip title='Help'>
            <IconButton id='intercom-help-button'>
              <IconHelp />
            </IconButton>
          </Tooltip>

          <AccountButton />
        </Stack>
      </Stack>
    </Box>
  );
};
