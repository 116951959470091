import React from 'react';

import { AutocompleteSearch } from '@lupa/ui/components/AutocompleteSearch';
import {
  StyledToggleButton,
  StyledToggleButtonGroup,
} from '@lupa/ui/components/StyledToggleButton';
import { DISCOUNT_TYPE } from '@lupa/utils/enums';
import { EditHealthPlanAllowanceUsageRow } from '@lupa/work/components/appointments/invoices/EditHealthPlanAllowanceUsageRow';
import NumberInput from '@lupa/work/components/ui/NumberInput';
import { TrpcRouterOutputs, trpc } from '@lupa/work/lib/trpc';
import { globalSingleton } from '@lupa/work/singletons/globalSingleton';
import { getBillingInfoFromSearchedProduct } from '@lupa/work/utils/billing-utils';
import {
  RemainingAllowanceQuantityDataType,
  calculateAllowanceDiscount,
} from '@lupa/work/utils/healthplan-utils';
import { formatCurrency } from '@lupa/work/utils/i18n';
import { BillingProductDataType } from '@lupa/work/validators/appointmentValidators';

import {
  Button,
  IconButton,
  InputAdornment,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import { blueGrey } from '@mui/material/colors';
import { IconCertificate, IconPlus, IconTrash } from '@tabler/icons-react';

import { format } from 'date-fns';
import { produce } from 'immer';

interface ProductsListProps {
  products: BillingProductDataType[];
  onProductChange: (index: number, product: BillingProductDataType) => void;
  onProductRemove: (index: number) => void;
  showUnitPrice: boolean;
  shouldShowVat: boolean;
  hasDiscountsPermission: boolean;
  availableProductAllowances: Record<
    string,
    RemainingAllowanceQuantityDataType[]
  >;
  setAvailableProductAllowances: React.Dispatch<
    React.SetStateAction<Record<string, RemainingAllowanceQuantityDataType[]>>
  >;
  showPrescriptionDetails: boolean;
  onPrescriptionClick: (index: number) => void;
}

export default function AppointmentsInvoiceProductsList({
  products,
  onProductChange,
  onProductRemove,
  showUnitPrice,
  shouldShowVat,
  hasDiscountsPermission,
  availableProductAllowances,
  setAvailableProductAllowances,
  showPrescriptionDetails,
  onPrescriptionClick,
}: ProductsListProps) {
  return (
    <Table size='small' sx={{ width: '100%' }}>
      <TableHead>
        <TableRow>
          <TableCell style={{ minWidth: 300 }}>Product</TableCell>
          <TableCell align='center'>Quantity</TableCell>
          {showUnitPrice && (
            <TableCell align='center'>
              <Stack direction='column' alignItems='center'>
                <span>Unit Price</span>
                <Typography
                  variant='caption'
                  color='text.secondary'
                  sx={{ width: 100 }}
                >
                  (incl. VAT)
                </Typography>
              </Stack>
            </TableCell>
          )}
          <TableCell align='center'>Discount</TableCell>
          {shouldShowVat && <TableCell align='center'>VAT</TableCell>}
          <TableCell align='center'>
            <Stack direction='column'>
              <span>Final Price</span>
              <Typography variant='caption' color='text.secondary'>
                (incl. VAT)
              </Typography>
            </Stack>
          </TableCell>
          <TableCell align='right'>Action</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {products.map((product, idx) => (
          <React.Fragment key={product.id}>
            <TableRow
              sx={{
                '& td, & th': {
                  borderBottom: 'none',
                  borderTop: '1px solid',
                  borderColor: 'divider',
                  paddingTop: (theme) => theme.spacing(2),
                },
                '&:first-of-type td, &:first-of-type th': {
                  borderTop: 'none',
                  borderBottom: 'none',
                },
              }}
            >
              <TableCell>
                <AutocompleteSearch<{
                  ItemType: TrpcRouterOutputs['products']['searchProducts'][0];
                }>
                  trpcFunction={trpc.products.searchProducts}
                  label='Product'
                  getOptionLabel={(option) => option.name ?? option}
                  value={product}
                  showTooltip
                  onSelect={(item) => {
                    onProductChange(
                      idx,
                      getBillingInfoFromSearchedProduct(item),
                    );
                  }}
                />
              </TableCell>
              <TableCell align='center'>
                <NumberInput
                  value={product.quantity}
                  showArrows
                  isInt={false}
                  disabled={
                    product.batches != null && product.batches.length > 0
                  }
                  onChange={(updateQuantity) => {
                    onProductChange(
                      idx,
                      produce(product, (draft) => {
                        draft.quantity = updateQuantity;

                        if (
                          draft.allowance_usage &&
                          draft.allowance_usage.length > 0
                        ) {
                          draft.allowance_usage[0].quantity = Math.min(
                            updateQuantity,
                            draft.allowance_usage[0].quantity,
                          );

                          const discountUpdate = calculateAllowanceDiscount(
                            { ...draft },
                            draft.allowance_usage[0],
                          );

                          draft.allowance_usage =
                            discountUpdate.allowance_usage;
                          draft.discount = discountUpdate.discount;
                          draft.discount_type = discountUpdate.discount_type;
                        } else {
                          draft.allowance_usage = null;
                        }
                      }),
                    );
                  }}
                  showSelect={!!product.has_subunit}
                  selectValue={product.measure_unit}
                  onSelectChange={(e) => {
                    const newUnit = e.target.value;
                    onProductChange(
                      idx,
                      produce(product, (draft) => {
                        draft.measure_unit = newUnit;
                        draft.unit_price =
                          newUnit === draft.unit
                            ? draft.unit_price * (draft.subunit_multiplier ?? 1)
                            : draft.unit_price /
                              (draft.subunit_multiplier ?? 1);

                        if (
                          draft.allowance_usage &&
                          draft.allowance_usage.length > 0
                        ) {
                          const discountUpdate = calculateAllowanceDiscount(
                            { ...draft },
                            draft.allowance_usage[0],
                          );

                          draft.allowance_usage =
                            discountUpdate.allowance_usage;
                          draft.discount = discountUpdate.discount;
                          draft.discount_type = discountUpdate.discount_type;
                        }
                      }),
                    );
                  }}
                  selectOptions={[product.unit, product.subunit]}
                  style={{ width: 60 }}
                />
              </TableCell>
              {showUnitPrice && (
                <TableCell align='center'>
                  {formatCurrency(product.unit_price)}
                </TableCell>
              )}
              <TableCell align='center'>
                <Stack direction='row' alignItems='center' gap={0.5}>
                  <StyledToggleButtonGroup
                    color='primary'
                    value={product.discount_type}
                    disabled={
                      !hasDiscountsPermission ||
                      (product.allowance_usage != null &&
                        product.allowance_usage.length > 0)
                    }
                    exclusive
                    onChange={(e, value) => {
                      if (value !== null) {
                        onProductChange(idx, {
                          ...product,
                          discount_type: value,
                          discount: 0,
                        });
                      }
                    }}
                    sx={{ height: 30 }}
                  >
                    <StyledToggleButton value={DISCOUNT_TYPE.TOTAL} width={28}>
                      £
                    </StyledToggleButton>
                    <StyledToggleButton
                      value={DISCOUNT_TYPE.PERCENTAGE}
                      width={28}
                    >
                      %
                    </StyledToggleButton>
                  </StyledToggleButtonGroup>
                  <NumberInput
                    value={product.discount}
                    isInt={false}
                    disabled={
                      !hasDiscountsPermission ||
                      (product.allowance_usage != null &&
                        product.allowance_usage.length > 0)
                    }
                    onChange={(value) => {
                      let newDiscount = Math.max(0, value);
                      if (product.discount_type === DISCOUNT_TYPE.PERCENTAGE) {
                        newDiscount = Math.min(newDiscount, 100);
                      } else {
                        newDiscount = Math.min(newDiscount, product.unit_price);
                      }
                      onProductChange(idx, {
                        ...product,
                        discount: newDiscount,
                      });
                    }}
                    InputProps={{
                      endAdornment:
                        product.discount_type === DISCOUNT_TYPE.PERCENTAGE ? (
                          <InputAdornment position='end'>%</InputAdornment>
                        ) : null,
                      startAdornment:
                        product.discount_type === DISCOUNT_TYPE.TOTAL ? (
                          <InputAdornment position='start'>£</InputAdornment>
                        ) : null,
                    }}
                    style={{ width: 60 }}
                  />
                </Stack>
              </TableCell>
              {shouldShowVat && (
                <TableCell align='center'>
                  <NumberInput
                    value={product.vat_percentage}
                    isInt={false}
                    onChange={(value) => {
                      onProductChange(idx, {
                        ...product,
                        vat_percentage: value,
                      });
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='end'>%</InputAdornment>
                      ),
                    }}
                    style={{ width: 60 }}
                  />
                </TableCell>
              )}
              <TableCell align='center' style={{ minWidth: 110 }}>
                {formatCurrency(product.price)}
              </TableCell>
              <TableCell align='center'>
                <Stack direction='row' justifyContent='flex-end'>
                  {showPrescriptionDetails && (
                    <Tooltip title='Prescription' placement='top'>
                      <span>
                        <IconButton
                          disabled={!product.name}
                          color={
                            product.prescription_id != null
                              ? 'primary'
                              : 'default'
                          }
                          onClick={() => onPrescriptionClick(idx)}
                        >
                          <IconCertificate />
                        </IconButton>
                      </span>
                    </Tooltip>
                  )}
                  <IconButton
                    color='error'
                    onClick={() => onProductRemove(idx)}
                  >
                    <IconTrash />
                  </IconButton>
                </Stack>
              </TableCell>
            </TableRow>

            {globalSingleton.currentStore.features?.health_plan_enabled && (
              <EditHealthPlanAllowanceUsageRow
                storeItemId={product.product_id ?? ''}
                storeItemCategory={product.product_detail?.category ?? ''}
                itemDetails={product}
                availableAllowances={availableProductAllowances}
                onAllowanceApplied={({
                  allowanceUsed,
                  availableAllowances,
                }) => {
                  setAvailableProductAllowances(availableAllowances);

                  if (allowanceUsed) {
                    const discountUpdate = calculateAllowanceDiscount(
                      { ...product },
                      allowanceUsed,
                    );

                    onProductChange(idx, {
                      ...product,
                      allowance_usage: discountUpdate.allowance_usage,
                      discount: discountUpdate.discount,
                      discount_type: discountUpdate.discount_type,
                    });
                  } else if (
                    product.allowance_usage != null &&
                    product.allowance_usage.length > 0
                  ) {
                    onProductChange(idx, {
                      ...product,
                      allowance_usage: [],
                      discount: 0,
                      discount_type: DISCOUNT_TYPE.PERCENTAGE,
                    });
                  }
                }}
              />
            )}
            {product.batches != null && (
              <TableRow>
                <TableCell
                  style={{ paddingBottom: 0, paddingTop: 0 }}
                  colSpan={12}
                >
                  <Stack
                    direction='column'
                    gap={1}
                    sx={{
                      margin: 1,
                      border: `1px solid ${blueGrey[50]}`,
                      borderRadius: 2,
                      p: 2,
                    }}
                  >
                    {product.batches && product.batches.length === 0 && (
                      <div style={{ alignSelf: 'center' }}>
                        <Button
                          variant='outlined'
                          onClick={() => {
                            onProductChange(idx, {
                              ...product,
                              batches: [
                                {
                                  product_id: product?.product_id ?? '',
                                  batch_number: '',
                                  expiry_date: '',
                                  quantity: product?.quantity ?? 1,
                                },
                              ],
                            });
                          }}
                        >
                          Select Batch
                        </Button>
                      </div>
                    )}
                    {product.batches && product.batches.length > 0 && (
                      <>
                        <Typography variant='h6' gutterBottom>
                          Batches
                        </Typography>
                        {product.batches.map((batch, batchIdx) => (
                          <Stack
                            key={batch.id ?? batchIdx}
                            direction='row'
                            alignItems='center'
                            gap={2}
                          >
                            <AutocompleteSearch<{
                              ItemType: TrpcRouterOutputs['products']['searchProductBatches'][0];
                            }>
                              trpcFunction={trpc.products.searchProductBatches}
                              extraTrpcInput={{ productId: product.product_id }}
                              label='Product'
                              getOptionLabel={(option) => option.batch_number}
                              value={{
                                id: batch?.id,
                                batch_number: batch?.batch_number,
                              }}
                              onSelect={(item) => {
                                const newBatches = [...(product.batches ?? [])];
                                if (item == null) {
                                  newBatches[batchIdx] = {
                                    product_id: '',
                                    batch_number: '',
                                    expiry_date: '',
                                    quantity: 0,
                                  };
                                } else {
                                  newBatches[batchIdx] = {
                                    batch_id: item?.id,
                                    product_id: product.id,
                                    batch_number: item?.batch_number,
                                    expiry_date: item?.expiry_date,
                                    quantity: product?.quantity ?? 1,
                                  };
                                }
                                onProductChange(idx, {
                                  ...product,
                                  batches: newBatches,
                                });
                              }}
                              sx={{ width: 260 }}
                            />
                            {batch?.expiry_date && (
                              <Typography
                                variant='body2'
                                color='text.secondary'
                              >
                                Expiry Date:{' '}
                                {format(
                                  new Date(batch.expiry_date),
                                  'dd/MM/yyyy',
                                )}
                              </Typography>
                            )}
                            <NumberInput
                              value={batch?.quantity}
                              showArrows
                              onChange={(value) => {
                                onProductChange(
                                  idx,
                                  produce(product, (draft) => {
                                    if (draft.batches != null) {
                                      draft.batches[batchIdx].quantity = value;
                                    }
                                  }),
                                );
                              }}
                              showSelect={!!product.has_subunit}
                              selectValue={product.measure_unit}
                              onSelectChange={(e) => {
                                const newUnit = e.target.value;
                                onProductChange(idx, {
                                  ...product,
                                  measure_unit: newUnit,
                                  unit_price:
                                    newUnit === product.unit
                                      ? product.unit_price *
                                        (product.subunit_multiplier ?? 1)
                                      : product.unit_price /
                                        (product.subunit_multiplier ?? 1),
                                });
                              }}
                              selectOptions={[product.unit, product.subunit]}
                              style={{ width: 80 }}
                            />
                            <IconButton
                              color='error'
                              onClick={() => {
                                const newBatches = [...(product.batches ?? [])];
                                newBatches.splice(batchIdx, 1);
                                onProductChange(idx, {
                                  ...product,
                                  batches: newBatches,
                                });
                              }}
                            >
                              <IconTrash />
                            </IconButton>
                          </Stack>
                        ))}
                        <div style={{ alignSelf: 'center' }}>
                          <IconButton
                            onClick={() => {
                              const newBatches = [
                                ...(product.batches ?? []),
                                {
                                  product_id: '',
                                  batch_number: '',
                                  expiry_date: '',
                                  quantity: 0,
                                },
                              ];
                              onProductChange(idx, {
                                ...product,
                                batches: newBatches,
                              });
                            }}
                          >
                            <IconPlus />
                          </IconButton>
                        </div>
                      </>
                    )}
                  </Stack>
                </TableCell>
              </TableRow>
            )}
          </React.Fragment>
        ))}
      </TableBody>
    </Table>
  );
}
