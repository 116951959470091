import React from 'react';

import { FormikProps } from 'formik';

import useFormikUtils from '../../hooks/useFormikUtils';
import NumberInput from '../LupaNumberInput';

interface LupaFormikNumberInputProps
  extends Omit<
    React.ComponentPropsWithoutRef<typeof NumberInput>,
    'name' | 'onChange'
  > {
  name: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formik: FormikProps<any>;
  value: Nullish<number>;
  afterInputChange?: (value: number) => void;
}

export default function LupaFormikNumberInput({
  name,
  formik,
  value,
  afterInputChange,
  ...props
}: LupaFormikNumberInputProps) {
  const { error, touched, helperText } = useFormikUtils(formik, name);

  return React.useMemo(
    () => (
      <NumberInput
        {...props}
        name={name}
        value={value}
        error={error}
        helperText={helperText}
        onChange={(value) => {
          if (!touched) {
            formik.setFieldTouched(name);
          }

          formik.setFieldValue(name, value).then(() => {
            if (value != null) {
              afterInputChange?.(value);
            }
          });
        }}
        onBlur={formik.handleBlur}
      />
    ),
    [name, error, touched, value, props],
  );
}
