import { isValidPhoneNumber } from 'libphonenumber-js';
import { ZodArray, ZodTypeAny, z } from 'zod';

// Our XML parser will parse single elements as arrays of one element.
// This function validate & map them back.
export const zSingleXMLElement = <T extends ZodTypeAny>(x: T) => {
  const arraySchema: ZodArray<T> = z.array(x).length(1);
  return arraySchema.transform((arr: Array<z.infer<typeof x>>) => arr[0]);
};
// The XML parser will return an empty string for self-closing tags.
// We sometimes want to treat these as undefined/no value.
export const zSelfClosingXMLForUndefined = <T extends ZodTypeAny>(
  expectedType: T,
) => {
  return z.union([expectedType, z.literal('').transform(() => undefined)]);
};

// z.coerce.boolean returns true for anything truthy - including 'false'.
// This behaves how you'd expect
export const zBooleanString = ({
  trueValues = ['true', 'yes'],
  falseValues = ['false', 'no'],
  standardiseToLowercase = true,
}: {
  trueValues?: string[];
  falseValues?: string[];
  standardiseToLowercase?: boolean;
} = {}) =>
  z.string().transform((value, ctx) => {
    if (standardiseToLowercase) {
      value = value.toLowerCase();
    }
    if (trueValues.includes(value)) return true;
    if (falseValues.includes(value)) return false;

    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: 'Value must be a boolean',
    });

    return z.NEVER;
  });

export const zTimestampz = () => z.string().datetime({ offset: true });

export const zTimestampzInPast = () =>
  zTimestampz().refine((value) => {
    try {
      return new Date(value) <= new Date();
    } catch {
      return false;
    }
  }, 'Date must be a valid date in the past');

export const zEmail = () =>
  z.union([z.literal(''), z.string().email('Invalid email.')]);

export const zPhoneNumber = () =>
  z.union([
    z.literal(''),
    z.string().refine(
      (value) => {
        try {
          return isValidPhoneNumber(value);
        } catch {
          return false;
        }
      },
      {
        message: 'Invalid phone number.',
      },
    ),
  ]);

export const zLupaAddress = () =>
  z.object({
    formatted_address: z.string(),
    line_1: z.string(),
    line_2: z.string().nullish(),
    line_3: z.string().nullish(),
    city: z.string(),
    country: z.string(),
    postcode: z.string(),
    latitude: z.number().nullish(),
    longitude: z.number().nullish(),
  });

export const zIsoDateString = z.string().date().brand('IsoDateString');
export type IsoDateString = z.infer<typeof zIsoDateString>;
