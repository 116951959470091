'use client';

import * as React from 'react';

import { buttonVariants } from '@lupa/ui/components/shadcn/button';
import { cn } from '@lupa/ui/lib/utils';

import { IconChevronLeft, IconChevronRight } from '@tabler/icons-react';

import { DayPicker, DropdownProps } from 'react-day-picker';

import { ScrollArea } from './scroll-area';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from './select';

export type CalendarProps = React.ComponentProps<typeof DayPicker>;

function Calendar({
  className,
  classNames,
  showOutsideDays = true,
  ...props
}: CalendarProps) {
  return (
    <DayPicker
      showOutsideDays={showOutsideDays}
      className={cn('p-3', className)}
      classNames={{
        months: 'flex flex-col sm:flex-row space-y-4 sm:space-x-4 sm:space-y-0',
        month: 'space-y-4',
        caption: 'flex justify-center pt-1 relative items-center',
        caption_label: 'text-sm font-medium',
        caption_dropdowns: 'flex justify-center gap-1',
        nav: 'space-x-1 flex items-center',
        nav_button: cn(
          buttonVariants({ variant: 'outline' }),
          'h-7 w-7 bg-transparent p-0 opacity-50 hover:opacity-100',
        ),
        nav_button_previous: 'absolute left-1',
        nav_button_next: 'absolute right-1',
        table: 'w-full border-collapse space-y-1',
        head_row: 'flex',
        head_cell:
          'text-muted-foreground rounded-md w-9 font-normal text-[0.8rem]',
        row: 'flex w-full mt-2 rounded-md overflow-hidden',
        cell: 'text-center w-9 text-sm p-0 relative focus-within:relative focus-within:z-20',
        day: cn(
          buttonVariants({ variant: 'ghost' }),
          'h-9 w-9 p-0 font-normal aria-selected:opacity-100 hover:bg-primary hover:text-primary-foreground hover:aria-selected:text-primary-foreground',
        ),
        day_selected:
          'bg-primary text-primary-foreground focus:bg-primary focus:text-primary-foreground',
        day_today: 'bg-primary/10 aria-selected:text-foreground',
        day_outside:
          'text-gray-400 aria-selected:bg-primary/10 aria-selected:text-gray-400 hover:aria-selected:text-gray-400',
        day_disabled: 'text-muted-foreground opacity-50',
        day_range_end: 'rounded-l-none',
        day_range_start: 'rounded-r-none',
        day_range_middle:
          'bg-primary/20 aria-selected:text-accent-foreground aria-selected:rounded-none',
        day_hidden: 'invisible',
        ...classNames,
      }}
      components={{
        IconLeft: () => <IconChevronLeft className='h-4 w-4' />,
        IconRight: () => <IconChevronRight className='h-4 w-4' />,
        // Dropdown from https://gist.github.com/mjbalcueva/1fbcb1be9ef68a82c14d778b686a04fa
        Dropdown: ({ value, onChange, children }: DropdownProps) => {
          const options = React.Children.toArray(
            children,
          ) as React.ReactElement<React.HTMLProps<HTMLOptionElement>>[];
          const selected = options.find((child) => child.props.value === value);
          const handleChange = (value: string) => {
            const changeEvent = {
              target: { value },
            } as React.ChangeEvent<HTMLSelectElement>;
            onChange?.(changeEvent);
          };
          return (
            <Select
              value={value?.toString()}
              onValueChange={(value) => {
                handleChange(value);
              }}
            >
              <SelectTrigger className='pr-1.5 focus:ring-0'>
                <SelectValue>{selected?.props?.children}</SelectValue>
              </SelectTrigger>
              <SelectContent position='popper'>
                <ScrollArea className='h-80'>
                  {options.map((option, id: number) => (
                    <SelectItem
                      key={`${option.props.value}-${id}`}
                      value={option.props.value?.toString() ?? ''}
                    >
                      {option.props.children}
                    </SelectItem>
                  ))}
                </ScrollArea>
              </SelectContent>
            </Select>
          );
        },
      }}
      {...props}
    />
  );
}
Calendar.displayName = 'Calendar';

export { Calendar };
