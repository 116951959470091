import React, { Suspense, lazy } from 'react';

import ErrorBoundaryFallback from '@lupa/ui/components/ErrorBoundaryFallback';
import IntercomComponent from '@lupa/work/components/Intercom';
import RootPage from '@lupa/work/pages/RootPage';

import * as Sentry from '@sentry/react';

import { Outlet } from 'react-router-dom';

import { GuestGuard } from '../guards/GuestGuard';
import { withAuthGuard } from '../hocs/withAuthGuard';
import { AuthLayout } from '../layouts/AuthLayout';

const Redirect = lazy(() => import('../pages/Redirect'));
const AppsPage = lazy(() => import('../pages/AppsPage'));
const ClientCreate = lazy(() => import('../components/clients/ClientCreate'));
const ClientClinicCreate = lazy(
  () => import('../components/clients/ClientClinicCreate'),
);
const EmployeeUpsert = lazy(
  () => import('../components/employees/EmployeeUpsert'),
);
const PetCreate = lazy(() => import('../components/pets/PetCreate'));
const AppointmentCreate = lazy(
  () => import('../components/appointments/upsert/AppointmentCreate'),
);
const Error404Page = lazy(() => import('../pages/404Page'));
const Error401Page = lazy(() => import('../pages/401Page'));
const AppointmentDetailsPage = lazy(
  () => import('../pages/AppointmentDetailsPage'),
);
const InvoiceDetailsPage = lazy(() => import('../pages/InvoiceDetailsPage'));
const AppointmentsPage = lazy(() => import('../pages/AppointmentsPage'));
const CalendarPage = lazy(() => import('../pages/CalendarPage'));
const ChatPage = lazy(() => import('../pages/ChatPage'));
const ClientDetailsEditPage = lazy(
  () => import('../pages/ClientDetailsEditPage'),
);
const ClientClinicDetailsEditPage = lazy(
  () => import('../pages/ClientClinicDetailsEditPage'),
);
const EmployeeDetailsEditPage = lazy(
  () => import('../pages/EmployeeDetailsEditPage'),
);
const ClientDetailsPage = lazy(() => import('../pages/ClientDetailsPage'));
const ClientClinicDetailsPage = lazy(
  () => import('../pages/ClientClinicDetailsPage'),
);
const InventoryPage = lazy(() => import('../pages/InventoryPage'));
const InventoryCreatePage = lazy(() => import('../pages/InventoryCreatePage'));
const InventoryDetailsEditPage = lazy(
  () => import('../pages/InventoryDetailsEditPage'),
);
const InventoryProcessOrderPage = lazy(
  () => import('../pages/InventoryProcessOrderPage'),
);
const AnalyticsPage = lazy(() => import('../pages/AnalyticsPage'));
const AnalyticsDashboardPage = lazy(
  () => import('../pages/AnalyticsDashboardPage'),
);

const ClientsPage = lazy(() => import('../pages/ClientsPage'));
const EmployeeDetailsPage = lazy(() => import('../pages/EmployeeDetailsPage'));
const LoginPage = lazy(() => import('../pages/LoginPage'));
const PetDetailsEditPage = lazy(() => import('../pages/PetDetailsEditPage'));
const PetDetailsPage = lazy(() => import('../pages/PetDetailsPage'));
const SettingsPage = lazy(() => import('../pages/SettingsPage'));
const ForgotPasswordPage = lazy(() => import('../pages/ForgotPasswordPage'));
const UpdatePasswordPage = lazy(() => import('../pages/UpdatePasswordPage'));
const RotaPage = lazy(() => import('../pages/RotaPage'));
const ClaimsPage = lazy(() => import('../pages/ClaimsPage'));
const DebtsPage = lazy(() => import('../pages/DebtsPage'));
const OutlookConnectPage = lazy(() => import('../pages/OutlookConnectPage'));
const DiagnosticsPage = lazy(() => import('../pages/DiagnosticsPage'));
const ClientCommsPage = lazy(() => import('../pages/ClientCommsPage'));
const AdminAnalyticsPage = lazy(() => import('../pages/AdminAnalyticsPage'));
const AdminSettingsPage = lazy(() => import('../pages/AdminSettingsPage'));

const AuthRootLayout = withAuthGuard((props) => <RootPage {...props} />);
const AdminAuthRootLayout = withAuthGuard((props) => (
  <RootPage {...props} isEnterpriseView />
));

export const routes = [
  {
    element: (
      <Sentry.ErrorBoundary fallback={<ErrorBoundaryFallback />} showDialog>
        <AuthRootLayout>
          <Suspense>
            <IntercomComponent />
            <Outlet />
          </Suspense>
        </AuthRootLayout>
      </Sentry.ErrorBoundary>
    ),
    children: [
      {
        index: true,
        element: <CalendarPage />,
      },
      {
        path: 'calendar',
        element: <CalendarPage />,
      },
      {
        path: 'apps',
        element: <AppsPage />,
      },
      {
        path: 'pets/:petId',
        element: <PetDetailsPage />,
      },
      {
        path: 'pets/:petId/edit',
        element: <PetDetailsEditPage />,
      },
      {
        path: 'pets/create',
        element: <PetCreate />,
      },
      {
        path: 'appointments',
        element: <AppointmentsPage />,
      },
      {
        path: 'appointments/:appointmentId',
        element: <AppointmentDetailsPage />,
      },
      {
        path: 'invoices',
        element: <InvoiceDetailsPage />,
      },
      {
        path: 'invoices/:invoiceId',
        element: <InvoiceDetailsPage />,
      },
      {
        path: 'appointments/create',
        element: <AppointmentCreate />,
      },
      {
        path: 'employees/:employeeId',
        element: <EmployeeDetailsPage />,
      },
      {
        path: 'employees/:employeeId/edit',
        element: <EmployeeDetailsEditPage />,
      },
      {
        path: 'employees/create',
        element: <EmployeeUpsert action='create' />,
      },
      {
        path: 'clients',
        element: <ClientsPage />,
      },
      {
        path: 'clients/:clientId',
        element: <ClientDetailsPage />,
      },
      {
        path: 'clients/:clientId/edit',
        element: <ClientDetailsEditPage />,
      },
      {
        path: 'clients/create',
        element: <ClientCreate />,
      },
      {
        path: 'clients/clinic/:clinicId',
        element: <ClientClinicDetailsPage />,
      },
      {
        path: 'clinics/create',
        element: <ClientClinicCreate />,
      },
      {
        path: 'clinics/:clinicId/edit',
        element: <ClientClinicDetailsEditPage />,
      },
      {
        path: 'chat',
        element: <ChatPage />,
      },
      {
        path: 'settings',
        element: <SettingsPage />,
      },
      {
        path: 'inventory',
        element: <InventoryPage />,
      },
      {
        path: 'inventory/create',
        element: <InventoryCreatePage />,
      },
      {
        path: 'inventory/:productId/edit',
        element: <InventoryDetailsEditPage />,
      },
      {
        path: 'inventory/process-order/:orderId',
        element: <InventoryProcessOrderPage />,
      },
      {
        path: 'settings/update-password',
        element: <UpdatePasswordPage />,
      },
      {
        path: 'analytics',
        element: <AnalyticsPage />,
      },
      {
        path: 'analytics/dashboard',
        element: <AnalyticsDashboardPage />,
      },
      {
        path: 'rota',
        element: <RotaPage />,
      },
      {
        path: 'claims',
        element: <ClaimsPage />,
      },
      {
        path: 'debts',
        element: <DebtsPage />,
      },
      {
        path: 'diagnostics',
        element: <DiagnosticsPage />,
      },
      {
        path: 'client-comms',
        element: <ClientCommsPage />,
      },
    ],
  },
  {
    path: 'admin',
    element: (
      <Sentry.ErrorBoundary fallback={<ErrorBoundaryFallback />} showDialog>
        <AdminAuthRootLayout>
          <Suspense>
            <Outlet />
          </Suspense>
        </AdminAuthRootLayout>
      </Sentry.ErrorBoundary>
    ),
    children: [
      {
        index: true,
        element: <AdminAnalyticsPage />,
      },
      {
        path: 'settings',
        element: <AdminSettingsPage />,
      },
    ],
  },
  {
    path: 'auth',
    element: (
      <GuestGuard>
        <AuthLayout>
          <Outlet />
        </AuthLayout>
      </GuestGuard>
    ),
    children: [
      {
        path: 'login',
        element: <LoginPage />,
      },
      {
        path: 'forgot-password',
        element: <ForgotPasswordPage />,
      },
    ],
  },
  {
    path: '404',
    element: <Error404Page />,
  },
  {
    path: '401',
    element: <Error401Page />,
  },
  {
    path: 'waitlist',
    element: <Redirect to='https://lupapets.com/waitlist' />,
  },
  {
    path: '*',
    element: <Error404Page />,
  },
  {
    path: 'outlook-connect',
    element: <OutlookConnectPage />,
  },

  //   {
  //     path: '401',
  //     element: <Error401Page />,
  //   },
  //   {
  //     path: '500',
  //     element: <Error500Page />,
  //   },
];
