import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@lupa/ui/components/shadcn/select';
import { cn } from '@lupa/ui/lib/utils';

import { FormItem } from '../shadcn/form';
import FormHelperText from './FormHelperText';
import FormLabel from './FormLabel';
import InputBaseProps from './InputBaseProps';

interface SelectInputProps<T extends { value: string; label: string }>
  extends InputBaseProps<string | null, string> {
  options: T[];
  onOptionSelected?: (option: T) => void;
}

export default function SelectInput<
  T extends { value: string; label: string },
>({
  label,
  required,
  error,
  placeholder = 'Select...',
  helperText,
  options,
  value,
  onChange,
  afterValueChange,
  onOptionSelected,
  hint,
  className,
  ...props
}: SelectInputProps<T>) {
  return (
    <FormItem className='space-y-1'>
      <FormLabel label={label} required={required} hint={hint} />

      <Select
        value={value ?? ''}
        onValueChange={(newValue) => {
          onChange(newValue);
          afterValueChange?.(newValue);

          const foundOption = options.find(
            (option) => option.value === newValue,
          );

          if (foundOption) {
            onOptionSelected?.(foundOption);
          }
        }}
        {...props}
      >
        <SelectTrigger
          id='select'
          className={cn(
            'bg-background',
            !!error &&
              'border-destructive/80 text-destructive focus-visible:border-destructive/80 focus-visible:ring-destructive/20',
            className,
          )}
        >
          <SelectValue placeholder={placeholder ?? label} />
        </SelectTrigger>

        <SelectContent>
          {options.map((option) => (
            <SelectItem key={option.value} value={option.value}>
              {option.label}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>

      <FormHelperText error={error} helperText={helperText} />
    </FormItem>
  );
}
