import React, { useMemo, useRef, useState } from 'react';

import ImageStack from '@lupa/ui/components/ImageStack';
import Multiselect, {
  MultiselectBaseItemType,
} from '@lupa/ui/components/Multiselect';
import {
  Avatar,
  AvatarFallback,
  AvatarImage,
} from '@lupa/ui/components/shadcn/avatar';
import { Button, buttonVariants } from '@lupa/ui/components/shadcn/button';
import { Calendar } from '@lupa/ui/components/shadcn/calendar';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@lupa/ui/components/shadcn/popover';
import { Tabs, TabsList, TabsTrigger } from '@lupa/ui/components/shadcn/tabs';
import { cn } from '@lupa/ui/lib/utils';
import { capitalize } from '@lupa/ui/utils/stringUtils';
import { getInitials, stringToColor } from '@lupa/utils/stringUtils';
import { TrpcRouterOutputs, trpc } from '@lupa/work/lib/trpc';
import { getImagePublicUrl } from '@lupa/work/utils/get-image-public-url';
import {
  CALENDAR_COLOUR_TYPE,
  CALENDAR_MODE_TYPE,
  CALENDAR_TIME_TYPE,
  CALENDAR_VIEW_TYPE,
} from '@lupa/work/utils/local-enums';

import { Theme } from '@mui/material';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';

import { format } from 'date-fns';
import { useAtom } from 'jotai';
import {
  Calendar1Icon,
  Check,
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  SlidersHorizontalIcon,
  X,
} from 'lucide-react';

import { mainCalendarColourType } from '../../atoms/atoms';

const getView = (
  viewModeType: CALENDAR_MODE_TYPE,
  viewTimeType: CALENDAR_TIME_TYPE,
) => {
  if (viewTimeType === CALENDAR_TIME_TYPE.WEEK) {
    return viewModeType === CALENDAR_MODE_TYPE.MERGED
      ? CALENDAR_VIEW_TYPE.TIME_GRID_WEEK
      : CALENDAR_VIEW_TYPE.RESOURCE_TIME_GRID_WEEK;
  }

  if (viewTimeType === CALENDAR_TIME_TYPE.DAY) {
    return viewModeType === CALENDAR_MODE_TYPE.MERGED
      ? CALENDAR_VIEW_TYPE.TIME_GRID_DAY
      : CALENDAR_VIEW_TYPE.RESOURCE_TIME_GRID_DAY;
  }

  if (viewTimeType === CALENDAR_TIME_TYPE.THREE_DAYS) {
    return viewModeType === CALENDAR_MODE_TYPE.MERGED
      ? CALENDAR_VIEW_TYPE.TIME_GRID_THREE_DAYS
      : CALENDAR_VIEW_TYPE.RESOURCE_TIME_GRID_THREE_DAYS;
  }

  return viewModeType === CALENDAR_MODE_TYPE.MERGED
    ? CALENDAR_VIEW_TYPE.RESOURCE_TIMELINE_WEEK
    : CALENDAR_VIEW_TYPE.RESOURCE_TIMELINE_WEEK;
};

type EmployeeResponse = TrpcRouterOutputs['employees']['getEmployees'];
type Employee = EmployeeResponse['data'][number] & MultiselectBaseItemType;

type CalendarToolbarProps = {
  date: Date;
  isThreeDaysView: boolean;
  onDateNext: () => void;
  onDateToday: () => void;
  onDatePrev: () => void;
  onViewChange: (view: CALENDAR_VIEW_TYPE) => void;
  onDateChange: (date: Date) => void;
  selectedEmployees: Employee[];
  setSelectedEmployees: (employees: Employee[]) => void;
  view: CALENDAR_VIEW_TYPE;
  dateRange?: {
    startDate: string;
    endDate: string;
  };
};

export const CalendarToolbar = ({
  date,
  dateRange,
  isThreeDaysView,
  onDateNext,
  onDateToday,
  onDatePrev,
  onViewChange,
  onDateChange,
  selectedEmployees,
  setSelectedEmployees,
  view,
  ...other
}: CalendarToolbarProps) => {
  const datePickerButtonRef = useRef<HTMLButtonElement>(null);
  const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

  const [colourType, setColourType] = useAtom(mainCalendarColourType);
  const [viewModeType, setViewModeType] = useState<CALENDAR_MODE_TYPE>(
    view === CALENDAR_VIEW_TYPE.TIME_GRID_WEEK ||
      view === CALENDAR_VIEW_TYPE.TIME_GRID_THREE_DAYS
      ? CALENDAR_MODE_TYPE.MERGED
      : CALENDAR_MODE_TYPE.SPLIT,
  );
  const [viewTimeType, setViewTimeType] = useState<CALENDAR_TIME_TYPE>(
    view.includes('Timeline')
      ? CALENDAR_TIME_TYPE.TIMELINE
      : view.includes('Week')
        ? CALENDAR_TIME_TYPE.WEEK
        : view.includes('Three')
          ? CALENDAR_TIME_TYPE.THREE_DAYS
          : CALENDAR_TIME_TYPE.DAY,
  );

  const dateWeekDay = format(date, 'EEE');
  const dateDay = format(date, 'do');
  const endDateDate = dateRange?.endDate
    ? format(new Date(dateRange.endDate), 'do')
    : '';
  const dateMonth = format(date, 'MMM');
  const dateYear = format(date, 'y');
  const isToday = date.getDate() === new Date().getDate();

  const { data } = trpc.employees.getEmployees.useQuery({
    activeOnly: true,
  });

  const employees = useMemo(() => {
    return (data?.data ?? [])
      .sort(
        (a, b) =>
          a.role.localeCompare(b.role) ||
          a.full_name.localeCompare(b.full_name),
      )
      .map((employee) => ({
        ...employee,
        value: employee.id,
        label: employee.full_name,
      }));
  }, [data?.data]);

  const hasSelectedEmployees = selectedEmployees.length > 0;
  const imageStackEmployees = useMemo(() => {
    return selectedEmployees.map((employee: Employee) => ({
      id: employee.id,
      image_url: getImagePublicUrl(employee.avatar_url),
      name: employee.full_name,
    }));
  }, [selectedEmployees, employees]);
  return (
    <div className={'mb-2 flex flex-col gap-2'}>
      <div
        className={
          'flex flex-col flex-wrap items-center justify-between gap-3 px-4 md:flex-row'
        }
        {...other}
      >
        <div
          className={'flex flex-col items-center gap-1 md:flex-row'}
          data-testid='calendar-toolbar-buttons'
        >
          <Popover>
            <PopoverTrigger asChild>
              <Button
                ref={datePickerButtonRef}
                size={'lg'}
                variant={'secondary'}
                data-testid='button-calendar-date-picker'
                className={'flex min-w-[350px] justify-between'}
              >
                <span className='flex items-center gap-4'>
                  <Calendar1Icon
                    className={cn('-ml-2', !isToday && 'text-primary')}
                  />
                  <Typography
                    variant='h5'
                    data-testid='text-calendar-date'
                  >{`${dateWeekDay}, ${dateDay} ${
                    endDateDate ? ` - ${endDateDate}` : ''
                  }`}</Typography>
                </span>
                <span className={'flex items-center gap-2'}>
                  <Typography sx={{ fontWeight: 400 }} variant='h5'>
                    {`${dateMonth} ${dateYear}`}
                  </Typography>
                  <ChevronDownIcon className='-mr-2 ml-2' />
                </span>
              </Button>
            </PopoverTrigger>
            <PopoverContent className={'w-auto'}>
              <Calendar
                selected={date}
                mode={'single'}
                onSelect={(value) => {
                  onDateChange(value ?? new Date());
                }}
              />
            </PopoverContent>
          </Popover>

          <div
            className={'flex items-center gap-2 pt-2 md:ml-2 md:gap-1 md:pt-0'}
          >
            <Button
              size={'icon'}
              variant={'secondary'}
              onClick={onDatePrev}
              data-testid='button-calendar-prev'
            >
              <ChevronLeftIcon />
            </Button>
            <Button size={'sm'} variant={'secondary'} onClick={onDateToday}>
              Today
            </Button>
            <Button
              size={'icon'}
              variant={'secondary'}
              onClick={onDateNext}
              data-testid='button-calendar-next'
            >
              <ChevronRightIcon />
            </Button>
          </div>
        </div>

        <div className={'flex items-center gap-2'}>
          <Multiselect
            allOptions={employees.map((employee) => ({
              ...employee,
              value: employee.full_name,
              label: employee.full_name,
            }))}
            selectedOptions={selectedEmployees ?? []}
            setSelectedOptions={setSelectedEmployees}
            popoverContentProps={{
              align: 'end',
            }}
            trigger={({ open, setOpen }) => (
              <Button
                variant={hasSelectedEmployees ? 'hint' : 'secondary'}
                size='sm'
                aria-expanded={open}
                className={cn('min-w-64 justify-between', {
                  'hover:bg-primary/20': hasSelectedEmployees,
                })}
              >
                {hasSelectedEmployees ? (
                  <ImageStack avatarSize={7} items={imageStackEmployees} />
                ) : (
                  'Showing all calendars'
                )}
                <div className='flex items-center gap-2'>
                  {hasSelectedEmployees && (
                    <div
                      className={cn(
                        buttonVariants({ variant: 'ghost', size: 'icon' }),
                        'text-primary-lighter hover:text-primary-darker group h-4 w-4 shrink-0',
                      )}
                      onClick={(e) => {
                        e.stopPropagation();
                        setSelectedEmployees([]);
                        setOpen(false);
                      }}
                    >
                      <X />
                    </div>
                  )}
                  <ChevronDownIcon className='ml-2 h-4 w-4 shrink-0 opacity-50' />
                </div>
              </Button>
            )}
            renderItem={(option) => {
              return (
                <Button
                  variant='ghost'
                  size='sm'
                  className='flex w-full items-center justify-start gap-1'
                >
                  <Avatar className={'mr-2 h-6 w-6 text-xs ring-2 ring-white'}>
                    <AvatarImage
                      src={getImagePublicUrl(option.avatar_url)}
                      alt={option.label}
                    />
                    <AvatarFallback
                      backgroundColor={stringToColor(option.label ?? '')}
                    >
                      {getInitials(option.label)}
                    </AvatarFallback>
                  </Avatar>
                  {option.label}
                  <Check
                    className={cn(
                      'ml-auto h-4 w-4',
                      selectedEmployees.find((o) => o.id === option.id)
                        ? 'opacity-100'
                        : 'opacity-0',
                    )}
                  />
                </Button>
              );
            }}
          />
          <Popover>
            <PopoverTrigger asChild>
              <Button size={'sm'} variant={'secondary'}>
                <SlidersHorizontalIcon />
                Settings
              </Button>
            </PopoverTrigger>
            <PopoverContent align={mdUp ? 'end' : 'center'}>
              <div className={'flex flex-col gap-4'}>
                {!isThreeDaysView && (
                  <div>
                    <h4 className='ml-1 pb-1 text-sm font-medium text-gray-800'>
                      Time Range
                    </h4>
                    <Tabs
                      value={viewTimeType}
                      onValueChange={(value) => {
                        if (value === 'timeline' && viewModeType === 'merged') {
                          setViewModeType(CALENDAR_MODE_TYPE.SPLIT);
                        }

                        if (value === 'timeline' && colourType === 'employee') {
                          setColourType(CALENDAR_COLOUR_TYPE.CATEGORY);
                        }

                        setViewTimeType(value as CALENDAR_TIME_TYPE); // eslint-disable-line @typescript-eslint/consistent-type-assertions
                        onViewChange(
                          getView(viewModeType, value as CALENDAR_TIME_TYPE),
                        ); // eslint-disable-line @typescript-eslint/consistent-type-assertions
                      }}
                    >
                      <TabsList>
                        <TabsTrigger value={CALENDAR_TIME_TYPE.DAY}>
                          {capitalize(CALENDAR_TIME_TYPE.DAY)}
                        </TabsTrigger>
                        {isThreeDaysView && (
                          <TabsTrigger value={CALENDAR_TIME_TYPE.THREE_DAYS}>
                            {capitalize(CALENDAR_TIME_TYPE.THREE_DAYS)}
                          </TabsTrigger>
                        )}
                        <TabsTrigger value={CALENDAR_TIME_TYPE.WEEK}>
                          {capitalize(CALENDAR_TIME_TYPE.WEEK)}
                        </TabsTrigger>
                        <TabsTrigger value={CALENDAR_TIME_TYPE.TIMELINE}>
                          Continuous
                        </TabsTrigger>
                      </TabsList>
                    </Tabs>
                  </div>
                )}

                <div>
                  <h4 className='ml-1 pb-1 text-sm font-medium text-gray-800'>
                    Calendar Mode
                  </h4>
                  <Tabs
                    value={viewModeType}
                    onValueChange={(newValue) => {
                      setViewModeType(newValue as CALENDAR_MODE_TYPE); // eslint-disable-line @typescript-eslint/consistent-type-assertions
                      onViewChange(
                        getView(newValue as CALENDAR_MODE_TYPE, viewTimeType), // eslint-disable-line @typescript-eslint/consistent-type-assertions
                      );
                    }}
                  >
                    <TabsList>
                      <TabsTrigger
                        value={CALENDAR_MODE_TYPE.MERGED}
                        disabled={viewTimeType === 'timeline'}
                      >
                        {capitalize(CALENDAR_MODE_TYPE.MERGED)}
                      </TabsTrigger>
                      <TabsTrigger
                        value={CALENDAR_MODE_TYPE.SPLIT}
                        disabled={viewTimeType === 'timeline'}
                      >
                        Individual
                      </TabsTrigger>
                    </TabsList>
                  </Tabs>
                </div>

                {!isThreeDaysView && (
                  <div>
                    <h4 className='ml-1 pb-1 text-sm font-medium text-gray-800'>
                      Colour By
                    </h4>
                    <Tabs
                      value={colourType}
                      onValueChange={(value) => {
                        setColourType(value as CALENDAR_COLOUR_TYPE); // eslint-disable-line @typescript-eslint/consistent-type-assertions
                      }}
                    >
                      <TabsList>
                        <TabsTrigger value={CALENDAR_COLOUR_TYPE.EMPLOYEE}>
                          {capitalize(CALENDAR_COLOUR_TYPE.EMPLOYEE)}
                        </TabsTrigger>
                        <TabsTrigger value={CALENDAR_COLOUR_TYPE.CATEGORY}>
                          {capitalize(CALENDAR_COLOUR_TYPE.CATEGORY)}
                        </TabsTrigger>
                      </TabsList>
                    </Tabs>
                  </div>
                )}
              </div>
            </PopoverContent>
          </Popover>
        </div>
      </div>
    </div>
  );
};
