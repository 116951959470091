import LoadingButton from '@lupa/ui/components/LoadingButton';
import { Button } from '@lupa/ui/components/shadcn/button';
import { insertProductValidatorData } from '@lupa/utils/validators/productValidator';
import { trpc } from '@lupa/work/lib/trpc';
import { paths } from '@lupa/work/paths';

import { zodResolver } from '@hookform/resolvers/zod';

import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

import InventoryUpsertProductForm, {
  UpsertProductForm,
} from './InventoryUpsertProductForm';

const useGetInitialValues = (): UpsertProductForm => {
  return {
    category: null,
    medical_category: null,
    name: null,
    item_code: null,
    description: null,

    supplier: null,
    supplier_product: null,

    unit: null,
    has_subunit: false,
    subunit_multiplier: null,
    subunit: null,

    has_batches: false,
    quantity: null,
    measure_unit: null,
    batches: [],

    price: 0,
    vat_code: 'standard',
    procurement_cost: 0,
    vat_percentage: 20,
    margin: 0,

    has_advanced_discounts: false,
    discount_1: 0,
    discount_2: 0,
    discount_3: 0,

    has_fees: false,
    dispensing_fee: 0,

    minimum_stock_level: 0,
    optimal_stock_level: 100,
  };
};

interface InventoryProductCreateProps {
  onComplete?: () => void;
}

export default function InventoryProductCreate({
  onComplete,
}: InventoryProductCreateProps) {
  const navigate = useNavigate();
  const trpcUtils = trpc.useUtils();

  const createProductMutation = trpc.products.createProduct.useMutation({
    onSuccess: () => {
      trpcUtils.products.getProducts.invalidate();

      toast.success('Product created');
      form.reset();

      if (onComplete) {
        onComplete();
        return;
      }

      navigate(paths.inventory.index);
    },
    onError: () => {
      toast.error('Something went wrong!');
    },
  });

  const onSubmit = (data: UpsertProductForm) => {
    const parsed = insertProductValidatorData.safeParse(data);
    if (parsed.success) {
      createProductMutation.mutate({
        data: parsed.data,
      });
    } else {
      console.error(parsed.error.errors);
    }
  };

  const initialValues = useGetInitialValues();

  const form = useForm<UpsertProductForm>({
    resolver: zodResolver(insertProductValidatorData),
    defaultValues: initialValues,
    mode: 'onTouched',
  });

  return (
    <div className='flex flex-col gap-2'>
      <InventoryUpsertProductForm form={form} />

      <div className='flex justify-end gap-2'>
        <div className='flex gap-2'>
          <Button
            variant='outline'
            onClick={() => {
              navigate(-1);
            }}
          >
            Cancel
          </Button>

          <LoadingButton
            variant='default'
            onClick={form.handleSubmit(onSubmit)}
            loading={createProductMutation.isPending}
          >
            Create
          </LoadingButton>
        </div>
      </div>
    </div>
  );
}
