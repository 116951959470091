import { AppointmentCreateForm } from '@lupa/work/components/appointments/upsert/AppointmentCreate';
import { AppointmentUpdateForm } from '@lupa/work/components/appointments/upsert/AppointmentUpdate';

import { FormikProps } from 'formik';

export const isCreateAppointmentFormik = (
  formik:
    | FormikProps<AppointmentUpdateForm>
    | FormikProps<AppointmentCreateForm>,
): formik is FormikProps<AppointmentCreateForm> => {
  return 'newClient' in formik.values;
};
