import React, { useCallback, useMemo } from 'react';

import useFormikUtils from '@lupa/ui/hooks/useFormikUtils';

import {
  FormControl,
  FormHelperText,
  InputLabel,
  ListSubheader,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  SxProps,
  Typography,
} from '@mui/material';
import { IconChevronDown } from '@tabler/icons-react';

import { FormikProps, FormikValues } from 'formik';
import { Paths } from 'type-fest';

interface FormSelectFieldProps<Values, Option, ValueType> {
  name: Paths<Values>;
  label?: string;
  formik: FormikProps<Values>;
  options: Array<Option>;
  getOptionValue: (o: Option) => ValueType;
  getOptionLabel: (o: Option) => string | React.ReactNode;
  disabled?: boolean;
  afterInputChange?: (option: Option) => void;
  sx?: SxProps;
  groupBy?: (option: Option) => string;
  widthStyle?: 'standard' | 'full-width';
  variant?: 'standard' | 'outlined';
}

export default function FormSelectField<
  Values extends FormikValues,
  Option,
  ValueType extends string | number,
>({
  name,
  label,
  formik,
  options,
  getOptionValue,
  getOptionLabel,
  disabled = false,
  afterInputChange,
  sx,
  groupBy,
  widthStyle = 'full-width',
  variant = 'outlined',
}: FormSelectFieldProps<Values, Option, ValueType>) {
  const fieldNameStr = name.toString();

  const { value, touched, error, helperText } = useFormikUtils(
    formik,
    fieldNameStr,
  );

  const handleInputChange = useCallback(
    (e: SelectChangeEvent<unknown>) => {
      const { value } = e.target;
      formik.setFieldValue(fieldNameStr, value).then(() => {
        const selectedOption = options.find(
          (option) => getOptionValue(option) === value,
        );

        if (!selectedOption) {
          return;
        }

        afterInputChange?.(selectedOption);
      });
    },
    [options],
  );

  const renderOptions = useMemo(() => {
    if (!groupBy) {
      return options.map((option) => (
        <MenuItem key={getOptionValue(option)} value={getOptionValue(option)}>
          <Stack direction='row' alignItems='center' gap={1}>
            <Typography variant='subtitle2'>
              {getOptionLabel(option)}
            </Typography>
          </Stack>
        </MenuItem>
      ));
    }

    const groupedOptions = options.reduce(
      (acc, option) => {
        const group = groupBy(option);
        if (!acc[group]) {
          acc[group] = [];
        }
        acc[group].push(option);
        return acc;
      },
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      {} as Record<string, Option[]>,
    );

    return Object.entries(groupedOptions).map(([group, groupOptions]) => [
      <ListSubheader key={group} color='primary'>
        {group}
      </ListSubheader>,
      ...groupOptions.map((option) => (
        <MenuItem key={getOptionValue(option)} value={getOptionValue(option)}>
          <Stack direction='row' alignItems='center' gap={1}>
            <Typography variant='subtitle2'>
              {getOptionLabel(option)}
            </Typography>
          </Stack>
        </MenuItem>
      )),
    ]);
  }, [options, getOptionValue, getOptionLabel, groupBy]);

  return useMemo(
    () => (
      <Stack
        direction='column'
        sx={sx}
        width={widthStyle === 'full-width' ? '100%' : undefined}
      >
        <FormControl fullWidth>
          <InputLabel>{label}</InputLabel>
          <Select
            disabled={disabled}
            value={value ?? ''}
            label={label}
            variant={variant}
            onChange={handleInputChange}
            IconComponent={(props) => (
              <IconChevronDown {...props} style={{ marginTop: -4 }} />
            )}
            error={error}
          >
            {renderOptions}
          </Select>
        </FormControl>

        {error && (
          <FormHelperText
            error
            sx={{
              ml: 1,
            }}
          >
            {helperText}
          </FormHelperText>
        )}
      </Stack>
    ),
    [value, fieldNameStr, error, touched, helperText, options, renderOptions],
  );
}
