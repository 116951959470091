// @ts-ignore -- vite imports the css as a string, but tsc doesn't understand it
// eslint-disable-next-line @nx/enforce-module-boundaries
import globalStylesheet from '../../../../libs/ui/src/styles/globals.css?inline';
// @ts-ignore -- vite imports the css as a string, but tsc doesn't understand it
import workStylesheet from '../index.css?inline';

const extractClassesFromHtml = (div: HTMLDivElement): string[] => {
  const classSet = new Set<string>();
  const selectors = Array.from(div.querySelectorAll('*'));

  for (const element of selectors) {
    if (!element.className || typeof element.className !== 'string') {
      continue;
    }

    const classes = element.className.split(/\s+/);
    classes.forEach((className) => {
      if (className.trim()) {
        classSet.add(className);
      }
    });
  }

  return Array.from(classSet);
};

const extractStylesForClasses = (classList: string[]): string => {
  let styles = '';

  Array.from(document.styleSheets).forEach((styleSheet) => {
    try {
      // Some stylesheets might not be accessible due to CORS restrictions
      const cssRules = styleSheet.cssRules || styleSheet.rules;

      Array.from(cssRules).forEach((rule) => {
        if (rule instanceof CSSStyleRule && rule.selectorText) {
          classList.forEach((className) => {
            // Match the class selector within the rule
            if (rule.selectorText.includes(`.${CSS.escape(className)}`)) {
              styles += `${rule.cssText}\n`;
            }
          });
        }
      });
    } catch (e) {
      console.warn('Could not access stylesheet:', styleSheet.href, e);
    }
  });

  return styles;
};

const injectStylesIntoHtml = (htmlContent: string, styles: string) => {
  const printStyles = `
    ${workStylesheet}

    ${globalStylesheet}
    
    @media print {
      body {
        color-adjust: exact;
        print-color-adjust: exact;
        -webkit-print-color-adjust: exact;
        background-color: white !important;
      }

      table {
        width: 100%;
      }

      th,
      td {
        word-break: break-word;
      }

      tr {
        page-break-inside: avoid;
      }
    }
  `;

  return `
    <!DOCTYPE html>
    <html lang="en">
        <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <title>Document</title>
        <style>${printStyles}</style>
        <style>${styles}</style>
        </head>
        <body>
        ${htmlContent}
        </body> 
    </html>
  `;
};

export const generateHtmlWithStyles = (div: HTMLDivElement) => {
  const classList = extractClassesFromHtml(div);
  const styles = extractStylesForClasses(classList);

  return injectStylesIntoHtml(div.innerHTML, styles);
};
