/**
 * Nifty hack to allow tailwindcss to still parse the classes at build time,
 * and we get to use a simple number instead
 */
export const commonSizes = new Map<number, string>([
  [1, 'size-1'],
  [2, 'size-2'],
  [3, 'size-3'],
  [4, 'size-4'],
  [5, 'size-5'],
  [6, 'size-6'],
  [7, 'size-7'],
  [8, 'size-8'],
  [9, 'size-9'],
  [10, 'size-10'],
  [11, 'size-11'],
  [12, 'size-12'],
  [18, 'size-18'],
  [24, 'size-24'],
  [32, 'size-32'],
  [48, 'size-48'],
]);
