import React, { useCallback } from 'react';

import { RouterLink } from '@lupa/ui/components/RouterLink';
import { useAuth } from '@lupa/ui/hooks/use-auth';
import { Tables } from '@lupa/utils/types/database.types';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Popover, { PopoverProps } from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import { IconSettings } from '@tabler/icons-react';
import { useQueryClient } from '@tanstack/react-query';

import toast from 'react-hot-toast';

import { paths } from '../paths';

interface AccountPopoverProps extends PopoverProps {
  employee: Pick<Tables<'employees'>, 'full_name' | 'email' | 'avatar_url'>;
  anchorEl: Nullish<HTMLButtonElement>;
  onClose: () => void;
  open: boolean;
}

export const AccountPopover = ({
  employee,
  anchorEl,
  onClose,
  open,
  ...other
}: AccountPopoverProps) => {
  const auth = useAuth();
  const queryClient = useQueryClient();

  const handleLogout = useCallback(async () => {
    try {
      queryClient.clear();
      onClose?.();
      await auth.signOut();
    } catch (err) {
      console.error(err);
      toast.error('Something went wrong!');
    }
  }, [auth, onClose]);

  return (
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{
        horizontal: 'center',
        vertical: 'bottom',
      }}
      disableScrollLock
      onClose={onClose}
      open={!!open}
      PaperProps={{ sx: { width: 200 } }}
      {...other}
    >
      <Box p={2}>
        <Typography variant='body1' noWrap>
          {employee.full_name}
        </Typography>
        <Typography color='text.secondary' variant='body2' noWrap>
          {employee.email}
        </Typography>
      </Box>
      <Divider />
      <Box sx={{ p: 1 }}>
        <ListItemButton
          component={RouterLink}
          href={paths.settings.index}
          onClick={onClose}
          sx={{
            borderRadius: 1,
            px: 1,
            py: 0.5,
          }}
        >
          <ListItemIcon>
            <IconSettings size={20} />
          </ListItemIcon>
          <ListItemText
            primary={<Typography variant='body1'>Settings</Typography>}
          />
        </ListItemButton>
      </Box>
      <Divider sx={{ my: '0 !important' }} />
      <Box
        sx={{
          display: 'flex',
          p: 1,
          justifyContent: 'center',
        }}
      >
        <Button color='error' onClick={handleLogout} size='small'>
          Logout
        </Button>
      </Box>
    </Popover>
  );
};
