import React from 'react';

import FallbackAvatar from '@lupa/ui/components/FallbackAvatar';
import Loading from '@lupa/ui/components/Loading';
import { usePopover } from '@lupa/ui/hooks/use-popover';
import { trpc } from '@lupa/work/lib/trpc';
import { paths } from '@lupa/work/paths';

import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { IconBuilding, IconChevronDown } from '@tabler/icons-react';

import { useAtom } from 'jotai';

import { lastLoadedStoreIdAtom } from '../atoms/atoms';
import { globalSingleton } from '../singletons/globalSingleton';
import { getImagePublicUrl } from '../utils/get-image-public-url';

export const TenantSwitch = () => {
  const { currentStore, currentCompany } = globalSingleton;
  const [, setLastLoadedStoreId] = useAtom(lastLoadedStoreIdAtom);
  const popover = usePopover();
  const isEnterpriseView = window.location.pathname.includes(paths.admin.index);

  const { data, isPending } = trpc.store.getRoot.useQuery(undefined, {
    enabled: globalSingleton.accessToken != null,
  });

  const onChangeStore = (storeId: string) => {
    setLastLoadedStoreId(storeId);
    window.location.href = `${window.location.origin}${paths.index}`;
  };

  const onAdminSwitch = () => {
    window.location.href = `${window.location.origin}${paths.admin.index}`;
  };

  if (
    isPending ||
    currentStore == null ||
    currentCompany == null ||
    data == null
  ) {
    return (
      <Loading
        style={{ height: 64, flexGrow: 0, paddingTop: 16, paddingBottom: 16 }}
      />
    );
  }

  return (
    <Stack
      alignItems='center'
      justifyContent='center'
      direction='row'
      spacing={1}
      px={{
        xs: 3,
        sm: 2,
      }}
      pt={{
        xs: 3,
        sm: 2,
      }}
      pb={{
        xs: 3,
        sm: 1.5,
      }}
    >
      <FallbackAvatar
        src={
          isEnterpriseView ? null : getImagePublicUrl(currentStore?.logo_url)
        }
        fallbackIcon={<IconBuilding />}
        size={36}
      />

      <Box sx={{ flexGrow: 1 }}>
        {isEnterpriseView ? (
          <Typography color='inherit' variant='h6' style={{ fontSize: 13 }}>
            {`${currentCompany?.name}`}
          </Typography>
        ) : (
          <Typography color='inherit' variant='h6' style={{ fontSize: 13 }}>
            {`${currentStore?.name} ${currentStore?.is_draft ? 'DRAFT' : ''}`}
          </Typography>
        )}
      </Box>

      {data.stores?.length > 1 && (
        <IconButton
          ref={popover.anchorRef}
          onClick={popover.handleOpen}
          style={{ margin: 0, padding: 0 }}
        >
          <IconChevronDown />
        </IconButton>
      )}

      <Menu
        anchorEl={popover.anchorRef.current}
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'bottom',
        }}
        onClose={popover.handleClose}
        open={popover.open}
        transformOrigin={{
          horizontal: 'right',
          vertical: 'top',
        }}
      >
        {data.stores
          .filter(
            ({ store }) => isEnterpriseView || store.id !== currentStore.id,
          )
          .map((option) => (
            <MenuItem
              key={option.store.id}
              onClick={() => onChangeStore(option.store.id)}
            >
              <Stack direction='row' alignItems='center' gap={1}>
                <FallbackAvatar
                  src={getImagePublicUrl(option.store.logo_url)}
                  fallbackIcon={<IconBuilding size={16} />}
                  name={option.store.name}
                  size={28}
                />

                <Typography variant='body2'>{option.store.name}</Typography>
              </Stack>
            </MenuItem>
          ))}

        {globalSingleton.isTestUser && (
          <MenuItem onClick={onAdminSwitch}>
            <Stack direction='row' alignItems='center' gap={1}>
              <FallbackAvatar
                src={null}
                fallbackIcon={<IconBuilding size={16} />}
                name={globalSingleton.currentStore.company.name}
                size={28}
              />

              <Typography variant='body2'>
                {globalSingleton.currentStore.company.name}
              </Typography>
            </Stack>
          </MenuItem>
        )}
      </Menu>
    </Stack>
  );
};
