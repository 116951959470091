import React, { useMemo } from 'react';

import { TextFieldProps } from '@mui/material';
import { DatePickerProps } from '@mui/x-date-pickers';

import { FormikProps, FormikValues } from 'formik';
import { Paths } from 'type-fest';

import useFormikUtils from '../../hooks/useFormikUtils';
import LupaDatePicker from '../LupaDatePicker';

interface FormDateFieldProps<Values> {
  name: Paths<Values>;
  label?: string;
  formik: FormikProps<Values>;
  maxDate?: Date;
  minDate?: Date;
  disabled?: boolean;
  sx?: DatePickerProps<never>['sx'];
  textFieldProps?: TextFieldProps;
  dateFormat?: 'isoDateTime' | 'isoDate';
}

export default function FormDateField<Values extends FormikValues>({
  name,
  label,
  formik,
  maxDate,
  minDate,
  disabled,
  sx,
  textFieldProps,
  dateFormat = 'isoDateTime',
}: FormDateFieldProps<Values>) {
  const fieldNameStr = name.toString();
  const { value, error, helperText } = useFormikUtils(formik, fieldNameStr);

  return useMemo(
    () => (
      <LupaDatePicker
        name={fieldNameStr}
        label={label}
        value={value ? new Date(value) : null}
        onChange={(value) => {
          formik.setFieldTouched(fieldNameStr);
          formik.setFieldValue(
            fieldNameStr,
            value == null
              ? null
              : dateFormat === 'isoDateTime'
                ? value.toISOString()
                : value.toISOString().split('T')[0],
          );
        }}
        maxDate={maxDate}
        minDate={minDate}
        slotProps={{
          textField: {
            name: fieldNameStr,
            error: !!error,
            helperText,
            ...textFieldProps,
          },
        }}
        disabled={disabled}
        sx={sx}
      />
    ),
    [fieldNameStr, value, error, helperText, textFieldProps],
  );
}
