import { type ReactNode, useState } from 'react';

import { Button } from '@lupa/ui/components/shadcn/button';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from '@lupa/ui/components/shadcn/command';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@lupa/ui/components/shadcn/popover';
import { cn } from '@lupa/ui/lib/utils';

import {
  PopoverContentProps,
  PopoverProps,
  PopoverTriggerProps,
} from '@radix-ui/react-popover';

import { Check, ChevronsUpDown } from 'lucide-react';

interface MultiselectProps<T> {
  placeholder?: string;
  emptyMessage?: string;
  allOptions: T[];
  selectedOptions: T[];
  setSelectedOptions: (options: T[]) => void;
  renderItem?: (option: T) => ReactNode;
  trigger?: ({
    open,
    setOpen,
  }: {
    open: boolean;
    setOpen: (value: boolean) => void;
  }) => ReactNode;
  popoverProps?: PopoverProps;
  popoverTriggerProps?: PopoverTriggerProps;
  popoverContentProps?: PopoverContentProps;
}

export interface MultiselectBaseItemType {
  id: string | number;
  value: string;
  label: string;
}

export default function Multiselect<T extends MultiselectBaseItemType>(
  props: MultiselectProps<T>,
) {
  const {
    placeholder = 'Type to search',
    emptyMessage = 'No results found.',
    allOptions,
    selectedOptions,
    setSelectedOptions,
    renderItem,
    trigger,
    popoverProps,
    popoverTriggerProps,
    popoverContentProps,
  } = props;
  const [open, setOpen] = useState(false);

  return (
    <Popover open={open} onOpenChange={setOpen} {...popoverProps}>
      <PopoverTrigger asChild {...popoverTriggerProps}>
        {trigger ? (
          trigger({ open, setOpen })
        ) : (
          <Button
            variant='outline'
            role='combobox'
            aria-expanded={open}
            className='w-50 justify-between'
          >
            <span>{selectedOptions.length} Selected</span>
            <ChevronsUpDown className='ml-2 h-4 w-4 shrink-0 opacity-50' />
          </Button>
        )}
      </PopoverTrigger>
      <PopoverContent className='w-full p-0' {...popoverContentProps}>
        <Command>
          <CommandInput placeholder={placeholder} />
          <CommandList>
            <CommandEmpty>{emptyMessage}</CommandEmpty>
            <CommandGroup>
              <CommandItem
                className={'mb-1 flex w-full justify-center p-4'}
                onSelect={() => {
                  setSelectedOptions(allOptions);
                }}
              >
                Select All
              </CommandItem>
              {allOptions.map((option) => {
                return (
                  <CommandItem
                    key={option.id}
                    value={option.value}
                    onSelect={() => {
                      const hasOption = selectedOptions.find(
                        (o) => o.id === option.id,
                      );
                      if (hasOption) {
                        setSelectedOptions(
                          selectedOptions.filter((o) => o.id !== option.id),
                        );
                      } else {
                        setSelectedOptions([option].concat(selectedOptions));
                      }
                    }}
                  >
                    {renderItem ? (
                      renderItem(option)
                    ) : (
                      <>
                        {option.label}
                        <Check
                          className={cn(
                            'mr-2 h-4 w-4',
                            selectedOptions.find((o) => o.id === option.id)
                              ? 'opacity-100'
                              : 'opacity-0',
                          )}
                        />
                      </>
                    )}
                  </CommandItem>
                );
              })}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
}
