import React from 'react';

import { UpsertAction } from '@lupa/utils/types/common.types';
import { isCreateAppointmentFormik } from '@lupa/work/utils/appointment-utils';

import { Box, Button, Stack, Typography } from '@mui/material';
import { IconNotesOff } from '@tabler/icons-react';

import { FormikProps } from 'formik';

import { AppointmentCreateForm } from './AppointmentCreate';
import { AppointmentUpdateForm } from './AppointmentUpdate';
import AppointmentUpsertDetailsCard from './AppointmentUpsertDetailsCard';

const getIsNextDisabled = (
  formik:
    | FormikProps<AppointmentUpdateForm>
    | FormikProps<AppointmentCreateForm>,
) => {
  if (isCreateAppointmentFormik(formik)) {
    return (
      (formik.values.client == null && formik.values.newClient == null) ||
      (formik.values.pet == null &&
        (formik.values.newPet == null || formik.errors?.newPet != null)) ||
      formik.values.employee == null ||
      !formik.values.appointment.title ||
      !formik.values.appointment?.visit_type?.id
    );
  }

  return (
    formik.values.client == null ||
    formik.values.pet == null ||
    formik.values.employee == null ||
    !formik.values.appointment.title ||
    !formik.values.appointment?.visit_type?.id
  );
};

type AppointmentUpsertDetailsSectionProps = {
  formik:
    | FormikProps<AppointmentUpdateForm>
    | FormikProps<AppointmentCreateForm>;
  action: UpsertAction;
  handleClose?: () => void;
  setActiveStep: (step: number) => void;
  showCreateClient: boolean;
  setShowCreateClient: (show: boolean) => void;
  setAutofilledAppointmentId?: (id: string) => void;
};

export default function AppointmentUpsertDetailsSection({
  action,
  formik,
  handleClose,
  setActiveStep,
  showCreateClient,
  setShowCreateClient,
  setAutofilledAppointmentId,
}: AppointmentUpsertDetailsSectionProps) {
  const isNextDisabled = getIsNextDisabled(formik);

  return (
    <Stack direction='column' spacing={2}>
      {action === 'update' && (
        <Box
          sx={{
            color: 'white',
            width: '100%',
            alignItems: 'flex-start',
            p: 2,
            border: '1px solid rgba(255, 255, 255, 0.3)',
            borderRadius: 3,
          }}
        >
          <Typography variant='h6' mb={2}>
            Client Booking Notes
          </Typography>
          {formik.values.appointment.client_notes ? (
            <Typography variant='body2'>
              {formik.values.appointment.client_notes}
            </Typography>
          ) : (
            <Stack
              direction='row'
              gap={1}
              alignItems='center'
              color='rgba(255, 255, 255, 0.8)'
            >
              <IconNotesOff stroke={1.5} />
              <Typography variant='body2'>No Notes</Typography>
            </Stack>
          )}
        </Box>
      )}

      <AppointmentUpsertDetailsCard
        handleClose={handleClose}
        action={action}
        formik={formik}
        showCreateClient={showCreateClient}
        setShowCreateClient={setShowCreateClient}
        setAutofilledAppointmentId={setAutofilledAppointmentId}
      />

      <Stack
        direction='row'
        gap={2}
        alignItems='center'
        justifyContent='flex-end'
      >
        <Button
          variant='contained'
          onClick={() => setActiveStep(1)}
          disabled={isNextDisabled}
          style={
            isNextDisabled
              ? {
                  backgroundColor: 'rgb(255,255,255,0.15)',
                  color: '#ccc',
                }
              : undefined
          }
        >
          Next
        </Button>
      </Stack>
    </Stack>
  );
}
