import React, { useMemo } from 'react';

import { cn } from '@lupa/ui/lib/utils';

import { IconMinus, IconPlus } from '@tabler/icons-react';

import { Button, Group, Input, NumberField } from 'react-aria-components';

import { FormItem } from '../shadcn/form';
import FormHelperText from './FormHelperText';
import FormLabel from './FormLabel';
import InputBaseProps from './InputBaseProps';

interface NumberInputProps
  extends InputBaseProps<number | undefined | null, number | null> {
  minValue?: number;
  maxValue?: number;
  showArrows?: boolean;
  inlineStartAddOn?: React.ReactNode;
  inlineEndAddOn?: React.ReactNode;
  formatOptions?: Intl.NumberFormatOptions;
  isInteger?: boolean;
  className?: string;
}

export default function NumberInput({
  error,
  required,
  label,
  helperText,
  value,
  onChange,
  afterValueChange,
  disabled,
  minValue,
  maxValue,
  showArrows = true,
  inlineStartAddOn,
  inlineEndAddOn,
  formatOptions,
  isInteger = false,
  hint,
  className,
  ...props
}: NumberInputProps) {
  const combinedFormatOptions = useMemo<
    Intl.NumberFormatOptions | undefined
  >(() => {
    if (isInteger && formatOptions) {
      return { maximumFractionDigits: 0, ...formatOptions };
    }

    if (isInteger) {
      return { maximumFractionDigits: 0 };
    }

    if (formatOptions) {
      return formatOptions;
    }

    return undefined;
  }, [isInteger, formatOptions]);

  return (
    <NumberField
      value={value ?? Number.NaN}
      onChange={(newValue) => {
        const newValueCast = Number.isNaN(newValue) ? null : newValue;
        onChange(newValueCast);
        afterValueChange?.(newValueCast);
      }}
      isDisabled={disabled}
      minValue={minValue}
      maxValue={maxValue}
      formatOptions={combinedFormatOptions}
    >
      <FormItem className={cn('space-y-1', className)}>
        <FormLabel label={label} required={required} hint={hint} />

        <Group
          className={cn(
            'border-input data-[focus-within]:border-ring data-[focus-within]:ring-ring/20 relative inline-flex h-9 w-full items-center overflow-hidden whitespace-nowrap rounded-lg border text-sm shadow-sm shadow-black/5 transition-shadow data-[disabled]:opacity-50 data-[focus-within]:outline-none data-[focus-within]:ring-[3px]',
            !!error &&
              'border-destructive/80 focus-visible:border-destructive/80 focus-visible:ring-destructive/20',
          )}
        >
          {showArrows && (
            <Button
              slot='decrement'
              className='border-input bg-background text-muted-foreground/80 hover:bg-accent hover:text-foreground -ms-px flex aspect-square h-[inherit] items-center justify-center rounded-s-lg border text-sm transition-shadow disabled:pointer-events-none disabled:cursor-not-allowed disabled:opacity-50'
            >
              <IconMinus size={16} strokeWidth={2} aria-hidden='true' />
            </Button>
          )}

          <Input
            className={cn(
              'bg-background text-foreground w-full grow px-3 py-2 text-center tabular-nums focus:outline-none',
              inlineStartAddOn && 'peer ps-8',
              inlineEndAddOn && 'peer pe-8',
            )}
            {...props}
          />

          {inlineStartAddOn && (
            <span className='text-muted-foreground pointer-events-none absolute inset-y-0 start-0 flex items-center justify-center ps-3 text-sm peer-disabled:opacity-50'>
              {inlineStartAddOn}
            </span>
          )}

          {inlineEndAddOn && (
            <span className='text-muted-foreground pointer-events-none absolute inset-y-0 end-0 flex items-center justify-center pe-3 text-sm peer-disabled:opacity-50'>
              {inlineEndAddOn}
            </span>
          )}

          {showArrows && (
            <Button
              slot='increment'
              className='border-input bg-background text-muted-foreground/80 hover:bg-accent hover:text-foreground -me-px flex aspect-square h-[inherit] items-center justify-center rounded-e-lg border text-sm transition-shadow disabled:pointer-events-none disabled:cursor-not-allowed disabled:opacity-50'
            >
              <IconPlus size={16} strokeWidth={2} aria-hidden='true' />
            </Button>
          )}
        </Group>
      </FormItem>

      <FormHelperText error={error} helperText={helperText} />
    </NumberField>
  );
}
