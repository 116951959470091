export function throwIfNullish<T>(
  value: T | null | undefined,
  message?: string,
): T {
  if (value == null) {
    throw new Error(message ?? 'Value was unexpectedly null or undefined');
  }
  return value;
}

export function assertNonNullish<T>(
  value: T,
  message?: string,
): asserts value is NonNullable<T> {
  if (value == null) {
    throw new Error(message ?? 'Value was unexpectedly null or undefined');
  }
}

export function notEmpty<TValue>(
  value: TValue | null | undefined,
): value is TValue {
  return value !== null && value !== undefined;
}

export function assertUnreachable(
  unhandledValue: never,
  throwException = false,
) {
  const errorStr = `Encountered unhandled value: ${unhandledValue}`;
  if (throwException) {
    throw new Error(errorStr);
  } else {
    console.error(errorStr);
  }
}
