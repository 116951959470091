import { globalSingleton } from '@lupa/work/singletons/globalSingleton';

import Intercom from '@intercom/messenger-js-sdk';

const DANIEL_TURNER_UID = '3a9dfa6a-90b8-4dc1-a02a-bd929ec4b178';

export default function IntercomComponent() {
  /**
   * Lots of prospective customers trial Lupa with the Daniel Turner account.
   *
   * We don't want them to see each other's messages, so we report them as
   * anonymous rather than Daniel Turner.
   */
  const shouldReportUserAsAnonymous =
    globalSingleton.userId === DANIEL_TURNER_UID;

  Intercom({
    app_id: 'g3qb8kec',
    custom_launcher_selector: '#intercom-help-button',
    hide_default_launcher: true,

    ...(!shouldReportUserAsAnonymous && {
      user_id: globalSingleton.userId ?? undefined,
      name: globalSingleton.currentEmployee.full_name,
      email: globalSingleton.currentEmployee.email,
      phone: globalSingleton.currentEmployee.phone ?? undefined,
      user_hash: globalSingleton.currentEmployee.intercomHash ?? undefined,
      company: {
        company_id: globalSingleton.currentStore.company.id,
        name: globalSingleton.currentStore.company.name,
      },
    }),
  });

  return null;
}
