import React from 'react';

import { flushSync } from 'react-dom';
import { createRoot } from 'react-dom/client';

import { createTheme } from '@lupa/ui/theme';
import { TrpcRouterInputs, trcpProxyClient } from '@lupa/work/lib/trpc';
import { globalSingleton } from '@lupa/work/singletons/globalSingleton';
import { generateHtmlWithStyles } from '@lupa/work/utils/html-utils';

import { ThemeProvider } from '@mui/material';

import axios from 'axios';

export type GeneratePdfOptions =
  TrpcRouterInputs['store']['generatePdfUrl']['pdfOptions'];

const customTheme = createTheme({
  colorPreset: 'purple',
  contrast: 'high',
  paletteMode: 'light',
  responsiveFontSizes: true,
});

export const generatePdfBlob = async (
  component: React.ReactNode,
  pdfOptions?: GeneratePdfOptions,
): Promise<Blob> => {
  const div = document.createElement('div');
  const root = createRoot(div);
  flushSync(() => {
    root.render(<ThemeProvider theme={customTheme}>{component}</ThemeProvider>);
  });
  const fullHtml = generateHtmlWithStyles(div);

  // const blob = new Blob([fullHtml], { type: 'text/html' });
  // const htmlUrl = URL.createObjectURL(blob);
  // window.open(htmlUrl, '_blank');

  const randomUuid = crypto.randomUUID();

  const url = await trcpProxyClient.store.getPresignedUrl.query({
    // userId as part of the key for security
    key: `${globalSingleton.userId}-${randomUuid}.html`,
    fileType: 'html',
  });

  // Upload the html to the presigned url in S3
  await axios.put(url, fullHtml, {
    headers: {
      'Content-Type': 'text/html',
    },
  });

  // Get the URL for the generated PDF
  const pdfUrl = await trcpProxyClient.store.generatePdfUrl.query({
    pdfId: randomUuid,
    pdfOptions,
  });

  const response = await axios.get(pdfUrl, { responseType: 'blob' });

  return response.data;
};
