import React from 'react';

import { Divider, Stack, Typography } from '@mui/material';

export default function DividerSection({
  title,
  textAlign = 'left',
  children,
}: {
  title: React.ReactNode;
  textAlign?: 'left' | 'center' | 'right';
  children?: React.ReactNode;
}) {
  const divider = (
    <Divider textAlign={textAlign}>
      <Typography
        variant='subtitle2'
        color='text.secondary'
        data-sentry-no-mask
      >
        {title}
      </Typography>
    </Divider>
  );

  if (children == null) {
    return divider;
  }

  return (
    <Stack direction='column' spacing={1.5}>
      {divider}
      {children}
    </Stack>
  );
}
