import { EMPLOYEES_WORKING_HOURS_STATUS } from '@lupa/utils/enums';
import { TrpcRouterOutputs } from '@lupa/work/lib/trpc';

import { blue, deepPurple, orange } from '@mui/material/colors';

import {
  addDays,
  differenceInMinutes,
  format,
  isAfter,
  isBefore,
  parse,
} from 'date-fns';
import { toZonedTime } from 'date-fns-tz';
import { ByWeekday, RRule } from 'rrule';

type TimeInterval = {
  open: string;
  close: string;
};

type DayWorkingHours = {
  start: string | null;
  breakTime: string | null;
  end: string | null;
  breakDuration: number | null;
};

export type EmployeeRota = Pick<
  TrpcRouterOutputs['employees']['getEmployeesRotas'][0],
  'id' | 'working_hours' | 'avatar_url' | 'full_name' | 'role'
>;

export type EmployeeRotaItem =
  TrpcRouterOutputs['employees']['getEmployeesRotas'][0]['working_hours'][0];

type OpeningTime = {
  day: string;
  times: TimeInterval[];
};

export const displayName = (fullName: string): string => {
  if (!fullName) {
    return '';
  }
  const [firstName, lastName] = fullName.split(' ');
  return `${firstName} ${lastName?.[0]}.`;
};

export const getWorkingHours = (
  timeIntervals: TimeInterval[],
): DayWorkingHours => {
  if (!timeIntervals || timeIntervals.length === 0) {
    return { start: null, breakTime: null, end: null, breakDuration: null };
  }

  const start = timeIntervals[0].open;
  const end = timeIntervals[timeIntervals.length - 1].close;

  let breakTime = null;
  let breakDuration = null;
  if (timeIntervals.length > 1) {
    breakTime = timeIntervals[0].close;
    const breakStart = parse(breakTime, 'HH:mm', new Date());
    const breakEnd = parse(timeIntervals[1].open, 'HH:mm', new Date());
    breakDuration = differenceInMinutes(breakEnd, breakStart);
  }

  return { start, breakTime, end, breakDuration };
};

export const getStoreWorkingHours = (
  storeOpeningTimes: {
    day: string;
    times: {
      open: string;
      close: string;
    }[];
  }[],
  dayName: string,
): string | DayWorkingHours => {
  const dayData = storeOpeningTimes.find((day) => day.day === dayName);
  if (!dayData || dayData.times.length === 0) {
    return 'closed';
  }

  return getWorkingHours(dayData.times);
};

export const getEmployeeExceptionOnDay = (
  employee: EmployeeRota,
  day: Date,
): Nullable<EmployeeRotaItem> => {
  if (!employee.working_hours) {
    return null;
  }

  const formattedDay = format(day, 'dd-MM-yyyy');
  const sortedWorkingHours = employee.working_hours.sort((a, b) => {
    return new Date(b.created_at).getTime() - new Date(a.created_at).getTime();
  });

  for (const wh of sortedWorkingHours) {
    if (wh.rrule != null) {
      const rule = new RRule({
        freq: wh.rrule.freq,
        interval: wh.rrule.interval,
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        byweekday: wh.rrule.byweekday as ByWeekday[],
        dtstart: toZonedTime(new Date(wh.rrule.dtstart), 'UTC'),
        until: toZonedTime(new Date(wh.rrule.until), 'UTC'),
      });

      const dates = rule.all();

      if (dates.some((date) => format(date, 'dd-MM-yyyy') === formattedDay)) {
        return wh;
      }
    } else if (
      wh.date &&
      format(new Date(wh.date), 'dd-MM-yyyy') === formattedDay
    ) {
      return wh;
    }
  }

  return null;
};

const parseTime = (timeStr: string): Date =>
  parse(timeStr, 'HH:mm', new Date());

export const getEmployeeNonWorkingIntervals = (
  storeOpeningTimes: TimeInterval[],
  employeeWorkingHours: TimeInterval[],
): TimeInterval[] => {
  const intervals = [
    ...storeOpeningTimes.map(({ open, close }) => ({
      start: parseTime(open),
      end: parseTime(close),
      type: 'open',
    })),
    ...employeeWorkingHours.map(({ open, close }) => ({
      start: parseTime(open),
      end: parseTime(close),
      type: 'working',
    })),
  ];

  intervals.sort((a, b) => a.start.getTime() - b.start.getTime());

  const nonWorkingIntervals: TimeInterval[] = [];
  let currentOpen: { start: Date; end: Date; type: string } | null = null;

  for (const interval of intervals) {
    if (interval.type === 'open') {
      if (currentOpen) {
        if (isBefore(interval.end, currentOpen.end)) {
          continue;
        }
        currentOpen.end = interval.end;
      } else {
        currentOpen = { ...interval };
      }
    } else {
      if (currentOpen && isBefore(currentOpen.start, interval.start)) {
        nonWorkingIntervals.push({
          open: format(currentOpen.start, 'HH:mm'),
          close: format(interval.start, 'HH:mm'),
        });
      }
      if (currentOpen && isBefore(currentOpen.start, interval.end)) {
        currentOpen.start = interval.end;
      }
    }
  }

  if (currentOpen && isAfter(currentOpen.end, currentOpen.start)) {
    nonWorkingIntervals.push({
      open: format(currentOpen.start, 'HH:mm'),
      close: format(currentOpen.end, 'HH:mm'),
    });
  }

  return nonWorkingIntervals;
};

export const getStoreEndOfWeekDate = (
  startOfWeek: Date,
  storeOpeningTimes: OpeningTime[],
): Date => {
  let lastOpenDayIndex = -1;

  storeOpeningTimes.forEach((day, index) => {
    if (day.times.length > 0) {
      lastOpenDayIndex = index;
    }
  });

  if (lastOpenDayIndex === -1) {
    return new Date();
  }

  return addDays(startOfWeek, lastOpenDayIndex);
};

export const dayNameToNumber = (dayName: string): number => {
  const dayNames = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ];
  return dayNames.indexOf(dayName);
};

export const getExceptionTypeColour = (
  type: EMPLOYEES_WORKING_HOURS_STATUS,
): string => {
  switch (type) {
    case EMPLOYEES_WORKING_HOURS_STATUS.AVAILABLE:
      return deepPurple[50];
    case EMPLOYEES_WORKING_HOURS_STATUS.VACATION:
      return blue[50];
    case EMPLOYEES_WORKING_HOURS_STATUS.SICK:
      return orange[50];
    case EMPLOYEES_WORKING_HOURS_STATUS.UNAVAILABLE:
      return 'white';
    default:
      return '#f2f2f2';
  }
};
