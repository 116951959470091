import { Path, PathValue, UseFormReturn } from 'react-hook-form';
import { isDeepEqual } from 'remeda';

export function getNestedValue<T>(obj: T, path: string): any {
  const keys = path.replace(/\[(\w+)\]/g, '.$1').split('.');
  return keys.reduce(
    (acc: any, key) => (acc && acc[key] !== undefined ? acc[key] : undefined),
    obj,
  );
}

type Formik = {
  errors: unknown;
  touched: unknown;
};

export const getFieldError = (
  formik: Formik,
  fieldName: string,
  showErrorBeforeTouched = false,
): boolean => {
  const error = getNestedValue(formik.errors, fieldName);
  const touched = getNestedValue(formik.touched, fieldName);
  return Boolean((touched || showErrorBeforeTouched) && error ? error : '');
};

export const getFieldHelperText = (
  formik: Formik,
  fieldName: string,
  showErrorBeforeTouched = false,
): string => {
  const error = getNestedValue(formik.errors, fieldName);
  const touched = getNestedValue(formik.touched, fieldName);
  return (touched || showErrorBeforeTouched) && error ? error : '';
};

export const getChangedValues = <T extends Record<string, unknown>>(
  values: T,
  initialValues: T,
) => {
  return Object.entries(values).reduce<Partial<T>>((acc, [key, value]) => {
    if (!isDeepEqual(value, initialValues[key])) {
      // @ts-ignore
      acc[key] = value;
    }
    return acc;
  }, {});
};

export const getFormChangedValues = <
  TFirst extends Record<string, unknown>,
  TSecond extends Record<string, unknown>,
>({
  data,
  initialValues,
}: {
  data: TFirst;
  initialValues: TSecond;
}): Partial<TFirst> => {
  return Object.entries(data).reduce<Partial<TFirst>>((acc, [key, value]) => {
    if (
      initialValues[key] === undefined ||
      !isDeepEqual(value, initialValues[key])
    ) {
      return { ...acc, [key]: value };
    }
    return acc;
  }, {});
};

export const clearErrorAndSetValue = <
  TFieldValues extends Record<string, any>,
  TFieldPath extends Path<TFieldValues>,
>(
  form: UseFormReturn<TFieldValues>,
  path: TFieldPath,
  value: PathValue<TFieldValues, TFieldPath>,
) => {
  form.clearErrors(path);
  form.setValue(path, value);
};
