import React from 'react';

import {
  Avatar,
  AvatarFallback,
  AvatarImage,
} from '@lupa/ui/components/shadcn/avatar';
import { commonSizes } from '@lupa/ui/utils/tailwindUtils';
import { getInitials, stringToColor } from '@lupa/utils/stringUtils';

type Props<T> = {
  items: T[];
  avatarSize?: number;
  maxLength?: number;
  randomizeRingColor?: boolean;
};
const ImageStack = <
  T extends { id: string | number; image_url: string; name?: string },
>({
  items,
  avatarSize = 10,
  maxLength = 5,
  randomizeRingColor = false,
}: Props<T>) => {
  return (
    <div className='flex w-full items-center space-x-2'>
      <div className='flex flex-shrink-0 -space-x-1'>
        {items.slice(0, maxLength).map((item) => (
          <Avatar
            key={item.id}
            className={`${commonSizes.get(avatarSize)} max-w-none overflow-hidden rounded-full text-xs ring-2 ring-white transition-all duration-150 hover:-mt-1`}
            ringColor={
              randomizeRingColor ? stringToColor(item.name ?? '') : 'white'
            }
          >
            <AvatarImage src={item.image_url} alt={item.name ?? ''} />
            <AvatarFallback backgroundColor={stringToColor(item.name ?? '')}>
              {getInitials(item.name)}
            </AvatarFallback>
          </Avatar>
        ))}
      </div>
      <span
        className={`${
          items.length <= maxLength ? 'invisible' : ''
        } flex-shrink-0 text-xs font-medium leading-5`}
      >
        +{items.length - maxLength}
      </span>
    </div>
  );
};

export default ImageStack;
