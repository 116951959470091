import React from 'react';

import { PrescriptionUpsert } from '@lupa/utils/validators/prescriptionValidators';
import { globalSingleton } from '@lupa/work/singletons/globalSingleton';
import { getImagePublicUrl } from '@lupa/work/utils/get-image-public-url';
import {
  formatNumberAsWords,
  formatQuantityAndUnit,
} from '@lupa/work/utils/units';
import { formatPrescriptionDosage } from '@lupa/work/validators/prescriptionValidators';

import { format } from 'date-fns';

interface DetailBoxProps {
  title: string;
  children: React.ReactNode;
}

const DetailBox: React.FC<DetailBoxProps> = ({ title, children }) => (
  <div className='rounded border-[0.75px] border-b-2 border-gray-800'>
    <div className='border-b-[0.75px] border-gray-800 py-1 text-center font-bold'>
      {title}
    </div>
    <div className='p-4 pt-2'>{children}</div>
  </div>
);

interface KVRowsProps {
  kv: { key: string; value: string }[];
}

const KVRows: React.FC<KVRowsProps> = ({ kv }) => (
  <div className='grid grid-cols-[auto_1fr] gap-x-1'>
    {kv.map(({ key, value }, index) => (
      // eslint-disable-next-line react/no-array-index-key
      <React.Fragment key={index}>
        <div className='text-right text-gray-600'>{key}:</div>
        <div className='overflow-hidden text-ellipsis'>{value}</div>
      </React.Fragment>
    ))}
  </div>
);

// Add Checkbox component
const Checkbox: React.FC = () => (
  <div className='h-4 w-4 rounded-sm border border-gray-800' />
);

interface WrittenPrescriptionPdfProps {
  prescription: PrescriptionUpsert;
  pet: {
    name: string;
    species: string;
    breed?: string;
  };
  ownerOrKeeper: {
    name: string;
    address?: string | null;
    phone?: string | null;
    email?: string | null;
  };
  prescriber: {
    name: string;
    rcvs_number: string | null;
  };
}

export default function WrittenPrescriptionPdf({
  prescription,
  pet,
  ownerOrKeeper,
  prescriber,
}: WrittenPrescriptionPdfProps) {
  const { currentStore } = globalSingleton;

  if (prescription.status === 'cancelled') {
    console.error(
      'Attempted to render a prescription letter for a cancelled prescription. Id: ',
      prescription.id,
    );
    return null;
  }

  return (
    <div className='mx-auto max-w-4xl p-8 font-sans text-sm'>
      {/* Header */}
      <div className='flex items-stretch justify-between border-b border-black pb-2'>
        <div>
          <h1 className='text-xl font-bold'>{currentStore?.name}</h1>
          <p>{currentStore?.address?.formatted_address}</p>
          <p>{currentStore?.email}</p>
          <p>{currentStore?.phone}</p>
        </div>
        {currentStore?.logo_url && (
          <img
            src={getImagePublicUrl(currentStore.logo_url)}
            alt='Store logo'
            className='h-10 w-10 rounded-full'
          />
        )}
      </div>

      <h2 className='my-6 text-center text-2xl font-bold'>
        Veterinary Prescription
      </h2>

      {/* Details Grid */}
      <div className='mb-4 grid grid-cols-2 gap-4'>
        <DetailBox title='Animal'>
          <KVRows
            kv={[
              { key: 'Name', value: pet.name },
              { key: 'Species', value: pet.species },
              ...(pet.breed ? [{ key: 'Breed', value: pet.breed }] : []),
            ]}
          />
        </DetailBox>

        <DetailBox title='Owner/Keeper'>
          <KVRows
            kv={[
              { key: 'Name', value: ownerOrKeeper.name },
              ...(ownerOrKeeper.address
                ? [{ key: 'Address', value: ownerOrKeeper.address }]
                : []),
              ...(ownerOrKeeper.phone
                ? [{ key: 'Phone', value: ownerOrKeeper.phone }]
                : []),
              ...(ownerOrKeeper.email
                ? [{ key: 'Email', value: ownerOrKeeper.email }]
                : []),
            ]}
          />
        </DetailBox>
      </div>

      {/* Prescription Details */}
      <DetailBox title='Prescription Details'>
        <div className='space-y-4'>
          <div className='space-y-4'>
            <div>
              <p>Product</p>
              <p className='font-bold'>{prescription.product_name}</p>
            </div>
            <div>
              <p>Quantity</p>
              <p className='font-bold'>
                {formatQuantityAndUnit({
                  quantity: prescription.quantity,
                  unit: prescription.unit,
                })}
              </p>
            </div>
            <div>
              <p>Permitted Repeats</p>
              <p className='font-bold'>
                {prescription.refill_limit > 0
                  ? `${formatNumberAsWords(prescription.refill_limit, {
                      includeNumericToo: true,
                    })} only`
                  : 'None'}
                {prescription.refills_permitted_until != null
                  ? `. Permitted until ${format(
                      new Date(prescription.refills_permitted_until),
                      'dd/MM/yyyy',
                    )}`
                  : ''}
              </p>
            </div>
            <div>
              <p>Instructions</p>
              <p className='font-bold'>
                {formatPrescriptionDosage({ prescription })}
              </p>
            </div>
          </div>

          {/* Warning Box */}
          <div className='rounded border-[0.75px] border-gray-400 p-4'>
            <p>For animal treatment only. Keep out of reach of children.</p>
          </div>

          {/* Checkbox Section */}
          <div className='space-y-3 rounded border-[0.75px] border-gray-400 p-4'>
            <p>Tick as appropriate:</p>
            <div className='flex items-center gap-2'>
              <Checkbox />
              <p>
                This prescription relates to a product prescribed under the
                cascade.
              </p>
            </div>
            <div className='flex items-center gap-2'>
              <Checkbox />
              <p>
                This prescription relates to an antibiotic prescribed for
                prophylactic or metaphylactic purposes.
              </p>
            </div>
          </div>

          {/* Signature Section */}
          <div className='-mx-4 mt-8 border-t-[0.75px] border-black'>
            <div className='grid grid-cols-2 gap-4 px-4 pt-4'>
              <div>
                <p>Prescriber: {prescriber.name}</p>
                {prescriber.rcvs_number && (
                  <p>RCVS number {prescriber.rcvs_number}</p>
                )}
                <p className='mt-2 text-xs text-gray-600'>
                  {currentStore?.name},{' '}
                  {currentStore?.address?.formatted_address}
                  {currentStore?.phone && `, ${currentStore.phone}`}
                </p>
                <p className='mt-2'>
                  This prescription is for an animal under my care.
                </p>
              </div>
              <div>
                <p className='font-bold'>Prescriber's Signature</p>
                <div className='mb-2 mt-2 h-10 border-b-[0.75px] border-black' />
                <p>Issued on: </p>
              </div>
            </div>
          </div>
        </div>
      </DetailBox>

      {/* Footer */}
      <p className='mt-6 text-center italic'>
        It is an offence under the Veterinary Medicines Regulations 2013 for a
        person to alter a written prescription unless authorised to do so by the
        person who signed it.
      </p>
      <p className='mt-2 text-center text-gray-600'>
        Powered by <span className='font-bold'>Lupa</span>
      </p>
    </div>
  );
}
