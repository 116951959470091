import React, { ReactNode } from 'react';

import {
  Box,
  Dialog,
  DialogProps,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { IconX } from '@tabler/icons-react';

interface DialogSectionProps {
  open: boolean;
  disableEscapeKeyDown?: boolean;
  disableBackdropClick?: boolean;
  onClose: () => void;
  maxWidth?: DialogProps['maxWidth'];
  fullScreen?: DialogProps['fullScreen'];
  fullWidth?: boolean;
  title: string;
  children: ReactNode;
  verticallyAligned?: boolean;
}

const DialogSection: React.FC<DialogSectionProps> = ({
  open,
  onClose,
  disableEscapeKeyDown = false,
  disableBackdropClick = false,
  maxWidth = 'md',
  fullScreen = false,
  fullWidth,
  title,
  verticallyAligned,
  children,
}) => {
  const handleClose: DialogProps['onClose'] = (event, reason) => {
    if (
      (disableBackdropClick && reason === 'backdropClick') ||
      (disableEscapeKeyDown && reason === 'escapeKeyDown')
    ) {
      return;
    }
    onClose();
  };

  return (
    <Dialog
      disableEscapeKeyDown={disableEscapeKeyDown}
      open={open}
      onClose={handleClose}
      maxWidth={maxWidth}
      fullWidth={fullWidth}
      fullScreen={fullScreen}
      sx={{
        '& .MuiDialog-container': {
          alignItems: verticallyAligned ? 'center' : 'flex-start',
        },
      }}
    >
      <Stack direction='column' p={2}>
        <Stack
          direction='row'
          alignItems='center'
          justifyContent='space-between'
          mb={1}
        >
          <Stack direction='column'>
            <Typography variant='h5'>{title}</Typography>
            <Box
              sx={{
                borderTopWidth: 4,
                borderTopStyle: 'solid',
                borderTopColor: 'rgba(124, 58, 237, 0.5)',
                width: 80,
                borderRadius: 8,
              }}
            />
          </Stack>

          <IconButton onClick={onClose}>
            <IconX />
          </IconButton>
        </Stack>
        {children}
      </Stack>
    </Dialog>
  );
};

export default DialogSection;
