import { useMemo } from 'react';

import { FormikProps } from 'formik';

import {
  getFieldError,
  getFieldHelperText,
  getNestedValue,
} from '../utils/formikUtils';

export default function useFormikUtils(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formik: FormikProps<any>,
  path: string,
): {
  value: any;
  touched: boolean;
  error: boolean;
  helperText: string;
} {
  return useMemo(() => {
    const value = getNestedValue(formik.values, path);
    const touched = !!getNestedValue(formik.touched, path);
    const error = getFieldError(formik, path, touched);
    const helperText = error ? getFieldHelperText(formik, path) : '';

    return {
      value,
      touched,
      error,
      helperText,
    };
  }, [formik, path]);
}
