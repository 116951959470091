import React, { useCallback, useMemo, useState } from 'react';

import {
  Autocomplete,
  AutocompleteChangeReason,
  SxProps,
  TextField,
  TextFieldProps,
  Typography,
  TypographyOwnProps,
} from '@mui/material';
import { IconChevronDown } from '@tabler/icons-react';

import { FormikProps, FormikValues } from 'formik';
import { Paths } from 'type-fest';

import useFormikUtils from '../hooks/useFormikUtils';

export function highlightAutocompleteMatch(
  text: string,
  match: string,
  typographyVariant: TypographyOwnProps['variant'],
) {
  const parts = text.split(match);
  return (
    <span>
      {parts.map((part, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <React.Fragment key={index}>
          {index > 0 && (
            <Typography
              variant={typographyVariant}
              component='span'
              fontWeight='bold'
              color='primary'
            >
              {match}
            </Typography>
          )}
          {part}
        </React.Fragment>
      ))}
    </span>
  );
}

interface FormLocalAutocompleteFieldProps<Values, Option, FreeSolo = boolean> {
  name: Paths<Values>;
  label: string;
  formik: FormikProps<Values>;
  options: Array<Option>;
  getOptionLabel: (
    o: FreeSolo extends true ? Option | string : Option,
  ) => string;
  getOptionKey: (o: FreeSolo extends true ? Option | string : Option) => string;
  textFieldProps?: TextFieldProps;
  freeSolo?: FreeSolo;
  sx?: SxProps;
}

export default function FormLocalAutocompleteField<
  Values extends FormikValues,
  Option,
  FreeSolo,
>({
  name,
  label,
  formik,
  options,
  getOptionLabel,
  getOptionKey,
  textFieldProps,
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  freeSolo = false as FreeSolo,
  sx,
}: FormLocalAutocompleteFieldProps<Values, Option, FreeSolo>) {
  const [changedSinceSelection, setChangedSinceSelection] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const fieldNameStr = name.toString();
  const { value, touched, error, helperText } = useFormikUtils(
    formik,
    fieldNameStr,
  );

  const handleInputChange = (
    event: React.SyntheticEvent<Element, Event>,
    newInputValue: string,
  ) => {
    setChangedSinceSelection(true);
    setInputValue(newInputValue);
  };

  const handleChange = useCallback(
    (
      event: React.SyntheticEvent<Element, Event>,
      value: Option | string | null,
      reason: AutocompleteChangeReason,
    ) => {
      setChangedSinceSelection(false);
      if (reason === 'blur') {
        return;
      }

      formik.setFieldValue(fieldNameStr, value);
    },
    [],
  );

  const handleBlur = useCallback(async () => {
    if (changedSinceSelection) {
      await formik.setFieldValue(fieldNameStr, inputValue);
    }
    await formik.setFieldTouched(fieldNameStr);
  }, [changedSinceSelection, formik, fieldNameStr, inputValue]);

  return useMemo(
    () => (
      <Autocomplete
        value={value}
        onChange={handleChange}
        onInputChange={handleInputChange}
        onBlur={handleBlur}
        options={options}
        getOptionLabel={getOptionLabel}
        freeSolo={!!freeSolo}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            error={error}
            helperText={helperText}
            onBlur={formik?.handleBlur}
            fullWidth
            variant='outlined'
            InputProps={{
              ...params.InputProps,
              style: {
                fontSize: 14,
              },
            }}
            {...textFieldProps}
          />
        )}
        renderOption={(props, option) => (
          <Typography
            variant='subtitle2'
            component='li'
            {...props}
            key={getOptionKey(option)}
            style={{
              paddingLeft: 2,
              paddingRight: 2,
              paddingTop: 4,
              paddingBottom: 4,
            }}
          >
            {highlightAutocompleteMatch(
              getOptionLabel(option),
              inputValue,
              'subtitle2',
            )}
          </Typography>
        )}
        popupIcon={<IconChevronDown />}
        sx={sx}
      />
    ),
    [value, fieldNameStr, error, touched, helperText, inputValue, options],
  );
}
