import mathUtils from './math-utils';

const productUtils = {
  calculatePrice: (
    values: {
      procurement_cost: number | null;
      discount_1: number | null;
      discount_2: number | null;
      discount_3: number | null;
      vat_percentage: number;
    },
    margin: number | null,
  ): number => {
    const cost = values.procurement_cost ?? 0;
    const discountsValue = mathUtils.getDiscountsValue(cost, [
      values.discount_1,
      values.discount_2,
      values.discount_3,
    ]);

    const marginFactor = mathUtils.getIncreaseFactor(margin);
    const vatFactor = mathUtils.getIncreaseFactor(values.vat_percentage);

    const price = (cost - discountsValue) * marginFactor * vatFactor;

    return mathUtils.roundCurrency(price);
  },

  calculateMargin: (
    values: {
      procurement_cost: number | null;
      discount_1: number | null;
      discount_2: number | null;
      discount_3: number | null;
      vat_percentage: number;
    },
    newPrice: number,
  ): number => {
    const cost = values.procurement_cost ?? 0;
    const discountsValue = mathUtils.getDiscountsValue(cost, [
      values.discount_1,
      values.discount_2,
      values.discount_3,
    ]);
    const vatFactor = mathUtils.getIncreaseFactor(values.vat_percentage);
    const formattedPrice = mathUtils.roundCurrency(newPrice);

    const marginFactor = formattedPrice / vatFactor / (cost - discountsValue);

    const margin = (marginFactor - 1) * 100;

    return mathUtils.roundCurrency(margin);
  },
};

export default productUtils;
