import { z } from 'zod';

import {
  APPOINTMENT_TYPE,
  ONLINE_BOOKING_EMPLOYEE_STATUS,
  STORE_CATEGORY,
  STORE_ONLINE_PAYMENT_METHOD,
} from '../enums';
import { zLupaAddress } from './utils';

export const updateStoreDetailsValidatorData = z.object({
  name: z
    .string()
    .max(255, 'Store name must be shorter than 256 characters')
    .min(1, 'Store name is required')
    .optional(),
  description: z
    .string()
    .max(5000, 'Description must be shorter than 5000 characters')
    .nullish(),
  email: z.string().email('Invalid email address').optional(),
  phone: z.string().optional(),
  address: zLupaAddress().nullish(),
  website: z.string().url('Invalid website').nullish().or(z.literal('')),
  facebook: z.string().url('Invalid Facebook link').nullish().or(z.literal('')),
  instagram: z
    .string()
    .url('Invalid Instagram link')
    .nullish()
    .or(z.literal('')),
  categories: z
    .array(z.nativeEnum(STORE_CATEGORY))
    .min(1, 'At least one category must be selected')
    .optional(),
  color: z.string().nullish(),
  locale: z.string().optional(),
  currency_code: z.string().optional(),
  vat_percentage: z.number().optional(),
});

export type UpdateStoreDetailsValidatorDataType = z.infer<
  typeof updateStoreDetailsValidatorData
>;

export const updateStorePaymentDetailsValidatorData = z.object({
  payment_method: z.nativeEnum(STORE_ONLINE_PAYMENT_METHOD),
  amount: z.number(),
  payment_enabled: z.boolean(),
});

export type UpdateStorePaymentDetailsValidatorDataType = z.infer<
  typeof updateStorePaymentDetailsValidatorData
>;

export const upsertStoreServiceValidatorData = z
  .object({
    id: z.string().uuid(),
    category: z.nativeEnum(APPOINTMENT_TYPE, {
      message: 'Category is required',
    }),
    name: z
      .string({
        message: 'Name is required',
      })
      .min(1, 'Name is required'),
    price: z
      .number({
        message: 'Invalid price',
      })
      .min(0, 'Invalid price'),
    venom_code: z.number().nullish(),
    is_external: z.boolean().nullish(),
    margin: z.number().nullish(),
    external_reference: z.string().nullish(),
  })
  .superRefine((data, ctx) => {
    if (data.is_external && !data.external_reference) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ['external_reference'],
        message: 'External reference is required',
      });
    }
  });

export type UpsertStoreServiceValidatorDataType = z.infer<
  typeof upsertStoreServiceValidatorData
>;

export const updateBookingFormSettingsData = z.object({
  online_booking_enabled: z.boolean(),
  online_booking_location_enabled: z.boolean(),
  online_booking_employee_status: z.nativeEnum(ONLINE_BOOKING_EMPLOYEE_STATUS),
  online_booking_date_enabled: z.boolean(),
  referrals_enabled: z.boolean(),
  should_show_store_address_in_emails: z.boolean(),
  default_employee: z
    .object({
      id: z.string().uuid(),
    })
    .optional(),
});

export type UpdateBookingFormSettingsDataType = z.infer<
  typeof updateBookingFormSettingsData
>;

export const updatePrintingSettingsData = z.object({
  dosageLabels: z.object({
    width: z.number().min(1, 'Width must be at least 1mm'),
    height: z.number().min(1, 'Height must be at least 1mm'),
    margins: z.object({
      top: z.number().min(0, 'Top margin must be non-negative'),
      right: z.number().min(0, 'Right margin must be non-negative'),
      bottom: z.number().min(0, 'Bottom margin must be non-negative'),
      left: z.number().min(0, 'Left margin must be non-negative'),
    }),
  }),
});
