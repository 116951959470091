import React from 'react';

import BasicDetails from '@lupa/ui/components/BasicDetails';
import CardSection from '@lupa/ui/components/CardSection';
import { Tables } from '@lupa/utils/types/database.types';
import HealthPlanSubscriptionsHistory from '@lupa/work/components/healthplans/HealthPlanSubscriptionsHistory';
import { TrpcRouterOutputs } from '@lupa/work/lib/trpc';
import { globalSingleton } from '@lupa/work/singletons/globalSingleton';
import { formatAddress } from '@lupa/work/utils/address-utils';

import { Card, CardContent, Divider, Stack, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { blueGrey } from '@mui/material/colors';

import { format } from 'date-fns';

import RichTextEditor from '../editors/RichTextEditor';

type ClientDetailsSectionProps = {
  client: Pick<
    Tables<'clients'>,
    | 'email'
    | 'phone'
    | 'notes'
    | 'created_at'
    | 'secondary_parent_name'
    | 'secondary_parent_last_name'
    | 'secondary_parent_email'
    | 'secondary_parent_phone'
    | 'address'
  >;
  healthPlanSubscriptions:
    | TrpcRouterOutputs['healthPlans']['getClientSubscriptions']
    | undefined;
};

export default function ClientDetailsSection({
  client,
  healthPlanSubscriptions,
}: ClientDetailsSectionProps) {
  return (
    <CardSection title='Client Details'>
      <Grid container spacing={2}>
        <Grid
          size={{
            xs: 12,
            md: 7,
          }}
        >
          <BasicDetails
            elevation={0}
            email={client.email}
            phone={client.phone}
            address={formatAddress(client.address)}
            birthday={null}
            clientSince={format(new Date(client.created_at), 'MMMM d, yyyy')}
          />
        </Grid>

        <Grid
          size={{
            xs: 12,
            md: 5,
          }}
        >
          <Stack direction='column'>
            <Card elevation={5} sx={{ height: '100%' }}>
              <CardContent
                sx={{
                  pt: 0,
                  '&:last-child': { pb: 2 },
                }}
              >
                <Stack pt={1.5} pb={1}>
                  <Stack
                    direction='row'
                    alignItems='center'
                    justifyContent='space-between'
                  >
                    <Typography variant='h6' color='#1C2536'>
                      Internal Client Notes
                    </Typography>
                  </Stack>

                  <Divider sx={{ borderColor: blueGrey[50] }} />
                </Stack>

                <RichTextEditor
                  value={client.notes}
                  placeholder='No notes'
                  readOnly
                />
              </CardContent>
            </Card>

            <BasicDetails
              elevation={0}
              secondaryParentName={client.secondary_parent_name}
              secondaryParentLastName={client.secondary_parent_last_name}
              secondaryParentEmail={client.secondary_parent_email}
              secondaryParentPhone={client.secondary_parent_phone}
            />
          </Stack>
        </Grid>
      </Grid>
      {globalSingleton.currentStore.features?.health_plan_enabled &&
        healthPlanSubscriptions != null &&
        healthPlanSubscriptions.length > 0 && (
          <Grid size={{ xs: 12, md: 6 }} sx={{ mt: 4 }}>
            <HealthPlanSubscriptionsHistory
              subscriptions={healthPlanSubscriptions}
            />
          </Grid>
        )}
    </CardSection>
  );
}
