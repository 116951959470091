import React from 'react';

import { STORE_TEMPLATE_TYPE } from '@lupa/utils/enums';

import { $generateNodesFromDOM } from '@lexical/html';
import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { Stack, SxProps, Theme } from '@mui/material';

import { $getRoot, $insertNodes, TextNode } from 'lexical';

import LexicalEditor from './LexicalEditor';
import { ExtendedTextNode } from './nodes/ExtendedTextNode';
import PlaygroundNodes from './nodes/PlaygroundNodes';
import { TableContext } from './plugins/TablePlugin';
import PlaygroundEditorTheme from './theme/PlaygroundEditorTheme';

interface RichTextEditorProps {
  value: Nullish<string>;
  onChange?: (value: string) => void;
  isComplexToolbar?: boolean;
  readOnly?: boolean;
  templateType?: STORE_TEMPLATE_TYPE;
  placeholder?: string;
  sx?: SxProps<Theme>;
}

export default function RichTextEditor({
  value,
  onChange,
  isComplexToolbar,
  readOnly = false,
  templateType,
  placeholder,
  sx,
}: RichTextEditorProps) {
  return (
    <Stack
      direction='column'
      flexGrow={1}
      sx={{
        overflowY: 'auto',
        scrollbarWidth: 'none',
        '&::-webkit-scrollbar': {
          display: 'none',
        },
        ...sx,
      }}
    >
      <LexicalComposer
        initialConfig={{
          editable: !readOnly,
          editorState: (editor) => {
            const root = $getRoot();
            if (root.getFirstChild() === null && value != null) {
              const parser = new DOMParser();
              const dom = parser.parseFromString(value, 'text/html');
              const nodes = $generateNodesFromDOM(editor, dom);
              $getRoot().select();
              $insertNodes(nodes);
            }
          },
          namespace: 'Playground',
          nodes: [
            ...PlaygroundNodes,
            ExtendedTextNode,
            {
              replace: TextNode,
              with: (node: TextNode) => new ExtendedTextNode(node.__text),
            },
          ],
          onError: (error: Error) => {
            throw error;
          },
          theme: PlaygroundEditorTheme,
        }}
      >
        <TableContext>
          <div className='editor-shell'>
            <LexicalEditor
              onChange={onChange}
              isComplexToolbar={isComplexToolbar}
              placeholder={placeholder}
              templateType={templateType}
            />
          </div>
        </TableContext>
      </LexicalComposer>
    </Stack>
  );
}
