import React, { useRef } from 'react';

import BasicDetails from '@lupa/ui/components/BasicDetails';
import CardSection from '@lupa/ui/components/CardSection';
import { HEALTH_PLAN_SUBSCRIPTION_STATUS } from '@lupa/utils/enums';
import HealthPlanSubscriptionsHistory from '@lupa/work/components/healthplans/HealthPlanSubscriptionsHistory';
import { TrpcRouterOutputs, trpc } from '@lupa/work/lib/trpc';
import { globalSingleton } from '@lupa/work/singletons/globalSingleton';
import { throwIfNullish } from '@lupa/work/utils/type-utils';

import { Stack } from '@mui/material';
import Grid from '@mui/material/Grid2';

import { format } from 'date-fns';
import { useNavigate } from 'react-router-dom';

import { paths } from '../../paths';
import { calculateAge } from '../../utils/calculate-age';
import useGetComponentWidth from '../../utils/use-get-component-width';
import PetInternalNotes from './PetInternalNotes';
import PetWeightTracking from './PetWeightTracking';

type PetDetailsSectionProps = {
  pet: TrpcRouterOutputs['appointments']['getAppointment']['pet'];
  clientId: string;
  petNotes: Nullish<string>;
  registeredAt: string;
  showPrimaryVetDetails?: boolean;
};

export default function PetDetailsSection({
  pet,
  clientId,
  petNotes,
  registeredAt,
  showPrimaryVetDetails,
}: PetDetailsSectionProps) {
  const navigate = useNavigate();
  const containerRef = useRef(null);
  const { isWidthXs } = useGetComponentWidth(containerRef);

  const {
    data: healthPlanSubscriptions,
    isPending: isPendingSubscriptions,
    isError: isErrorLoadingPetSubscriptions,
  } = trpc.healthPlans.getPetSubscriptions.useQuery(
    {
      petId: throwIfNullish(pet.id),
    },
    {
      enabled:
        globalSingleton.currentStore.features?.health_plan_enabled ?? false,
    },
  );

  return (
    <CardSection
      title='Pet Details'
      onEdit={() => navigate(paths.pets.edit(pet.id))}
    >
      <Grid
        container
        spacing={2}
        ref={containerRef}
        alignItems='stretch'
        sx={{ maxWidth: '100%' }}
      >
        <Grid size={isWidthXs ? 12 : 7}>
          <Stack direction='column' gap={1}>
            <BasicDetails
              itemsPerColumn={4}
              elevation={0}
              name={pet.name}
              species={pet.species}
              breed={pet.breed}
              sex={pet.sex}
              neutered={pet.neutered ? 'Yes' : 'No'}
              age={calculateAge(pet.dob)}
              dateOfBirth={
                pet.dob ? format(new Date(pet.dob), 'dd MMM yyyy') : 'N/A'
              }
              colour={pet.colour}
              deceased={pet.deceased ? 'Yes' : 'No'}
              microchipNumber={pet.microchip}
              passportNumber={pet.passport_number}
              registeredAt={format(new Date(registeredAt), 'dd MMM yyyy')}
            />

            <Stack direction='row' gap={1} flexGrow={1}>
              <Stack flexGrow={1}>
                <BasicDetails
                  title='Insurance'
                  elevation={0}
                  insurer={pet.insurer}
                  policyNumber={pet.policy_holder_number}
                />
              </Stack>

              {globalSingleton.currentStore.features?.health_plan_enabled &&
                healthPlanSubscriptions != null &&
                healthPlanSubscriptions.length > 0 && (
                  <Stack flexGrow={1}>
                    <BasicDetails
                      title='Subscription Plan(s)'
                      elevation={0}
                      activePlanNames={[
                        healthPlanSubscriptions
                          .filter(
                            (sub) =>
                              sub.subscription_status ===
                              HEALTH_PLAN_SUBSCRIPTION_STATUS.ACTIVE,
                          )
                          .map((sub) => sub.health_plan.name)
                          .join(', '),
                      ]}
                    />
                  </Stack>
                )}
            </Stack>

            {showPrimaryVetDetails && (
              <BasicDetails
                title='Primary Vet'
                onEdit={() => navigate(paths.pets.edit(pet.id))}
                clinicName={pet.primary_vet?.practice_name}
                name={pet.primary_vet?.name}
                email={pet.primary_vet?.email}
                phone={pet.primary_vet?.phone}
              />
            )}
          </Stack>
        </Grid>

        <Grid size={isWidthXs ? 12 : 5}>
          <Stack direction='column' gap={1} height='100%'>
            <Stack flexGrow={1}>
              <PetInternalNotes
                petNotes={petNotes}
                petId={pet.id}
                clientId={clientId}
              />
            </Stack>

            <PetWeightTracking petId={pet.id} weights={pet.weights} />
          </Stack>
        </Grid>
      </Grid>
      {globalSingleton.currentStore.features?.health_plan_enabled &&
        !isPendingSubscriptions &&
        !isErrorLoadingPetSubscriptions &&
        healthPlanSubscriptions != null &&
        healthPlanSubscriptions.length > 0 && (
          <Grid size={{ xs: 12, md: 6 }} sx={{ mt: 4 }}>
            <HealthPlanSubscriptionsHistory
              subscriptions={healthPlanSubscriptions}
            />
          </Grid>
        )}
    </CardSection>
  );
}
