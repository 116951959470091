import React, { ComponentProps, useState } from 'react';

import AutocompleteInput from '@lupa/ui/components/form/AutocompleteInput';
import { TrpcRouterOutputs, trpc } from '@lupa/work/lib/trpc';

import { keepPreviousData } from '@tanstack/react-query';
import { useDebounce } from '@uidotdev/usehooks';

type VenomCodeOption = NonNullable<
  TrpcRouterOutputs['store']['searchVenomCodes']
>[number];

interface SearchVenomCodesProps<
  TFreeSolo extends boolean = false,
  TClearable extends boolean = false,
> extends Omit<
    ComponentProps<
      typeof AutocompleteInput<VenomCodeOption, TFreeSolo, TClearable>
    >,
    'options'
  > {
  venomType: number;
}

export default function SearchVenomCodes<
  TFreeSolo extends boolean = false,
  TClearable extends boolean = false,
>({
  venomType,
  onOptionSelected,
  ...props
}: SearchVenomCodesProps<TFreeSolo, TClearable>) {
  const [open, setOpen] = useState(false);
  const [query, setQuery] = useState<string | undefined>(undefined);
  const queryDebounced = useDebounce(query, 300);

  const { data, isLoading } = trpc.store.searchVenomCodes.useQuery(
    {
      venomType,
      query: queryDebounced,
    },
    {
      enabled: open,
      placeholderData: keepPreviousData,
    },
  );

  return (
    <AutocompleteInput<VenomCodeOption, TFreeSolo, TClearable>
      {...props}
      options={data ?? []}
      loading={isLoading}
      onOpenChange={(o) => {
        setOpen(o);
      }}
      onCommandValueChange={(v) => {
        setQuery(v);
      }}
      onOptionSelected={onOptionSelected}
    />
  );
}
