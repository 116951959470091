import React, { ReactNode, useEffect, useState } from 'react';

import { useAuth } from '@lupa/ui/hooks/use-auth';
import { createTheme } from '@lupa/ui/theme';
import { LUPA_ERROR_KEY } from '@lupa/utils/enums';
import { trpc } from '@lupa/work/lib/trpc';
import { IS_DEV } from '@lupa/work/utils/networkUtils';

import { Theme } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import * as Sentry from '@sentry/browser';

import { useAtom } from 'jotai';
import { toast } from 'react-hot-toast';

import { lastLoadedStoreIdAtom } from '../atoms/atoms';
import JerryChat from '../components/JerryChat';
import { SplashScreen } from '../components/SplashScreen';
import useInitializeSocket from '../hooks/use-initialize-socket';
import VerticalLayout from '../layouts/VerticalLayout';
import {
  globalSingleton,
  initializeGlobalSingleton,
} from '../singletons/globalSingleton';

// Define the type for props
interface RootPageProps {
  children: ReactNode;
  isEnterpriseView?: boolean;
}

export default function RootPage({
  children,
  isEnterpriseView,
}: RootPageProps) {
  const [rootDataLoaded, setRootDataLoaded] = useState(false);
  const { user, isAuthenticated, signOut } = useAuth();
  const [lastLoadedStoreId, setLastLoadedStoreId] = useAtom(
    lastLoadedStoreIdAtom,
  );
  const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

  const queryEnabled = isAuthenticated && globalSingleton.accessToken != null;
  const {
    data,
    isPending,
    isError,
    error: getRootError,
  } = trpc.store.getRoot.useQuery(undefined, {
    enabled: queryEnabled,
  });

  useEffect(() => {
    if (isPending || !queryEnabled) {
      return;
    }

    if (isError) {
      if (
        getRootError.message ===
        LUPA_ERROR_KEY.AUTH_USER_HAS_NO_WORK_PROFILE_BUT_HAS_REMUS_PROFILE
      ) {
        console.info('Redirecting Remus user to notes...');
        window.location.href = `https://lupapets.com/notes?redirect-reason=${encodeURIComponent(
          LUPA_ERROR_KEY.AUTH_USER_HAS_NO_WORK_PROFILE_BUT_HAS_REMUS_PROFILE,
        )}`;
      } else {
        toast.error('Failed to load store - please log in again.');
        signOut();

        if (
          getRootError.message !== LUPA_ERROR_KEY.AUTH_USER_HAS_NO_WORK_PROFILE
        ) {
          console.error(getRootError);
        }
      }

      return;
    }

    if (data == null) {
      console.error('No data returned from store.getRoot');

      return;
    }

    const stores = data.stores;

    if (stores.length === 0) {
      toast.error('You are not linked to any stores');
      signOut();

      return;
    }

    let selectedStoreId =
      lastLoadedStoreId == null
        ? stores[stores.length - 1]?.store?.id
        : stores.find((dataStore) => dataStore.store.id === lastLoadedStoreId)
            ?.store?.id;

    if (selectedStoreId == null) {
      selectedStoreId = stores[stores.length - 1].store.id;
      setLastLoadedStoreId(selectedStoreId);
    }

    const selectedStore = stores.find(
      ({ store }) => store.id === selectedStoreId,
    );

    if (selectedStore == null) {
      console.error('No store found with id', selectedStoreId);
      return;
    }

    initializeGlobalSingleton(data, selectedStore.store, IS_DEV);
    setRootDataLoaded(true);
  }, [isError, getRootError, isPending, data, queryEnabled]);

  Sentry.setUser({
    id: user?.id,
    email: user?.email,
    username: globalSingleton.currentEmployee?.full_name,
    store_id: globalSingleton.currentStore?.id,
    employee_id: globalSingleton.currentEmployee?.id,
  });

  useInitializeSocket(isAuthenticated);

  if (
    !rootDataLoaded ||
    isPending ||
    !globalSingleton.currentStore ||
    (isAuthenticated && user?.id == null)
  ) {
    return <SplashScreen />;
  }

  const customTheme = createTheme({
    colorPreset: globalSingleton.isVetPartnerDemo ? '#edb540' : 'purple',
    contrast: 'high',
    paletteMode: 'light',
    responsiveFontSizes: true,
  });

  return (
    <>
      <ThemeProvider theme={customTheme}>
        <VerticalLayout isEnterpriseView={isEnterpriseView}>
          {children}
        </VerticalLayout>
      </ThemeProvider>
      {isAuthenticated && mdUp && <JerryChat />}
    </>
  );
}
