import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from '@lupa/ui/components/shadcn/card';

import { IconX } from '@tabler/icons-react';

import { Button } from './shadcn/button';

interface NewCardSectionProps extends React.ComponentProps<typeof Card> {
  title: string;
  children: React.ReactNode;
  className?: string;
  description?: string;
  onClose?: () => void;
}

export default function NewCardSection({
  title,
  description,
  children,
  className,
  onClose,
  ...props
}: NewCardSectionProps) {
  return (
    <Card className={className} {...props}>
      <CardHeader className='p-4'>
        <div className='flex items-center justify-between'>
          <CardTitle>{title}</CardTitle>

          <div className='flex items-center gap-2'>
            {onClose && (
              <Button variant='outline' size='icon' onClick={onClose}>
                <IconX />
              </Button>
            )}
          </div>
        </div>
        {description && <CardDescription>{description}</CardDescription>}
      </CardHeader>
      <CardContent className='grid gap-4'>{children}</CardContent>
    </Card>
  );
}
