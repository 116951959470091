import { useEffect } from 'react';

import NewCardSection from '@lupa/ui/components/NewCardSection';
import AutocompleteInput from '@lupa/ui/components/form/AutocompleteInput';
import CheckboxInput from '@lupa/ui/components/form/CheckboxInput';
import DateInput from '@lupa/ui/components/form/DateInput';
import NumberInput from '@lupa/ui/components/form/NumberInput';
import SelectInput from '@lupa/ui/components/form/SelectInput';
import TextInput from '@lupa/ui/components/form/TextInput';
import TextareaInput from '@lupa/ui/components/form/TextareaInput';
import { Button } from '@lupa/ui/components/shadcn/button';
import { Form, FormField } from '@lupa/ui/components/shadcn/form';
import {
  PRODUCT_CATEGORY_TYPE,
  PRODUCT_MEDICAL_CATEGORY_TYPE,
} from '@lupa/utils/enums';
import { MakeNonNullishFieldsNullable } from '@lupa/utils/types/utils';
import { insertProductValidatorData } from '@lupa/utils/validators/productValidator';
import CurrencyInput from '@lupa/work/components/CurrencyInput';
import { globalSingleton } from '@lupa/work/singletons/globalSingleton';
import {
  getProductCategoryLabel,
  getProductMedicalCategoryLabel,
} from '@lupa/work/utils/get-labels';
import productUtils from '@lupa/work/utils/product-utils';

import { IconTrash } from '@tabler/icons-react';

import { UseFormReturn, useFieldArray } from 'react-hook-form';
import { z } from 'zod';

import SearchSupplierProducts from '../search/SearchSupplierProducts';
import InventoryProductFormDiscountsChart from './InventoryProductFormDiscountsChart';

const createProductSchema = insertProductValidatorData.innerType();
export type UpsertProductForm = MakeNonNullishFieldsNullable<
  z.infer<typeof insertProductValidatorData>
>;

const getIsMedicalCategory = (category: PRODUCT_CATEGORY_TYPE | null) => {
  return (
    category === PRODUCT_CATEGORY_TYPE.GENERAL_MEDICATION ||
    category === PRODUCT_CATEGORY_TYPE.VACCINATION ||
    category === PRODUCT_CATEGORY_TYPE.ANTI_PARASITIC
  );
};

const getUnitsAndSubunits = (
  category: PRODUCT_CATEGORY_TYPE | null,
): { units: string[]; subunits: string[] } => {
  switch (category) {
    case PRODUCT_CATEGORY_TYPE.ANTI_PARASITIC:
      return {
        units: ['Bottle', 'Box', 'Tube', 'Packet'],
        subunits: ['Tablets', 'Capsules', 'Milliliters', 'Grams'],
      };

    case PRODUCT_CATEGORY_TYPE.CONSUMABLES:
      return {
        units: ['Pack', 'Box', 'Bag'],
        subunits: ['Grams', 'Pieces', 'Milliliters'],
      };

    case PRODUCT_CATEGORY_TYPE.GENERAL_MEDICATION:
      return {
        units: ['Bottle', 'Box', 'Vial', 'Syringe'],
        subunits: ['Tablets', 'Capsules', 'Milliliters', 'Doses'],
      };

    case PRODUCT_CATEGORY_TYPE.GROOMING_CARE:
      return {
        units: ['Bottle', 'Tube', 'Can', 'Pack'],
        subunits: ['Milliliters', 'Grams'],
      };

    case PRODUCT_CATEGORY_TYPE.NUTRITION:
      return {
        units: ['Bag', 'Can', 'Tub', 'Bottle'],
        subunits: ['Grams', 'Kilograms', 'Milliliters', 'Servings'],
      };

    case PRODUCT_CATEGORY_TYPE.PET_ACCESSORIES:
      return {
        units: ['Piece', 'Pack', 'Box'],
        subunits: ['Items', 'Centimeters', 'Inches'],
      };

    case PRODUCT_CATEGORY_TYPE.VACCINATION:
      return {
        units: ['Vial', 'Syringe', 'Box'],
        subunits: ['Doses', 'Milliliters'],
      };

    case PRODUCT_CATEGORY_TYPE.OTHER:
    default:
      return {
        units: ['Piece', 'Pack', 'Box', 'Bottle'],
        subunits: ['Items', 'Milliliters', 'Grams'],
      };
  }
};

const vatOptions = [
  { value: 'standard', label: 'Standard (20%)', number: 20 },
  { value: 'reduced', label: 'Reduced (5%)', number: 5 },
  { value: 'exempt', label: 'Exempt (0%)', number: 0 },
  { value: 'zero', label: 'Zero (0%)', number: 0 },
  { value: 'custom', label: 'Custom', number: 0 },
];

const supplierOptions = ['NVS', 'Covetrus', 'MWI'];

interface InventoryUpsertProductFormProps {
  form: UseFormReturn<UpsertProductForm>;
}

export default function InventoryUpsertProductForm({
  form,
}: InventoryUpsertProductFormProps) {
  const {
    fields: batchesFields,
    append: appendBatch,
    remove: removeBatches,
  } = useFieldArray({
    control: form.control,
    name: 'batches',
  });

  useEffect(() => {
    // This effect makes sure that the pricing stays consistent when any price related field changes
    // It is optimized with RHF to avoid needless rerenders, and it will only rerender
    // the affected fields.

    const { unsubscribe } = form.watch((value, { name, type }) => {
      if (name == null) {
        return;
      }

      if (
        type === 'change' &&
        [
          'procurement_cost',
          'discount_1',
          'discount_2',
          'discount_3',
          'vat_percentage',
          'margin',
        ].includes(name)
      ) {
        form.setValue(
          'price',
          productUtils.calculatePrice(
            {
              procurement_cost: form.getValues('procurement_cost'),
              discount_1: form.getValues('discount_1'),
              discount_2: form.getValues('discount_2'),
              discount_3: form.getValues('discount_3'),
              vat_percentage:
                form.getValues('vat_percentage') ??
                globalSingleton.currentStore.vat_percentage,
            },
            form.getValues('margin'),
          ),
        );
      } else if (type === 'change' && name === 'price') {
        form.setValue(
          'margin',
          productUtils.calculateMargin(
            {
              procurement_cost: form.getValues('procurement_cost'),
              discount_1: form.getValues('discount_1'),
              discount_2: form.getValues('discount_2'),
              discount_3: form.getValues('discount_3'),
              vat_percentage:
                form.getValues('vat_percentage') ??
                globalSingleton.currentStore.vat_percentage,
            },
            form.getValues('price') ?? 0,
          ),
        );
      }
    });

    return () => {
      unsubscribe();
    };
  }, [form.watch]);

  return (
    <Form {...form}>
      <div className='space-y-4'>
        <NewCardSection
          title='Supplier'
          description='Optional information about the supplier.'
        >
          <div className='grid auto-rows-min gap-4 md:grid-cols-2'>
            <FormField
              control={form.control}
              name='supplier'
              render={({ field: { ref: _ref, ...fieldProps } }) => {
                return (
                  <AutocompleteInput
                    {...fieldProps}
                    value={
                      fieldProps.value
                        ? {
                            id: fieldProps.value,
                            label: fieldProps.value,
                          }
                        : null
                    }
                    onChange={(v) => {
                      fieldProps.onChange(v.id);
                    }}
                    label='Supplier (Select or type)'
                    options={supplierOptions.map((option) => ({
                      id: option,
                      label: option,
                    }))}
                    freeSolo
                    required={!createProductSchema.shape.supplier.isNullable()}
                    error={form.formState.errors.supplier?.message}
                  />
                );
              }}
            />

            <FormField
              control={form.control}
              name='supplier_product'
              render={({ field: { ref: _ref, ...fieldProps } }) => {
                return (
                  <SearchSupplierProducts
                    {...fieldProps}
                    value={
                      fieldProps.value
                        ? {
                            id: fieldProps.value.id,
                            label: fieldProps.value.description,
                          }
                        : null
                    }
                    onChange={(v) => {
                      if (v == null) {
                        form.setValue('supplier_product', null, {
                          shouldDirty: true,
                        });
                        form.setValue('name', null, { shouldDirty: true });
                        form.setValue('item_code', null, { shouldDirty: true });
                        form.setValue('procurement_cost', null, {
                          shouldDirty: true,
                        });
                        form.setValue('price', null, {
                          shouldDirty: true,
                        });
                      } else {
                        fieldProps.onChange({
                          id: v.id,
                          description: v.label,
                          code: v.code,
                        });

                        form.setValue('name', v.description, {
                          shouldValidate: true,
                        });
                        form.setValue('item_code', v.code, {
                          shouldValidate: true,
                        });
                        form.setValue('procurement_cost', v.cost, {
                          shouldValidate: true,
                        });
                      }
                    }}
                    clearable
                    label='Supplier Product'
                    required={
                      !createProductSchema.shape.supplier_product.isOptional()
                    }
                    onOptionSelected={(v) => {
                      form.setValue('name', v.description);
                      form.setValue('item_code', v.code);
                      form.setValue('procurement_cost', v.cost);
                    }}
                    error={form.formState.errors.name?.message}
                  />
                );
              }}
            />
          </div>
        </NewCardSection>

        <NewCardSection title='Product Description'>
          <div className='grid auto-rows-min gap-4 md:grid-cols-2'>
            <div className='flex flex-col gap-2'>
              <FormField
                control={form.control}
                name='category'
                render={({ field: { ref: _ref, ...fieldProps } }) => {
                  return (
                    <SelectInput
                      {...fieldProps}
                      label='Category'
                      options={Object.values(PRODUCT_CATEGORY_TYPE).map(
                        (option) => ({
                          value: option,
                          label: getProductCategoryLabel(option),
                        }),
                      )}
                      afterValueChange={() => {
                        form.setValue('medical_category', null);
                      }}
                      required={
                        !createProductSchema.shape.category.isNullable()
                      }
                      error={form.formState.errors.category?.message}
                    />
                  );
                }}
              />

              {getIsMedicalCategory(form.watch('category')) && (
                <FormField
                  control={form.control}
                  name='medical_category'
                  render={({ field: { ref: _ref, ...fieldProps } }) => {
                    return (
                      <SelectInput
                        {...fieldProps}
                        label='Medical Category'
                        options={Object.values(
                          PRODUCT_MEDICAL_CATEGORY_TYPE,
                        ).map((option) => ({
                          value: option,
                          label: getProductMedicalCategoryLabel(option),
                        }))}
                        required={
                          form.watch('category') ===
                          PRODUCT_CATEGORY_TYPE.GENERAL_MEDICATION
                        }
                        error={form.formState.errors.medical_category?.message}
                      />
                    );
                  }}
                />
              )}

              <FormField
                control={form.control}
                name='name'
                render={({ field: { ref: _ref, ...fieldProps } }) => (
                  <TextInput
                    {...fieldProps}
                    label='Name'
                    required={!createProductSchema.shape.name.isNullable()}
                    error={form.formState.errors.name?.message}
                  />
                )}
              />

              <FormField
                control={form.control}
                name='item_code'
                render={({ field: { ref: _ref, ...fieldProps } }) => (
                  <TextInput
                    {...fieldProps}
                    label='Item Code'
                    required={!createProductSchema.shape.item_code.isNullable()}
                    error={form.formState.errors.item_code?.message}
                  />
                )}
              />
            </div>

            <FormField
              control={form.control}
              name='description'
              render={({ field: { ref: _ref, ...fieldProps } }) => (
                <TextareaInput
                  {...fieldProps}
                  label='Description'
                  rows={7}
                  required={!createProductSchema.shape.description.isNullable()}
                  error={form.formState.errors.description?.message}
                />
              )}
            />
          </div>
        </NewCardSection>

        <NewCardSection title='Count'>
          <div className='flex flex-col gap-4'>
            <FormField
              control={form.control}
              name='has_subunit'
              render={({ field: { ref: _ref, ...fieldProps } }) => {
                return (
                  <CheckboxInput
                    {...fieldProps}
                    afterValueChange={(checked) => {
                      if (checked) {
                        form.setValue('has_subunit', true);
                        form.setValue('subunit_multiplier', 1);
                      } else {
                        form.setValue('has_subunit', false);
                        form.setValue('subunit_multiplier', null, {
                          shouldValidate: true,
                        });
                        form.setValue('measure_unit', null);
                        form.setValue('unit', null);
                        form.setValue('subunit', null);
                      }
                    }}
                    label='Has Subunit?'
                    required={
                      !createProductSchema.shape.has_subunit.isNullable()
                    }
                    error={form.formState.errors.has_subunit?.message}
                    expandableField={
                      <div className='flex gap-2'>
                        <FormField
                          control={form.control}
                          name='unit'
                          render={({ field: { ref: _ref, ...fieldProps } }) => {
                            return (
                              <AutocompleteInput
                                {...fieldProps}
                                value={
                                  fieldProps.value
                                    ? {
                                        id: fieldProps.value,
                                        label: fieldProps.value,
                                      }
                                    : null
                                }
                                onChange={(v) => {
                                  fieldProps.onChange(v.id);
                                }}
                                className='w-[240px]'
                                label='Unit'
                                hint='Select or type a measurement unit'
                                required={
                                  !createProductSchema.shape.unit.isNullable()
                                }
                                freeSolo
                                options={getUnitsAndSubunits(
                                  form.watch('category'),
                                ).units.map((option) => ({
                                  id: option,
                                  label: option,
                                }))}
                                error={form.formState.errors.unit?.message}
                              />
                            );
                          }}
                        />

                        <FormField
                          control={form.control}
                          name='subunit_multiplier'
                          render={({ field: { ref: _ref, ...fieldProps } }) => {
                            return (
                              <NumberInput
                                {...fieldProps}
                                label='Contains'
                                required={
                                  !createProductSchema.shape.subunit_multiplier.isNullable()
                                }
                                error={
                                  form.formState.errors.subunit_multiplier
                                    ?.message
                                }
                                minValue={0}
                                className='w-[160px]'
                              />
                            );
                          }}
                        />

                        <FormField
                          control={form.control}
                          name='subunit'
                          render={({ field: { ref: _ref, ...fieldProps } }) => {
                            return (
                              <AutocompleteInput
                                {...fieldProps}
                                value={
                                  fieldProps.value
                                    ? {
                                        id: fieldProps.value,
                                        label: fieldProps.value,
                                      }
                                    : null
                                }
                                onChange={(v) => {
                                  fieldProps.onChange(v.id);
                                }}
                                className='w-[240px]'
                                label='Subunit'
                                hint='Select or type a measurement subunit'
                                required={
                                  !createProductSchema.shape.subunit.isNullable()
                                }
                                freeSolo
                                options={getUnitsAndSubunits(
                                  form.watch('category'),
                                ).subunits.map((option) => ({
                                  id: option,
                                  label: option,
                                }))}
                                error={form.formState.errors.subunit?.message}
                                helperText={
                                  form.watch('unit') && form.watch('subunit')
                                    ? `1 ${form.watch('unit')} contains ${form.watch('subunit_multiplier')} ${form.watch('subunit')}`
                                    : undefined
                                }
                              />
                            );
                          }}
                        />

                        {form.watch('unit') && form.watch('subunit') && (
                          <FormField
                            control={form.control}
                            name='measure_unit'
                            render={({
                              field: { ref: _ref, ...fieldProps },
                            }) => {
                              return (
                                <SelectInput
                                  {...fieldProps}
                                  className='w-[240px]'
                                  label='Measure Unit'
                                  hint='Select the default unit of measure for the product'
                                  options={[
                                    {
                                      value: form.watch('unit') ?? '',
                                      label: form.watch('unit') ?? '',
                                    },
                                    {
                                      value: form.watch('subunit') ?? '',
                                      label: form.watch('subunit') ?? '',
                                    },
                                  ]}
                                  required={
                                    !createProductSchema.shape.measure_unit.isNullable()
                                  }
                                  error={
                                    form.formState.errors.measure_unit?.message
                                  }
                                />
                              );
                            }}
                          />
                        )}
                      </div>
                    }
                  />
                );
              }}
            />

            <div className='flex gap-2'>
              {!form.watch('has_batches') && (
                <FormField
                  control={form.control}
                  name='quantity'
                  render={({ field: { ref: _ref, ...fieldProps } }) => {
                    return (
                      <NumberInput
                        {...fieldProps}
                        label='Stock Quantity'
                        disabled={form.watch('has_batches') ?? false}
                        required={
                          !createProductSchema.shape.quantity.isNullable()
                        }
                        error={form.formState.errors.quantity?.message}
                        className='w-[160px]'
                      />
                    );
                  }}
                />
              )}
            </div>

            <FormField
              control={form.control}
              name='has_batches'
              render={({ field: { ref: _ref, ...fieldProps } }) => {
                return (
                  <CheckboxInput
                    {...fieldProps}
                    afterValueChange={(newValue) => {
                      if (newValue) {
                        appendBatch({
                          id: crypto.randomUUID(),
                          batch_number: '',
                          expiry_date: null,
                          quantity: 1,
                        });
                      } else {
                        removeBatches();
                      }
                    }}
                    label='Has Batches?'
                    required={
                      !createProductSchema.shape.has_batches.isNullable()
                    }
                    error={form.formState.errors.has_batches?.message}
                    expandableField={
                      <div className='flex flex-col items-center gap-2'>
                        {batchesFields.map((batch, idx) => (
                          <div key={batch.id} className='flex items-end gap-2'>
                            <FormField
                              control={form.control}
                              name={`batches.${idx}.quantity` as const}
                              render={({
                                field: { ref: _ref, ...fieldProps },
                              }) => {
                                return (
                                  <NumberInput
                                    {...fieldProps}
                                    label='Quantity'
                                    required={true}
                                    error={
                                      form.formState.errors.batches?.[idx]
                                        ?.quantity?.message
                                    }
                                    className='w-[160px]'
                                  />
                                );
                              }}
                            />

                            <FormField
                              control={form.control}
                              name={`batches.${idx}.batch_number` as const}
                              render={({
                                field: { ref: _ref, ...fieldProps },
                              }) => (
                                <TextInput
                                  {...fieldProps}
                                  label='Batch Number'
                                  required={false}
                                  error={
                                    form.formState.errors.batches?.[idx]
                                      ?.batch_number?.message
                                  }
                                />
                              )}
                            />

                            <FormField
                              control={form.control}
                              name={`batches.${idx}.expiry_date` as const}
                              render={({
                                field: { ref: _ref, ...fieldProps },
                              }) => {
                                return (
                                  <DateInput
                                    {...fieldProps}
                                    className='w-[200px]'
                                    label='Expiry Date'
                                    required={false}
                                    error={
                                      form.formState.errors.batches?.[idx]
                                        ?.expiry_date?.message
                                    }
                                  />
                                );
                              }}
                            />

                            <Button
                              variant='destructiveOutline'
                              size='icon'
                              onClick={() => {
                                removeBatches(idx);
                              }}
                            >
                              <IconTrash />
                            </Button>
                          </div>
                        ))}

                        <Button
                          variant='outline'
                          color='primary'
                          onClick={() => {
                            appendBatch({
                              id: crypto.randomUUID(),
                              batch_number: '',
                              expiry_date: null,
                              quantity: 1,
                            });
                          }}
                        >
                          Add Batch
                        </Button>
                      </div>
                    }
                  />
                );
              }}
            />
          </div>
        </NewCardSection>

        <NewCardSection
          title={`Pricing ${form.watch('unit') ? `(${form.watch('unit')})` : ''}`}
        >
          <div className='flex gap-2'>
            <FormField
              control={form.control}
              name='procurement_cost'
              render={({ field: { ref: _ref, ...fieldProps } }) => {
                return (
                  <CurrencyInput
                    {...fieldProps}
                    label='Procurement Cost (ex-VAT)'
                    required={
                      !createProductSchema.shape.procurement_cost.isNullable()
                    }
                    error={form.formState.errors.procurement_cost?.message}
                    minValue={0}
                    className='w-[220px]'
                  />
                );
              }}
            />

            <FormField
              control={form.control}
              name='margin'
              render={({ field: { ref: _ref, ...fieldProps } }) => {
                return (
                  <NumberInput
                    {...fieldProps}
                    label='Mark Up'
                    showArrows={false}
                    required={!createProductSchema.shape.margin.isNullable()}
                    error={form.formState.errors.margin?.message}
                    minValue={-100}
                    className='w-[180px]'
                    inlineEndAddOn='%'
                  />
                );
              }}
            />

            <FormField
              control={form.control}
              name='vat_code'
              render={({ field: { ref: _ref, ...fieldProps } }) => {
                return (
                  <SelectInput
                    {...fieldProps}
                    className='w-[180px]'
                    label='VAT Code'
                    options={vatOptions}
                    onOptionSelected={(option) => {
                      form.setValue('vat_percentage', option.number);
                    }}
                    required={!createProductSchema.shape.vat_code.isNullable()}
                    error={form.formState.errors.vat_code?.message}
                  />
                );
              }}
            />

            {form.watch('vat_code') === 'custom' && (
              <FormField
                control={form.control}
                name='vat_percentage'
                render={({ field: { ref: _ref, ...fieldProps } }) => {
                  return (
                    <NumberInput
                      {...fieldProps}
                      label='VAT'
                      showArrows={false}
                      required={
                        !createProductSchema.shape.vat_percentage.isNullable()
                      }
                      error={form.formState.errors.vat_percentage?.message}
                      minValue={0}
                      className='w-[180px]'
                      inlineEndAddOn='%'
                    />
                  );
                }}
              />
            )}

            <FormField
              control={form.control}
              name='price'
              render={({ field: { ref: _ref, ...fieldProps } }) => {
                return (
                  <CurrencyInput
                    {...fieldProps}
                    label='Price'
                    showArrows={false}
                    required={!createProductSchema.shape.price.isNullable()}
                    error={form.formState.errors.price?.message}
                    minValue={0}
                    className='w-[180px]'
                  />
                );
              }}
            />
          </div>

          <FormField
            control={form.control}
            name='has_advanced_discounts'
            render={({ field: { ref: _ref, ...fieldProps } }) => {
              return (
                <CheckboxInput
                  {...fieldProps}
                  label='Has Advanced Discounts?'
                  required={
                    !createProductSchema.shape.has_advanced_discounts.isNullable()
                  }
                  afterValueChange={(checked) => {
                    if (!checked) {
                      form.setValue('discount_1', 0);
                      form.setValue('discount_2', 0);
                      form.setValue('discount_3', 0);
                    }
                  }}
                  error={form.formState.errors.has_advanced_discounts?.message}
                  expandableField={
                    <div className='flex flex-col gap-2'>
                      <div className='flex gap-2'>
                        <FormField
                          control={form.control}
                          name='discount_1'
                          render={({ field: { ref: _ref, ...fieldProps } }) => {
                            return (
                              <NumberInput
                                {...fieldProps}
                                label='Discount 1'
                                showArrows={false}
                                required={
                                  !createProductSchema.shape.discount_1.isNullable()
                                }
                                error={
                                  form.formState.errors.discount_1?.message
                                }
                                minValue={-100}
                                className='w-[180px]'
                                inlineEndAddOn='%'
                              />
                            );
                          }}
                        />

                        <FormField
                          control={form.control}
                          name='discount_2'
                          render={({ field: { ref: _ref, ...fieldProps } }) => {
                            return (
                              <NumberInput
                                {...fieldProps}
                                label='Discount 2'
                                showArrows={false}
                                required={
                                  !createProductSchema.shape.discount_2.isNullable()
                                }
                                error={
                                  form.formState.errors.discount_2?.message
                                }
                                minValue={-100}
                                className='w-[180px]'
                                inlineEndAddOn='%'
                              />
                            );
                          }}
                        />

                        <FormField
                          control={form.control}
                          name='discount_3'
                          render={({ field: { ref: _ref, ...fieldProps } }) => {
                            return (
                              <NumberInput
                                {...fieldProps}
                                label='Discount 3'
                                showArrows={false}
                                required={
                                  !createProductSchema.shape.discount_3.isNullable()
                                }
                                error={
                                  form.formState.errors.discount_3?.message
                                }
                                minValue={-100}
                                className='w-[180px]'
                                inlineEndAddOn='%'
                              />
                            );
                          }}
                        />
                      </div>

                      <InventoryProductFormDiscountsChart form={form} />
                    </div>
                  }
                />
              );
            }}
          />

          <FormField
            control={form.control}
            name='has_fees'
            render={({ field: { ref: _ref, ...fieldProps } }) => {
              return (
                <CheckboxInput
                  {...fieldProps}
                  label='Has Extra Fees?'
                  afterValueChange={(checked) => {
                    if (!checked) {
                      form.setValue('dispensing_fee', 0);
                    }
                  }}
                  required={!createProductSchema.shape.has_fees.isNullable()}
                  error={form.formState.errors.has_fees?.message}
                  expandableField={
                    <div className='flex flex-col gap-2'>
                      <FormField
                        control={form.control}
                        name='dispensing_fee'
                        render={({ field: { ref: _ref, ...fieldProps } }) => {
                          return (
                            <CurrencyInput
                              {...fieldProps}
                              label='Dispensing/Injection Fee (inc-VAT)'
                              showArrows={false}
                              required={
                                !createProductSchema.shape.dispensing_fee.isNullable()
                              }
                              error={
                                form.formState.errors.dispensing_fee?.message
                              }
                              minValue={0}
                              className='w-[320px]'
                            />
                          );
                        }}
                      />
                    </div>
                  }
                />
              );
            }}
          />
        </NewCardSection>

        <NewCardSection title='Stock Levels'>
          <div className='flex gap-2'>
            <FormField
              control={form.control}
              name='minimum_stock_level'
              render={({ field: { ref: _ref, ...fieldProps } }) => {
                return (
                  <NumberInput
                    {...fieldProps}
                    label='Minimum Stock Level'
                    required={
                      !createProductSchema.shape.minimum_stock_level.isNullable()
                    }
                    error={form.formState.errors.minimum_stock_level?.message}
                    minValue={0}
                    className='w-[160px]'
                  />
                );
              }}
            />

            <FormField
              control={form.control}
              name='optimal_stock_level'
              render={({ field: { ref: _ref, ...fieldProps } }) => {
                return (
                  <NumberInput
                    {...fieldProps}
                    label='Optimal Stock Level'
                    required={
                      !createProductSchema.shape.optimal_stock_level.isNullable()
                    }
                    error={form.formState.errors.optimal_stock_level?.message}
                    minValue={0}
                    className='w-[160px]'
                  />
                );
              }}
            />
          </div>
        </NewCardSection>
      </div>
    </Form>
  );
}
