import {
  Prescription,
  StructuredPrescriptionDosage,
} from '@lupa/utils/validators/prescriptionValidators';
import {
  formatNumberAsWords,
  formatQuantityAndUnit,
} from '@lupa/work/utils/units';

import { match } from 'ts-pattern';

// Rendering utils
export const formatFrequency = ({
  timesPerInterval,
  intervalNumber,
  intervalUnit,
}: StructuredPrescriptionDosage['frequency']) => {
  const timesPerIntervalFormatted = match(timesPerInterval)
    .with(1, () => '')
    .with(2, () => 'twice ')
    .otherwise(
      () => `${formatNumberAsWords(timesPerInterval).toLowerCase()} times `,
    );

  const intervalNumberFormatted =
    intervalNumber === 1
      ? ''
      : `${formatNumberAsWords(intervalNumber).toLowerCase()} `;

  const intervalUnitFormatted =
    intervalNumber === 1 ? intervalUnit.replace(/s$/, '') : intervalUnit;

  return `${timesPerIntervalFormatted}every ${intervalNumberFormatted}${intervalUnitFormatted}`;
};

export function formatDuration(
  duration: StructuredPrescriptionDosage['duration'],
) {
  if (duration.type === 'one-off') {
    return null;
  }

  if (duration.type === 'for-duration') {
    const durationUnitFormatted =
      duration.durationNumber === 1
        ? duration.durationUnit.replace(/s$/, '')
        : duration.durationUnit;
    return `for ${duration.durationNumber} ${durationUnitFormatted}`;
  }

  if (duration.type === 'until') {
    const formattedDate = new Date(duration.until).toLocaleDateString('en-GB', {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
    });
    return `until ${formattedDate}`;
  }

  if (duration.type === 'ongoing') {
    return 'ongoing';
  }
}

export const formatPrescriptionDosage = ({
  prescription: { dosage_specification, unit },
}: {
  prescription: Pick<Prescription, 'dosage_specification' | 'unit'>;
}) => {
  if (dosage_specification.type === 'freeform') {
    return dosage_specification.instructions;
  }

  const formattedAmount = formatQuantityAndUnit({
    quantity: dosage_specification.quantity,
    unit,
  });

  const formattedFrequency = formatFrequency(dosage_specification.frequency);

  const formattedDuration = formatDuration(dosage_specification.duration);

  const formattedDosage = `Give ${formattedAmount} ${formattedFrequency}${
    formattedDuration ? ` ${formattedDuration}` : ''
  }`;

  if (dosage_specification.additionalInstructions) {
    return `${formattedDosage}. ${dosage_specification.additionalInstructions}`;
  }
  return formattedDosage;
};
