import React from 'react';

import { IconButton, InputAdornment } from '@mui/material';
import { IconX } from '@tabler/icons-react';

import { MuiTelInput } from 'mui-tel-input';

type TelephoneFieldProps = {
  name: string;
  value: Nullish<string>;
  onChange: (value: Nullish<string> | null) => void;
  onBlur: (e: any) => void;
  helperText: string | false | undefined;
  error: boolean;
  label?: string;
};

export default function TelephoneField({
  name,
  value,
  onChange,
  onBlur,
  helperText,
  error,
  label = 'Phone (optional)',
}: TelephoneFieldProps) {
  return (
    <MuiTelInput
      fullWidth
      label={label}
      name={name}
      variant='outlined'
      defaultCountry='GB'
      forceCallingCode
      autoComplete='off'
      disableFormatting
      value={value ?? ''}
      onBlur={onBlur}
      onChange={(value, info) => {
        if (value === `+${info.countryCallingCode}`) {
          onChange(null);
          return;
        }

        onChange(value);
      }}
      slotProps={{
        input: {
          endAdornment: (
            <InputAdornment position='end'>
              <IconButton
                edge='end'
                onClick={() => {
                  onChange(null);
                }}
              >
                <IconX />
              </IconButton>
            </InputAdornment>
          ),
        },
      }}
      helperText={helperText}
      error={error}
    />
  );
}
