import React, { useMemo } from 'react';

import { RouterLink } from '@lupa/ui/components/RouterLink';
import { usePathname } from '@lupa/work/hooks/use-pathname';

import { Badge, ButtonBase, Stack, Tooltip } from '@mui/material';

const APPS_PATHS = [
  'analytics',
  'client-comms',
  'debts',
  'diagnostics',
  'claims',
  'inventory',
  'rota',
];

interface TopNavItemProps {
  item: {
    title: string;
    path: string;
    icon: React.JSX.Element;
    testId: string;
    badgeContent?: number;
  };
  isEnterpriseView?: boolean;
}

export default function TopNavItem({ item }: TopNavItemProps) {
  const pathname = usePathname();

  const active: boolean = useMemo(() => {
    const getFormattedPathname = (path: string) => {
      if (path === '/admin') {
        return '/';
      } else if (path.startsWith('/admin')) {
        return path.replace('/admin', '');
      }

      return path;
    };

    const formattedPathname = getFormattedPathname(pathname);
    const itemPath = getFormattedPathname(item.path);

    const pathSegments = formattedPathname.split('?')[0].split('/');
    const itemPathSegments = itemPath.split('?')[0].split('/');

    if (
      (itemPath === '/' && formattedPathname === '/') ||
      (itemPath === '/' && pathSegments[1] === 'calendar')
    ) {
      return true;
    }
    if (pathSegments[1] === 'pets' && itemPath.startsWith('/clients')) {
      return true;
    }
    if (APPS_PATHS.includes(pathSegments[1]) && itemPath.startsWith('/apps')) {
      return true;
    }
    if (
      itemPath !== '/' &&
      formattedPathname !== '/' &&
      pathSegments[1] === itemPathSegments[1]
    ) {
      return true;
    }

    return false;
  }, [pathname]);

  return (
    <Stack
      direction='column'
      justifyContent='center'
      style={{ height: 64, position: 'relative' }}
    >
      <Tooltip title={item.title} enterDelay={200}>
        <ButtonBase
          component={RouterLink}
          href={item.path}
          data-testid={item.testId}
          sx={{
            alignItems: 'center',
            borderRadius: 1,
            display: 'flex',
            justifyContent: 'flex-start',
            pl: `16px`,
            pr: '16px',
            py: '12px',
            textAlign: 'left',
            width: '100%',
            ...(active && {
              ...{
                backgroundColor: 'var(--nav-item-active-bg)',
              },
            }),
            '&:hover': {
              backgroundColor: 'var(--nav-item-hover-bg)',
            },
          }}
        >
          <Stack
            direction='column'
            spacing={0.5}
            sx={{
              color: active
                ? 'var(--nav-item-icon-active-color)'
                : 'var(--nav-section-title-color)',
              mx: 2,
              textTransform: 'uppercase',
              '& svg': {
                fontSize: '24px !important',
              },
            }}
          >
            {item.badgeContent ? (
              <Badge color='primary' badgeContent={item.badgeContent}>
                {item.icon}
              </Badge>
            ) : (
              item.icon
            )}
          </Stack>
        </ButtonBase>
      </Tooltip>

      <div
        style={{
          position: 'absolute',
          height: '4px',
          bottom: 0,
          width: '100%',
          backgroundColor: active
            ? 'var(--nav-item-icon-active-color)'
            : 'transparent',
          borderRadius: '2px',
          transition: 'background-color 0.3s ease-in-out',
          ...(!active && {
            ':hover': {
              backgroundColor: 'var(--nav-item-hover-bg)',
            },
          }),
        }}
      />
    </Stack>
  );
}
