import { PET_SPECIES } from '@lupa/utils/enums';

export const SPECIES_OPTIONS = [
  // Birds
  { group: 'Avian', species: PET_SPECIES.BUDGIE },
  { group: 'Avian', species: PET_SPECIES.CANARY },
  { group: 'Avian', species: PET_SPECIES.CHICKEN },
  { group: 'Avian', species: PET_SPECIES.PARROT },
  { group: 'Avian', species: PET_SPECIES.BIRD_OTHER },
  { group: 'Avian', species: PET_SPECIES.BIRDS_WILD },
  { group: 'Avian', species: PET_SPECIES.BIRD_GENERAL },
  { group: 'Avian', species: PET_SPECIES.AVIAN_GENERAL },

  // Common pets
  { group: 'Cat', species: PET_SPECIES.CAT },
  { group: 'Dog', species: PET_SPECIES.DOG },

  // Small Mammals
  { group: 'Small Mammals', species: PET_SPECIES.CHINCHILLA },
  { group: 'Small Mammals', species: PET_SPECIES.FERRET },
  { group: 'Small Mammals', species: PET_SPECIES.GERBIL },
  { group: 'Small Mammals', species: PET_SPECIES.GUINEA_PIG },
  { group: 'Small Mammals', species: PET_SPECIES.HAMSTER },
  { group: 'Small Mammals', species: PET_SPECIES.HEDGEHOG },
  { group: 'Small Mammals', species: PET_SPECIES.MOUSE },
  { group: 'Small Mammals', species: PET_SPECIES.RABBIT },
  { group: 'Small Mammals', species: PET_SPECIES.RAT },
  { group: 'Small Mammals', species: PET_SPECIES.SUGAR_GLIDER },
  { group: 'Small Mammals', species: PET_SPECIES.SMALL_MAMMAL_OTHER },
  { group: 'Small Mammals', species: PET_SPECIES.LAGAMORPH },
  { group: 'Small Mammals', species: PET_SPECIES.RODENTS },

  // Reptiles & Amphibians
  { group: 'Reptile & Amphibian', species: PET_SPECIES.FROG },
  { group: 'Reptile & Amphibian', species: PET_SPECIES.LIZARD },
  { group: 'Reptile & Amphibian', species: PET_SPECIES.SNAKE },
  { group: 'Reptile & Amphibian', species: PET_SPECIES.TORTOISE },
  { group: 'Reptile & Amphibian', species: PET_SPECIES.TURTLE },
  {
    group: 'Reptile & Amphibian',
    species: PET_SPECIES.REPTILE_AMPHIBIAN_OTHER,
  },
  { group: 'Reptile & Amphibian', species: PET_SPECIES.REPTILE },

  // Livestock & Farm
  { group: 'Livestock & Farm', species: PET_SPECIES.BOVINE },
  { group: 'Livestock & Farm', species: PET_SPECIES.CAPRINE },
  { group: 'Livestock & Farm', species: PET_SPECIES.HORSE },
  { group: 'Livestock & Farm', species: PET_SPECIES.OVINE },
  { group: 'Livestock & Farm', species: PET_SPECIES.PORCINE },
  { group: 'Livestock & Farm', species: PET_SPECIES.LIVESTOCK_FARM_OTHER },
  { group: 'Livestock & Farm', species: PET_SPECIES.DONKEY },

  // Fish
  { group: 'Fish', species: PET_SPECIES.FRESHWATER_FISH },
  { group: 'Fish', species: PET_SPECIES.SALTWATER_FISH },
  { group: 'Fish', species: PET_SPECIES.FISH_GENERAL },

  // Exotic and Other
  { group: 'Exotic Pets', species: PET_SPECIES.TARANTULA },
  { group: 'Exotic Pets', species: PET_SPECIES.EXOTIC },
  { group: 'Exotic Pets', species: PET_SPECIES.EXOTIC_GENERAL },
  { group: 'Wildlife', species: PET_SPECIES.WILDLIFE },
  { group: 'Wildlife', species: PET_SPECIES.WILD_ANIMAL },
  { group: 'Other', species: PET_SPECIES.OTHER },
  { group: 'Other', species: PET_SPECIES.UNKNOWN },
];
