import NumberInput from '@lupa/ui/components/form/NumberInput';
import { globalSingleton } from '@lupa/work/singletons/globalSingleton';
import { getCurrencySymbolFromCode } from '@lupa/work/utils/i18n';

type CurrencyInputProps = React.ComponentProps<typeof NumberInput> & {
  formatOptions?: Intl.NumberFormatOptions;
};

export default function CurrencyInput({
  formatOptions = {
    maximumFractionDigits: 2,
  },
  ...props
}: CurrencyInputProps) {
  return (
    <NumberInput
      {...props}
      showArrows={false}
      inlineStartAddOn={getCurrencySymbolFromCode(
        globalSingleton.currentStore.currency_code,
      )}
      formatOptions={formatOptions}
    />
  );
}
