import React, { lazy } from 'react';

import Loading from '@lupa/ui/components/Loading';
import { UpsertAction } from '@lupa/utils/types/common.types';
import { TrpcRouterOutputs, trpc } from '@lupa/work/lib/trpc';

import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { IconX } from '@tabler/icons-react';

import AppointmentUpdate from '../../appointments/upsert/AppointmentUpdate';

const AppointmentCreate = lazy(
  () => import('@lupa/work/components/appointments/upsert/AppointmentCreate'),
);

type CalendarUpsertEventDialogProps = {
  appointment?: TrpcRouterOutputs['appointments']['getAppointment'];
  appointmentId?: string;
  onClose: () => void;
  open?: boolean;
  action?: UpsertAction;
  isNotification?: boolean;
  range?: {
    start: string;
    end: string;
  };
  defaultClient?: {
    id: string;
    full_name: string;
  };
  defaultPet?: {
    id: string;
    name: string;
    owner_full_name: string;
  };
  defaultEmployee?: {
    id: string;
    full_name: string;
    role: string;
  };
};

export default function CalendarUpsertEventDialog({
  appointment,
  appointmentId,
  onClose,
  open = false,
  action = 'create',
  isNotification = false,
  range,
  defaultClient,
  defaultPet,
  defaultEmployee,
}: CalendarUpsertEventDialogProps) {
  const shouldFetchAppointment =
    appointment == null && action === 'update' && appointmentId != null;

  const { data: fetchedAppointment, isPending } =
    trpc.appointments.getAppointment.useQuery(
      {
        appointmentId: appointmentId!,
      },
      {
        enabled: shouldFetchAppointment,
      },
    );

  return (
    <Dialog fullScreen onClose={onClose} open={open}>
      <Stack flexGrow={1}>
        <Stack
          direction='row'
          alignItems='center'
          justifyContent='space-between'
          px={3}
          pt={3}
        >
          <Typography align='center' gutterBottom variant='h5'>
            {action === 'update' ? 'Update Appointment' : 'New Appointment'}
          </Typography>

          <IconButton onClick={onClose}>
            <IconX />
          </IconButton>
        </Stack>

        <Stack p={2} flexGrow={1}>
          {action === 'create' ? (
            <AppointmentCreate
              isNotification={isNotification}
              handleClose={onClose}
              range={range}
              defaultClient={defaultClient}
              defaultPet={defaultPet}
              defaultEmployee={defaultEmployee}
            />
          ) : action === 'update' && shouldFetchAppointment && isPending ? (
            <Loading />
          ) : (
            <AppointmentUpdate
              appointment={appointment ?? fetchedAppointment!}
              isNotification={isNotification}
              handleClose={onClose}
            />
          )}
        </Stack>
      </Stack>
    </Dialog>
  );
}
