import React from 'react';

import {
  Checkbox,
  Divider,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemButtonProps,
  ListItemProps,
  ListItemText,
  TypographyProps,
} from '@mui/material';
import { IconX } from '@tabler/icons-react';

import {
  ListChildComponentProps,
  FixedSizeList as VirtualizedList,
} from 'react-window';

export interface PickerListItem<TKey extends string> {
  id: TKey;
  primaryText: string;
  secondaryText?: string;
  isSelected: boolean;
}

interface PickerListProps<TKey extends string> {
  items: PickerListItem<TKey>[];
  onToggle: (itemId: TKey) => void;
  listType: 'TOGGLEABLE' | 'REMOVABLE';
  rowHeight: number;
  listHeight: number;
  primaryTypographyProps?: TypographyProps;
  secondaryTypographyProps?: TypographyProps;
  secondaryTypographyStyle?: React.CSSProperties;
}

function ListItemOrListItemButton({
  toggleable,
  ...props
}: { toggleable: boolean } & ListItemProps & ListItemButtonProps) {
  return toggleable ? <ListItemButton {...props} /> : <ListItem {...props} />;
}

const ListItemComponent = <TKey extends string>({
  index,
  style,
  data,
}: ListChildComponentProps<
  Pick<PickerListProps<TKey>, 'items' | 'onToggle' | 'listType'> & {
    primaryTypographyProps?: TypographyProps;
    secondaryTypographyProps?: TypographyProps;
    secondaryTypographyStyle?: React.CSSProperties;
  }
>) => {
  const {
    items,
    onToggle,
    listType,

    primaryTypographyProps,
    secondaryTypographyProps,
    secondaryTypographyStyle,
  } = data;
  const item = items[index];

  const action =
    listType === 'REMOVABLE' ? (
      <IconButton aria-label='delete' onClick={() => onToggle(item.id)}>
        <IconX size={20} />
      </IconButton>
    ) : (
      <Checkbox
        checked={item.isSelected}
        tabIndex={-1}
        disableRipple
        inputProps={{ 'aria-labelledby': `item-${item.id}` }}
      />
    );

  return (
    <div style={{ ...style }}>
      <ListItemOrListItemButton
        toggleable={listType === 'TOGGLEABLE'}
        disableGutters
        onClick={
          listType === 'TOGGLEABLE' ? () => onToggle(item.id) : undefined
        }
        sx={{ height: '100%' }}
      >
        {action}
        <ListItemText
          id={`item-${item.id}`}
          primary={item.primaryText}
          primaryTypographyProps={{ noWrap: true, ...primaryTypographyProps }}
          secondary={item.secondaryText && item.secondaryText}
          secondaryTypographyProps={{
            noWrap: true,
            color: 'textSecondary',
            variant: 'body2',
            ...secondaryTypographyProps,
          }}
          style={secondaryTypographyStyle}
        />
      </ListItemOrListItemButton>
      {index !== items.length - 1 && <Divider />}
    </div>
  );
};

function PickerList<TKey extends string>({
  items,
  onToggle,
  listType,
  rowHeight,
  listHeight,
  primaryTypographyProps,
  secondaryTypographyProps,
  secondaryTypographyStyle,
}: PickerListProps<TKey>) {
  return (
    <VirtualizedList
      height={listHeight}
      itemCount={items.length}
      itemSize={rowHeight}
      width='100%'
      itemData={{
        items,
        onToggle,
        listType,
        primaryTypographyProps,
        secondaryTypographyProps,
        secondaryTypographyStyle,
      }}
    >
      {ListItemComponent<TKey>}
    </VirtualizedList>
  );
}

export default PickerList;
