import { useAuth } from '@lupa/ui/hooks/use-auth';

import { keepPreviousData, useQuery } from '@tanstack/react-query';

import api from '../lib/axios';
import { globalSingleton } from '../singletons/globalSingleton';
import { API_KEYS } from './apiKeys';

export const addEmployeesToChat = async ({
  employees,
  chatId,
}: {
  employees: string[];
  chatId: string;
}) => {
  const response = await api.post('/api/work/conversation/add', {
    employees,
    chatId,
    storeId: globalSingleton.currentStore.id,
  });

  return response.data;
};

export const fetchConversations = async () => {
  const response = await api.get('/api/work/conversations', {
    params: {
      storeId: globalSingleton.currentStore.id,
    },
  });

  return response.data;
};

export async function fetchConversation({
  queryKey,
}: {
  queryKey: [string, string, number];
}) {
  const [, conversationId, size] = queryKey;

  const queryParams = new URLSearchParams({
    conversationId,
    size: size.toString(),
  });

  const response = await api.get(
    `/api/work/conversation?${queryParams.toString()}`,
  );

  return response.data;
}

export async function sendConversationMessage(
  message: string,
  conversationId: string,
  senderName: string,
) {
  const response = await api.post('/api/work/conversation/send-message', {
    message,
    conversationId,
    senderName,
  });

  return response.data;
}

export const useGetConversations = () => {
  const { isAuthenticated } = useAuth();
  return useQuery({
    queryKey: [API_KEYS.CONVERSATIONS],
    queryFn: fetchConversations,
    placeholderData: keepPreviousData,
    enabled: isAuthenticated,
  });
};

export async function getOrCreateConversation({
  customerId,
}: {
  customerId: string;
}) {
  const response = await api.post('/api/work/conversation/get-or-create', {
    storeId: globalSingleton.currentStore.id,
    customerId,
  });

  return response.data;
}

export async function updateConversationLastReadTimestamp({
  conversationId,
}: {
  conversationId: string;
}) {
  const response = await api.post(
    '/api/work/conversation/update-last-read-timestamp',
    {
      conversationId,
    },
  );

  return response.data;
}
