import React from 'react';

import { APPOINTMENT_NOTIFICATION_REASON } from '@lupa/utils/enums';

import { FormControlLabel, Stack, Switch, Typography } from '@mui/material';
import { IconBellRinging2 } from '@tabler/icons-react';

const getText = (reason: APPOINTMENT_NOTIFICATION_REASON) => {
  switch (reason) {
    case APPOINTMENT_NOTIFICATION_REASON.CONFIRMED:
      return 'The appointment is marked as confirmed.';
    case APPOINTMENT_NOTIFICATION_REASON.CANCELLED:
      return 'The appointment is marked as cancelled.';
    case APPOINTMENT_NOTIFICATION_REASON.NO_SHOW:
      return 'The appointment is marked as missed.';
    case APPOINTMENT_NOTIFICATION_REASON.TIME:
      return 'The starting time of a confirmed appointment has been changed.';
    default:
      return '';
  }
};

type AppointmentNotificationProps = {
  notifyUser: boolean;
  onNotifyUserChanged: (notifyUser: boolean) => void;
  reason?: APPOINTMENT_NOTIFICATION_REASON;
  darkMode?: boolean;
};

export default function AppointmentNotification({
  reason,
  notifyUser,
  onNotifyUserChanged,
  darkMode = true,
}: AppointmentNotificationProps) {
  return (
    <Stack direction='column' gap={1}>
      {reason && (
        <Stack
          direction='row'
          alignItems='center'
          gap={2}
          sx={{
            borderRadius: 2,
            border: '1px solid rgb(124, 58, 237, 0.8)',
            backgroundColor: darkMode
              ? 'rgb(124, 58, 237, 0.2)'
              : 'rgb(124, 58, 237, 0.8)',
            p: 2,
          }}
        >
          <IconBellRinging2 stroke={1.5} size={32} color='white' />
          <Typography
            variant='caption'
            fontWeight={500}
            align='center'
            color='white'
          >
            {getText(reason)}
          </Typography>
        </Stack>
      )}

      <Stack direction='row' spacing={1} color={darkMode ? 'white' : 'black'}>
        <FormControlLabel
          name='notify_user'
          control={
            <Switch
              name='notify_user'
              checked={notifyUser}
              onChange={(event) => {
                onNotifyUserChanged(event.target.checked);
              }}
              style={{ pointerEvents: 'auto' }}
            />
          }
          label={
            <Typography variant='subtitle2'>
              Send Email / Mobile notification to client?
            </Typography>
          }
          labelPlacement='start'
          sx={{ pointerEvents: 'none' }}
        />
      </Stack>
    </Stack>
  );
}
