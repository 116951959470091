import React, { useEffect, useState } from 'react';

import {
  InputAdornment,
  OutlinedTextFieldProps,
  Stack,
  TextField,
  TextFieldProps,
} from '@mui/material';
import { blueGrey, purple } from '@mui/material/colors';
import { styled } from '@mui/material/styles';
import { IconMinus, IconPlus } from '@tabler/icons-react';

type CustomTextFieldProps = TextFieldProps & {
  showArrows?: boolean;
  testIdPrefix?: string;
};

const CustomTextField = styled(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ({ testIdPrefix = '', showArrows, ...otherProps }: CustomTextFieldProps) => (
    <TextField
      {...otherProps}
      data-testid={`${testIdPrefix}-text-field`}
      inputProps={{
        step: 'any',
      }}
    />
  ),
)(({ size, showArrows }) => ({
  '& .MuiInputBase-root': {
    paddingRight: 8,
    paddingLeft: '4px',
    borderRadius: showArrows ? 0 : 8,
    backgroundColor: 'white',
  },
  '& .MuiInputBase-input': {
    padding: size === 'small' ? '8px 4px' : '16px 8px',
  },
  '& .MuiInputAdornment-root .MuiTypography-root': {
    fontSize: '12px',
  },
  '& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button':
    {
      WebkitAppearance: 'none',
      margin: 0,
    },
  '& input[type=number]': {
    MozAppearance: 'textfield',
    textAlign: 'center',
  },
}));

interface StyledButtonProps {
  size: 'small' | 'medium';
  position: 'left' | 'right';
  showSelect?: boolean;
}

const StyledButton = styled('button')<StyledButtonProps>(
  ({ size, position }) => ({
    fontFamily: 'IBM Plex Sans, sans-serif',
    fontSize: '0.875rem',
    boxSizing: 'border-box',
    lineHeight: 1.5,
    border: '1px solid',
    borderColor: '#e5e7eb',
    borderTopLeftRadius: position === 'left' ? 8 : 0,
    borderBottomLeftRadius: position === 'left' ? 8 : 0,
    borderTopRightRadius: position === 'right' ? 8 : 0,
    borderBottomRightRadius: position === 'right' ? 8 : 0,
    borderLeftWidth: position === 'right' ? 0 : '1px',
    borderRightWidth: position === 'left' ? 0 : '1px',
    background: 'white',
    color: blueGrey[900],
    width: '24px',
    height: size === 'small' ? '36px' : 52,
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'center',
    alignItems: 'center',
    transitionProperty: 'all',
    transitionTimingFunction: 'cubic-bezier(0.4, 0, 0.2, 1)',
    transitionDuration: '120ms',

    '&:hover': {
      cursor: 'pointer',
      background: purple[500],
      borderColor: purple[400],
      color: purple[50],
    },

    '&:focus-visible': {
      outline: 0,
    },

    '&.increment': {
      order: 1,
    },
  }),
);

interface LupaNumberInputProps
  extends Omit<OutlinedTextFieldProps, 'size' | 'onChange' | 'variant'> {
  name: string;
  value: Nullish<number>;
  onChange: (value: number) => void;
  min?: number;
  max?: number;
  step?: number;
  isInt?: boolean;
  showArrows?: boolean;
  label?: string;
  disabled?: boolean;
  size?: 'small' | 'medium';
  variant?: 'outlined' | 'filled' | 'standard';
  stackStyle?: React.CSSProperties;
  testIdPrefix?: string;
  onMinusClick?: (value: number) => void;
  onPlusClick?: (value: number) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  helperText?: string;
  error?: boolean;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
}

export default function LupaNumberInput({
  name,
  value,
  onChange,
  min = 0,
  max = 10000,
  step = 1,
  isInt = true,
  showArrows = false,
  label,
  disabled,
  size = 'medium',
  variant = 'outlined',
  stackStyle,
  testIdPrefix = '',
  onMinusClick,
  onPlusClick,
  onBlur,
  helperText,
  error,
  startIcon,
  endIcon,
}: LupaNumberInputProps) {
  const [stringInputValue, setStringInputValue] = useState(
    value?.toString() ?? '',
  );

  useEffect(() => {
    setStringInputValue(value?.toString() ?? '');
  }, [value]);

  useEffect(() => {
    onValueChange();
  }, [stringInputValue]);

  const getValidatedNumber = (numberString: string): number | undefined => {
    let currentInputValue = Number(numberString);

    if (Number.isNaN(currentInputValue)) {
      return;
    }

    currentInputValue = Math.min(Math.max(currentInputValue, min), max);
    const finalValue = isInt
      ? Math.round(currentInputValue)
      : currentInputValue;

    return finalValue;
  };

  const onValueChange = () => {
    const finalValue = getValidatedNumber(stringInputValue);

    if (finalValue != null) {
      if (value == null || Math.abs(finalValue - value) > 0.00001) {
        onChange(finalValue);
      }
    }
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const finalValue = getValidatedNumber(stringInputValue);

    if (finalValue != null) {
      setStringInputValue(finalValue.toString());
    } else {
      setStringInputValue(value?.toString() ?? '');
    }

    onBlur?.(e);
  };

  return (
    <Stack
      direction='column'
      gap={0}
      style={stackStyle}
      data-testid={`${testIdPrefix}-amount-input-div`}
    >
      <Stack direction='row' alignItems='flex-start' justifyContent='center'>
        {showArrows && (
          <StyledButton
            size={size}
            position='left'
            onClick={() => {
              if (value == null) {
                return;
              }

              if (value > min) {
                const newValue = value - step;
                onChange(newValue);
                onMinusClick?.(newValue);
              }
            }}
            disabled={disabled || (value != null && value <= min)}
            data-testid={`${testIdPrefix}-left-button`}
          >
            <IconMinus fontSize='18px' />
          </StyledButton>
        )}

        <CustomTextField
          name={name}
          value={stringInputValue}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setStringInputValue(e.target.value)
          }
          onBlur={handleBlur}
          variant={variant}
          type='number'
          disabled={disabled}
          label={label}
          placeholder={label}
          onWheel={(e) => e.currentTarget.blur()}
          size={size}
          showArrows={showArrows}
          style={{ width: '100%' }}
          autoComplete='off'
          testIdPrefix={`${testIdPrefix}-custom-input`}
          helperText={helperText}
          error={error}
          InputProps={{
            startAdornment: startIcon ? (
              <InputAdornment position='start'>{startIcon}</InputAdornment>
            ) : undefined,
            endAdornment: endIcon ? (
              <InputAdornment position='end'>{endIcon}</InputAdornment>
            ) : undefined,
            ...(value === Number.POSITIVE_INFINITY && {
              placeholder: 'Unlimited',
            }),
          }}
        />

        {showArrows && (
          <StyledButton
            size={size}
            position='right'
            onClick={() => {
              if (value == null) {
                return;
              }

              if (value < max) {
                const newValue = value + step;
                onChange(newValue);
                onPlusClick?.(newValue);
              }
            }}
            disabled={disabled || (value != null && value >= max)}
            data-testid={`${testIdPrefix}-right-button`}
          >
            <IconPlus fontSize='18px' />
          </StyledButton>
        )}
      </Stack>
    </Stack>
  );
}
