import React from 'react';

import FallbackAvatar, {
  FallbackAvatarProps,
} from '@lupa/ui/components/FallbackAvatar';

import { IconGrave2 } from '@tabler/icons-react';

interface PetFallbackAvatarProps extends FallbackAvatarProps {
  isDeceased: boolean | null;
}

export default function PetFallbackAvatar({
  isDeceased,
  ...props
}: PetFallbackAvatarProps) {
  if (isDeceased) {
    return (
      <FallbackAvatar
        src={null}
        size={props.size}
        fallbackIcon={<IconGrave2 color='#6C737F' />}
        sx={props.sx}
        style={props.style}
      />
    );
  }

  return <FallbackAvatar {...props} />;
}
