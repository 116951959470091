import React, { useMemo, useRef, useState } from 'react';

import { getFieldHelperText } from '@lupa/ui/utils/formikUtils';
import { globalSingleton } from '@lupa/work/singletons/globalSingleton';

import { Paper, Typography } from '@mui/material';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import Avatar from '@mui/material/Avatar';
import Box, { BoxProps } from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Popper from '@mui/material/Popper';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { darken, lighten, styled } from '@mui/material/styles';
import { IconChevronDown } from '@tabler/icons-react';
import { keepPreviousData, useQuery } from '@tanstack/react-query';

// @ts-ignore
import match from 'autosuggest-highlight/match';
// @ts-ignore
import parse from 'autosuggest-highlight/parse';

const OPTIONS_LIMIT = 50;

const GroupHeader = styled('div')(({ theme }) => ({
  position: 'sticky',
  top: '-8px',
  padding: '4px 10px',
  color: theme.palette.primary.main,
  backgroundColor:
    theme.palette.mode === 'light'
      ? lighten(theme.palette.primary.light, 0.85)
      : darken(theme.palette.primary.main, 0.8),
}));

const GroupItems = styled('ul')({
  padding: 0,
});

const StyledPopper = styled(Popper)(({ theme }) => ({
  [`& .MuiPaper-root`]: {
    maxWidth: '100%',
    mt: 1,
    boxShadow: theme.shadows[16],
  },
}));

interface ItemsSearchProps extends BoxProps {
  name?: string;
  queryKey?: Nullish<string>;
  queryFn?: (key: any) => any;
  queryExtraValues?: any[];
  handleOnChange?: (value: any) => void;
  handleOnFreeSoloChange?: (value: string) => void;
  items: any;
  placeholder: string;
  getOptionLabel: (option: any) => string;
  formik?: any;
  multiple?: boolean;
  disabled?: boolean;
  localOptions?: Nullish<any[]>;
  renderTags?: (value: any, getTagProps: any) => React.ReactNode;
  handleExtraOptionOnClick?: () => void;
  freeSolo?: boolean;
  extraOptionText?: string;
  groupBy?: (option: any) => string;
  inputPropsSx?: any;
  size?: 'small' | 'medium';
  staleTime?: number;
  enableClearable?: boolean;
  testIdPrefix?: string;
}

export const ItemsSearch = ({
  name,
  queryKey = null,
  queryFn = () => {},
  queryExtraValues = [],
  handleOnChange,
  handleOnFreeSoloChange,
  items,
  placeholder,
  getOptionLabel,
  formik = null,
  multiple = true,
  disabled = false,
  localOptions = null,
  renderTags,
  handleExtraOptionOnClick,
  freeSolo = false,
  extraOptionText,
  groupBy,
  inputPropsSx,
  size,
  enableClearable = true,
  staleTime = 1000 * 60 * 10, // 10 minutes
  testIdPrefix = '',
  ...other
}: ItemsSearchProps) => {
  const { currentStore } = globalSingleton;
  const [inputValue, setInputValue] = useState('');
  const [changedValue, setChangedValue] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const autocompleteRef = useRef<any>(null);
  const textFieldRef = useRef<any>(null);

  const defaultFilterOptions = createFilterOptions();
  const { data, isFetching } = useQuery({
    queryKey: [
      queryKey,
      inputValue === changedValue ? '' : inputValue,
      currentStore!.id,
      ...queryExtraValues,
    ],
    queryFn,
    placeholderData: keepPreviousData,
    staleTime,
    enabled: localOptions == null && isOpen,
  });

  const options = data?.options ?? [];

  const onClickExtraOption = () => {
    setIsOpen(false);
    textFieldRef.current.value = '';

    if (handleExtraOptionOnClick) {
      handleExtraOptionOnClick();
    }
  };

  const autocompleteOptions = useMemo(() => {
    const allOptions = localOptions ?? options ?? [];

    const filteredOptions = allOptions.filter(
      (option: any) =>
        !items ||
        (!Array.isArray(items)
          ? items.id == null || items.id !== option.id
          : !items.some((item) => item.id == null || item.id === option.id)),
    );

    return filteredOptions;
  }, [localOptions, items, options]);

  const errorMessage = formik && name ? getFieldHelperText(formik, name) : null;

  const getOptionLabelHandleUndefined = (option: any) => {
    return getOptionLabel(option) ?? '';
  };

  const filterOptions = (options: any, state: any) => {
    return defaultFilterOptions(options, state).slice(0, OPTIONS_LIMIT);
  };

  return (
    <Box {...other}>
      <Autocomplete
        data-testid={`${testIdPrefix}-auto-complete`}
        ref={autocompleteRef}
        open={isOpen}
        disableClearable={!enableClearable}
        disableListWrap
        multiple={multiple}
        popupIcon={<IconChevronDown />}
        value={items ?? null}
        onChange={(event, newValue, reason) => {
          setIsOpen(false);

          if (reason === 'blur') {
            return;
          }

          if (newValue) {
            setChangedValue(getOptionLabelHandleUndefined(newValue));
          }

          handleOnChange?.(newValue);
        }}
        onOpen={() => {
          setIsOpen(true);
        }}
        onBlur={() => {
          setIsOpen(false);
        }}
        options={autocompleteOptions}
        getOptionLabel={getOptionLabelHandleUndefined}
        groupBy={groupBy}
        disabled={disabled}
        filterOptions={filterOptions}
        freeSolo={freeSolo}
        autoSelect={freeSolo}
        onInputChange={(_, newInputValue) => {
          if (freeSolo) {
            handleOnFreeSoloChange?.(newInputValue);
          }

          setInputValue(newInputValue);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            ref={textFieldRef}
            name={name}
            error={Boolean(errorMessage)}
            helperText={errorMessage}
            onBlur={formik?.handleBlur}
            fullWidth
            variant='outlined'
            label={placeholder}
            disabled={disabled}
            size={size}
            data-testid={`${testIdPrefix}-input-text-field`}
            InputProps={{
              ...params.InputProps,
              sx: inputPropsSx,
              endAdornment: (
                <>
                  {isFetching && localOptions == null ? (
                    <CircularProgress color='inherit' size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
        PaperComponent={({ children }) => {
          return (
            <Paper>
              <Box>{children}</Box>
              {handleExtraOptionOnClick && (
                <Box
                  sx={{
                    justifyContent: 'flex-start',
                    p: 1,
                    borderTop: '1px solid',
                    borderColor: 'divider',
                  }}
                >
                  <Button
                    color='primary'
                    fullWidth
                    onMouseDown={onClickExtraOption}
                    data-testid={`${testIdPrefix}-extra-option-btn`}
                  >
                    {extraOptionText}
                  </Button>
                </Box>
              )}
            </Paper>
          );
        }}
        PopperComponent={StyledPopper}
        renderOption={(
          props,
          option,
          { inputValue: renderInputValue, index: renderOptionIndex },
        ) => {
          const matches = match(
            getOptionLabelHandleUndefined(option),
            renderInputValue,
            {
              insideWords: true,
            },
          );
          const parts = parse(getOptionLabelHandleUndefined(option), matches);

          return (
            <Stack
              {...props}
              key={renderOptionIndex}
              component='li'
              direction='column'
              style={{ alignItems: 'flex-start', flexGrow: 1 }}
            >
              <Stack direction='row' flexGrow={1}>
                {option.avatar && (
                  <ListItemAvatar>
                    <Avatar src={option.avatar} />
                  </ListItemAvatar>
                )}
                <div>
                  {parts.map((part: any, index: any) => (
                    <Typography
                      key={index}
                      variant='body1'
                      display='inline'
                      color={part.highlight ? 'primary' : 'inherit'}
                      style={{
                        fontWeight: part.highlight ? 700 : 400,
                      }}
                      data-testid={`${testIdPrefix}-option-text-${index}`}
                    >
                      {part.text}
                    </Typography>
                  ))}
                </div>
              </Stack>

              {option.subtitle && (
                <Typography
                  variant='caption'
                  color='text.secondary'
                  data-testid={`${testIdPrefix}-option-subtitle`}
                >
                  {option.subtitle}
                </Typography>
              )}
            </Stack>
          );
        }}
        renderGroup={(params) => (
          <li key={params.key} data-testid={`${testIdPrefix}-item-key`}>
            <GroupHeader>{params.group}</GroupHeader>
            <GroupItems
              sx={{
                '& li': {
                  paddingLeft: '10px',
                  listStyleType: 'none',
                },
              }}
            >
              {params.children}
            </GroupItems>
          </li>
        )}
        renderTags={renderTags}
      />
    </Box>
  );
};
