import React, { useEffect } from 'react';

import { useDialog } from '@lupa/ui/hooks/use-dialog';

import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { IconSearch } from '@tabler/icons-react';

import { SearchDialog } from './SearchDialog';

export const SearchButton = () => {
  const dialog = useDialog();

  useEffect(() => {
    const handleKeyDown = (event: any) => {
      if ((event.metaKey || event.ctrlKey) && event.key === 'k') {
        event.preventDefault();
        dialog.handleOpen();
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [dialog]);

  return (
    <>
      <Tooltip title='Search'>
        <IconButton onClick={dialog.handleOpen}>
          <IconSearch />
        </IconButton>
      </Tooltip>
      <SearchDialog onClose={dialog.handleClose} open={dialog.open} />
    </>
  );
};
