import React, { useEffect } from 'react';

import { IconButton, Link, Stack, Typography } from '@mui/material';
import { IconPhoneIncoming, IconX } from '@tabler/icons-react';
import { useQueryClient } from '@tanstack/react-query';

import { produce } from 'immer';
import toast from 'react-hot-toast';
import io, { Socket } from 'socket.io-client';

import { API_KEYS } from '../api/apiKeys';
import { paths } from '../paths';
import { globalSingleton } from '../singletons/globalSingleton';
import { API_ENDPOINT } from '../utils/networkUtils';

const useInitializeSocket = (isAuthenticated: boolean) => {
  const queryClient = useQueryClient();

  useEffect(() => {
    if (!isAuthenticated) {
      return;
    }

    const socket: Socket = io(API_ENDPOINT, {
      path: '/api/socket.io',
      query: { user_id: globalSingleton.userId },
    });

    socket.on('connect', () => {
      console.info('Connected to server');
    });

    socket.on('new-message-received', (newMessage: any) => {
      if (newMessage.sender_id === globalSingleton.userId) {
        return;
      }

      const conversations: any[] =
        queryClient.getQueryData([API_KEYS.CONVERSATIONS]) || [];
      const conversationExists = conversations.find(
        (c) => c.id === newMessage.conversation_id,
      );

      if (!conversationExists) {
        queryClient.invalidateQueries({
          queryKey: [API_KEYS.CONVERSATIONS],
        });
        return;
      }

      queryClient.setQueryData([API_KEYS.CONVERSATIONS], (oldData: any) => {
        return produce(oldData, (draft: any) => {
          const conversation = draft.find(
            (c: any) => c.id === newMessage.conversation_id,
          );

          if (!conversation) {
            return;
          }

          conversation.messages = [newMessage];
          conversation.last_message = [{ message: newMessage }];
        });
      });

      queryClient.setQueryData(
        [API_KEYS.CONVERSATION, newMessage.conversation_id, 10],
        (oldData: any) => {
          return produce(oldData, (draft: any) => {
            if (draft == null) {
              return;
            }

            draft.messages = [...draft.messages, newMessage];
            draft.last_message = [{ message: newMessage }];
          });
        },
      );
    });

    socket.on('new-incoming-call', (newCall: any) => {
      toast(
        (t) => (
          <Stack
            direction='row'
            alignItems='center'
            justifyContent='space-between'
            spacing={1}
          >
            <Typography
              mr={2}
            >{`Incoming call from ${newCall.clientName}`}</Typography>

            <Link
              color='white'
              href={paths.clients.details(newCall.clientId)}
              variant='subtitle2'
              target='_blank'
              rel='noopener noreferrer'
            >
              View profile
            </Link>

            <IconButton color='error' onClick={() => toast.dismiss(t.id)}>
              <IconX />
            </IconButton>
          </Stack>
        ),
        {
          icon: <IconPhoneIncoming />,
          duration: 10000,
        },
      );
    });

    globalSingleton.socket = socket;

    // eslint-disable-next-line consistent-return
    return () => {
      socket.disconnect();
    };
  }, [isAuthenticated, queryClient]);
};

export default useInitializeSocket;
