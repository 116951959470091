import * as React from 'react';
import { useEffect, useState } from 'react';

import { STORE_TEMPLATE_TYPE } from '@lupa/utils/enums';

import { $generateHtmlFromNodes } from '@lexical/html';
import { AutoFocusPlugin } from '@lexical/react/LexicalAutoFocusPlugin';
import { CheckListPlugin } from '@lexical/react/LexicalCheckListPlugin';
import { ClearEditorPlugin } from '@lexical/react/LexicalClearEditorPlugin';
import { ClickableLinkPlugin } from '@lexical/react/LexicalClickableLinkPlugin';
import { LexicalErrorBoundary } from '@lexical/react/LexicalErrorBoundary';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import { HorizontalRulePlugin } from '@lexical/react/LexicalHorizontalRulePlugin';
import { ListPlugin } from '@lexical/react/LexicalListPlugin';
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { TabIndentationPlugin } from '@lexical/react/LexicalTabIndentationPlugin';
import { TablePlugin } from '@lexical/react/LexicalTablePlugin';
import { useLexicalEditable } from '@lexical/react/useLexicalEditable';

import AutoLinkPlugin from './plugins/AutoLinkPlugin';
import DraggableBlockPlugin from './plugins/DraggableBlockPlugin';
import FloatingLinkEditorPlugin from './plugins/FloatingLinkEditorPlugin';
import { LayoutPlugin } from './plugins/LayoutPlugin';
import LinkPlugin from './plugins/LinkPlugin';
import ListMaxIndentLevelPlugin from './plugins/ListMaxIndentLevelPlugin';
import MarkdownShortcutPlugin from './plugins/MarkdownShourtcutPlugin';
import PageBreakPlugin from './plugins/PageBreakPlugin';
import TabFocusPlugin from './plugins/TabFocusPlugin';
import TableCellActionMenuPlugin from './plugins/table/TableActionMenuPlugin';
import TableCellResizer from './plugins/table/TableCellResizer';
import TableHoverActionsPlugin from './plugins/table/TableHoverActionsPlugin';
import EditorToolbarPlugin from './plugins/toolbar/EditorToolbarPlugin';
import FloatingTextFormatToolbarPlugin from './plugins/toolbar/FloatingTextFormatToolbarPlugin';
import ContentEditable from './ui/ContentEditable';

import './LexicalEditor.css';

interface LexicalEditorProps {
  onChange?: (value: string) => void;
  isComplexToolbar?: boolean;
  placeholder?: string;
  templateType?: STORE_TEMPLATE_TYPE;
}

export default function LexicalEditor({
  onChange,
  isComplexToolbar,
  placeholder = 'Enter some text...',
  templateType,
}: LexicalEditorProps): JSX.Element {
  const isEditable = useLexicalEditable();
  const [floatingAnchorElem, setFloatingAnchorElem] =
    useState<HTMLDivElement | null>(null);
  const [isSmallWidthViewport, setIsSmallWidthViewport] =
    useState<boolean>(false);
  const [isLinkEditMode, setIsLinkEditMode] = useState<boolean>(false);
  const [isFullScreen, setIsFullScreen] = useState(false);

  const onRef = (_floatingAnchorElem: HTMLDivElement) => {
    if (_floatingAnchorElem !== null) {
      setFloatingAnchorElem(_floatingAnchorElem);
    }
  };

  useEffect(() => {
    const updateViewPortWidth = () => {
      const isNextSmallWidthViewport = window.matchMedia(
        '(max-width: 1025px)',
      ).matches;

      if (isNextSmallWidthViewport !== isSmallWidthViewport) {
        setIsSmallWidthViewport(isNextSmallWidthViewport);
      }
    };
    updateViewPortWidth();
    window.addEventListener('resize', updateViewPortWidth);

    return () => {
      window.removeEventListener('resize', updateViewPortWidth);
    };
  }, [isSmallWidthViewport]);

  return (
    <div className={`lexical-text-editor ${isFullScreen ? 'fullscreen' : ''}`}>
      {isEditable && !isSmallWidthViewport && (
        <EditorToolbarPlugin
          isComplexToolbar={isComplexToolbar}
          setIsLinkEditMode={setIsLinkEditMode}
          templateType={templateType}
          isFullScreen={isFullScreen}
          setIsFullScreen={setIsFullScreen}
        />
      )}
      <div className='editor-container'>
        <AutoFocusPlugin />
        <ClearEditorPlugin />
        <AutoLinkPlugin />
        <HistoryPlugin />
        <RichTextPlugin
          placeholder={
            <div
              className='ContentEditable__placeholder'
              style={!isEditable ? { left: '0px' } : {}}
            >
              {placeholder}
            </div>
          }
          contentEditable={
            <div className='editor-scroller'>
              <div className='editor' ref={onRef}>
                <ContentEditable placeholder={placeholder} />
              </div>
            </div>
          }
          ErrorBoundary={LexicalErrorBoundary}
        />
        <MarkdownShortcutPlugin />
        <ListPlugin />
        <CheckListPlugin />
        <ListMaxIndentLevelPlugin maxDepth={7} />
        <TablePlugin hasCellMerge hasCellBackgroundColor />
        <TableCellResizer />
        <TableHoverActionsPlugin />
        <LinkPlugin />
        <ClickableLinkPlugin disabled={isEditable} />
        <HorizontalRulePlugin />
        <TabFocusPlugin />
        <TabIndentationPlugin />
        <PageBreakPlugin />
        <LayoutPlugin />
        {isEditable && floatingAnchorElem && !isSmallWidthViewport && (
          <>
            <DraggableBlockPlugin anchorElem={floatingAnchorElem} />
            <FloatingLinkEditorPlugin
              anchorElem={floatingAnchorElem}
              isLinkEditMode={isLinkEditMode}
              setIsLinkEditMode={setIsLinkEditMode}
            />
            <TableCellActionMenuPlugin
              anchorElem={floatingAnchorElem}
              cellMerge
            />
            <FloatingTextFormatToolbarPlugin
              anchorElem={floatingAnchorElem}
              setIsLinkEditMode={setIsLinkEditMode}
            />
          </>
        )}

        {onChange != null && (
          <OnChangePlugin
            ignoreSelectionChange
            ignoreHistoryMergeTagChange
            onChange={(editorState, editor) => {
              editorState.read(() => {
                const htmlString = $generateHtmlFromNodes(editor);
                onChange(htmlString);
              });
            }}
          />
        )}
      </div>
    </div>
  );
}
