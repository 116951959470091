import * as React from 'react';
import { useCallback } from 'react';

import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';

import { enGB } from 'date-fns/locale';

function LupaDatePicker({
  onChange,
  ...props
}: Parameters<typeof DatePicker>[0]) {
  const onChangeCallback: Parameters<typeof DatePicker>[0]['onChange'] = (
    date,
    context,
  ) => {
    // Don't call onChange if the date a nonnull but invalid date
    if (date != null && Number.isNaN(date.getTime())) {
      return;
    }

    onChange?.(date, context);
  };
  const handleChange = useCallback(onChangeCallback, [onChange]);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enGB}>
      <DatePicker onChange={handleChange} {...props} />
    </LocalizationProvider>
  );
}

export default LupaDatePicker;
